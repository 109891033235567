import { Button, Col, Form, Modal, Row, Spin, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess, notifyWarning } from "src/components/notification";
import { TYPE_YEP_BASE } from "src/constants";
import { importFileByTemplate } from "src/services/actions/import.actions";
import { getOrgs } from "src/services/actions/org.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const ImportModal = ({
	visible = true,
	detail = {} as any,
	onCancelModalImport = () => {},
	onSuccess = () => {},
	isLoading = false
}) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [fileTest, setFileTest] = useState<any>(undefined);
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [dataPage, setDataPage] = useState<any>([]);

	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
	}, []); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCheckinYepDetails(detail.id);
				form.setFieldsValue({
					...res?.data
				});
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, []); //eslint-disable-line

	const handleChangeFile = (e: any) => {
		setFileTest(e.target.files[0]);
	};
	const onFinishImportFile = () => {
		if (!fileTest) {
			notifyWarning("Vui lòng chọn file");
		} else {
			let params = {
				file: fileTest
				// type: EXPORT_TEMPLATE_TYPE.TEMPLATE_BOOKING,
			};
			const init = async () => {
                try {
                    // if (users.length === 0 || !stateGetOrgs?.data) return;
                    setLoading(true);
                    const res: any = await _apiNotSaga.importYEP(params);
                    console.log(res);
                    // form.setFieldsValue({
                    //     ...res?.data
                    // });
                    setLoading(false);
                    onSuccess()
                } catch (error: any) {
                    setLoading(false);
                    notifyError(error.message);
                }
            };
            init()
		}
	};

	return (
		<>
			<Modal
				open={visible}
				title="Nhập file"
				maskClosable={false}
				className="modal-create-waiting-book"
				onCancel={onCancelModalImport}
				footer={null}
				width={420}
				centered
			>
				<Spin spinning={isLoading || false}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<label
							htmlFor="importFile"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								borderRadius: "5px",
								background: "#259c58",
								height: "41px",
								width: "calc(50% - 4px)",
								cursor: "pointer"
							}}
						>
							<span style={{ marginLeft: "8px", color: "#fff" }}>Chọn file</span>
						</label>
						<input type="file" id="importFile" onChange={(e) => handleChangeFile(e)} style={{ display: "none" }} />

						<div
							onClick={() => onFinishImportFile()}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								borderRadius: "5px",
								background: "#27aae1",
								height: "41px",
								width: "calc(50% - 4px)",
								color: "#fff",
								cursor: "pointer"
							}}
						>
							Xác nhận
						</div>
					</div>
					{fileTest && <div>{fileTest.name}</div>}
				</Spin>
			</Modal>
		</>
	);
};

export default ImportModal;
