import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import moment from "moment";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import { createUser, userInfo } from "src/components/commonRender/renderData";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id,r?.user_erp_code)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Tên tài khoản",
			key: "user_erp_code",
			fixed: "left",
			width: 250,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.full_name}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Email:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.email}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">SĐT:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug"> </span>
							<span className="text-blue-600 text-[13px] font-normal   underline leading-snug">{record?.phone}</span>
						</div>
					</div>
				);
			}
		},

		{
			title: "Ngày tạo",
			key: "user_type_name",
			width: 150,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.created_by}</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.created_at ? moment(record?.created_at).format("DD/MM/YYYY") : "-"}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày kích hoạt:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.actived_at ? moment(record?.actived_at).format("DD/MM/YYYY") : "-"}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Đăng nhập cuối:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.last_login_at ? moment(record?.last_login_at).format("DD/MM/YYYY") : "-"}
							</span>
						</div>
					</div>
				);
			}
		},

		{
			title: "TT hoạt động",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 150,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
				</div>
			)
		},
		// {
		// 	title: "Trạng thái khóa",
		// 	dataIndex: "locked",
		// 	key: "locked",
		// 	align: "center",
		// 	width: 150,
		// 	render: (v: string, r: any) => (
		// 		<div className="flex justify-center">
		// 			<Switch checked={v === "Y"} onChange={(status) => updateStatus(r, status, "locked")} />
		// 		</div>
		// 	)
		// },
		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};

export const columnsDataOrg = ({ updateStatus, getDetail }: any) => {
	return [
		{
			title: "Chi nhánh",
			width: 140,
			dataIndex: "org_name",
			key: "org_name"
		},

		{
			title: "TT hoạt động",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 150,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
				</div>
			)
		}
	];
};
