import React from "react";

const SvgLive = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="31" height="15" viewBox="0 0 31 15" fill="none">
			<path
				d="M0.320312 2.24024C0.320312 1.13567 1.21574 0.240234 2.32031 0.240234H28.6803C29.7849 0.240234 30.6803 1.13566 30.6803 2.24023V12.7602C30.6803 13.8648 29.7849 14.7602 28.6803 14.7602H2.32031C1.21574 14.7602 0.320312 13.8648 0.320312 12.7602V2.24024Z"
				fill="#FB4A59"
			/>
			<path d="M13.59 9.43783V10.4009H9.56055V4.59766H10.6081V9.43783H13.59Z" fill="#F2F2F2" />
			<path d="M14.46 4.59766H15.5086V10.4009H14.46V4.59766Z" fill="#F2F2F2" />
			<path
				d="M22.3161 4.59766L19.891 10.4009H18.7175L16.293 4.59766H17.4588L19.3005 8.9988L21.1421 4.59766H22.3161Z"
				fill="#F2F2F2"
			/>
			<path
				d="M24.1419 5.56073V6.9631H27.1407V7.9259H24.1419V9.4381H27.4533V10.4012H23.0859V4.59766H27.4533V5.56073H24.1419Z"
				fill="#F2F2F2"
			/>
			<path
				d="M4.99123 9.08401C5.86605 9.08401 6.57523 8.37483 6.57523 7.50002C6.57523 6.6252 5.86605 5.91602 4.99123 5.91602C4.11641 5.91602 3.40723 6.6252 3.40723 7.50002C3.40723 8.37483 4.11641 9.08401 4.99123 9.08401Z"
				fill="#F2F2F2"
			/>
		</svg>
	);
};

export default SvgLive;
