import { Button, Col, Form, Modal, Row, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import { TYPE_YEP_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [dataPage, setDataPage] = useState<any>([]);

	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
	}, []); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCheckinYepDetails(detail.id);
				form.setFieldsValue({
					...res?.data
				});
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, []); //eslint-disable-line

	const onSubmit = async (val: any) => {
		// setLoading(true);
		let date = val?.date;

		delete val.date;
		const params = {
			...val,
			is_check: '0',
			org_name: stateGetOrgs?.data?.find((e: any) => e.org_code === val.org_code)?.org_name,
			// status: status ? "A" : "D",
			// start_at: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
			// end_at: moment(date[1]).format("YYYY-MM-DD HH:mm:ss")
		};
		try {
			const res: any = isCreate
				? await _apiNotSaga.createCheckinYep(params)
				: await _apiNotSaga.updateYepCheckin(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} người dùng`}
				style={{
					maxWidth: "80vw"
				}}
				width={"60%"}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div> */}
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[8, 8]}>
							<Col xs={8} md={8}>
								<FormSelect
									name="company"
									label="Chọn loại"
									dataSource={TYPE_YEP_BASE}
									placeholder="Chọn loại"
									slOption={{
										name: "name",
										value: "id"
									}}
									// onChange={onChangeSite}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormSelect
									name="org_code"
									label="Chi nhánh"
									dataSource={stateGetOrgs?.data}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "org_name",
										value: "org_code"
									}}
									showSearch
									optionFilterProp="children"
									required
									// disabled
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="ma_nv"
									label="Mã Nhân viên"
									type="text"
									size="large"
									placeholder="Nhập mã nhân viên"
									required
									// disabled
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="ho_ten"
									label="Họ và tên"
									type="text"
									size="large"
									placeholder="Nhập họ và tên"
									required
									// disabled
								/>
							</Col>
							<Col xs={8} md={8}>
								<FormInput
									name="so_ban"
									label="Số bàn"
									type="text"
									size="large"
									placeholder="Nhập số bàn"
									required
									// disabled
								/>
							</Col>
							{/* <Col xs={24} md={24}>
								<FormSelectDate name="date" label="Thời gian hiển thị" required onChange={onChangeDate} />
							</Col>
							<Col xs={24} md={24}>
								<Col md={24} xs={24} sm={24}>
									<Form.Item name="link" label="Hình ảnh banner">
										<UpdaloadImage
											fileList={fileListMobie}
											className="iconMoudle"
											setChangeFileList={setFileListMobie}
											// uploadImg={uploadImg}
											uploadImg={(info: any) => uploadImg(info)}
											maxCount={1}
											width={"100%"}
										/>
									</Form.Item>
								</Col>
							</Col> */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
