/*eslint-disable*/
import { DownloadOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, TableProps, Col, Row } from "antd";
import { arrayMoveImmutable } from "array-move";
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd } from "react-sortable-hoc";
import FlexFilter from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import PanigationAntStyled from "src/components/panigation/PanigationAntStyled";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import TableStyled from "src/components/table/TableStyled";
import { DataType, FLEX_FILTER_ELEMENTS, STATUS_BASE } from "src/constants";
import { getModuleFunct } from "src/services/actions/mouduleFunct.actioms";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import CreateModuleFuct from "../component/createModuleFuct";
import { columnsData, _paramsFilter } from "../component/data";
import FormSelect from "src/components/form/FormSelect";

export const CustomerListContext = createContext<any>({});
const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);
const ModuleFuct = () => {
	const [createForm] = Form.useForm();
	const dispatch = useDispatch();
	const [appId, setAppId] = useState<any>("");
	const [dataApp, setDataApp] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter, level: 0 });
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
	const [data, setData] = useState<any>([]);
	const [updateStatus, setUpdateStatus] = useState<any>({});
	const [updateData, setUpdateData] = useState<any>({});
	const [callback, setCallback] = useState<boolean>(false);
	const { stateGetModuleFunct } = useSelector((state: AppState) => state.moduleFunctReducer);
	console.log("stateGetModuleFunct", stateGetModuleFunct);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				// setLoading(true);
				const res: any = await _apiNotSaga.getApps({
					page: 1,
					limit: 999
				});
				setDataApp(res?.data);
				// setLoading(false);
			} catch (error: any) {
				setDataApp([]);
				// setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line
	useEffect(() => {
		if(appId){
			dispatch(getModuleFunct({
				...paramsFilter,
				app_id:appId
			}));
		}
		
	}, [paramsFilter, callback,appId]);
	// useEffect(() => {
	// 	console.log(appId);
	// 	dispatch(getModuleFunct({ status: "A", app_id: appId }));
	// }, [appId]);
	useEffect(() => {
		if (stateGetModuleFunct?.data) {
			setData(stateGetModuleFunct?.data);
		}
	}, [stateGetModuleFunct]);
	useEffect(() => {
		if (updateStatus.id) {
			let id = updateStatus.id;
			let status = updateStatus.status === "A" ? "D" : "A";
			const _newData = {
				method: updateStatus.method,
				status: status,
				ui_route: updateStatus.ui_route,
				level: updateStatus.level,
				index: updateStatus.index,
				funct_name: updateStatus.funct_name,
				funct_code: updateStatus.funct_code
			};
			_apiNotSaga
				.updateModuleFunct(id, _newData)
				.then((res: any) => {
					if (res.success) {
						dispatch(getModuleFunct(paramsFilter));
						notifySuccess("Cập nhật thành công");
						setUpdateStatus({});
					}
				})
				.catch((err) => {
					notifyError(err.message);
				});
		}
	}, [updateStatus]);
	const onSearch = (val: any) => {
		const { idBtn, params: flexParams } = val;
		if (val.idBtn === "btnAdd") {
			setIsEdit(false);
			setOpenCreateModal(true);
		} else if (val.idBtn === "btnSearch") {
			const newParams = removeIdentity({
				...paramsFilter,
				page: 1,
				...flexParams
			});
			setParamsFilter(newParams);
		}
	};
	const onChangePaging = (page: number, pageSize: number) => {
		setParamsFilter((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	const onCancelCreateModal = () => {
		if (updateData) setUpdateData({});
		createForm.resetFields();
		setOpenCreateModal(false);
		setIsEdit(false);
	};
	const onChangeTable: TableProps<DataType>["onChange"] = (pagination, filters, sorter: any, extra) => {
		const _sort = { field: sorter.field, order: sorter.order };
		setParamsFilter({ ...paramsFilter, ..._sort });
	};
	const onUpdateIndexes = (array: any) => {
		const newFunctsPosition = array.map((functItem: any, i: number) => {
			return { id: functItem.id, index: i };
		});
		_apiNotSaga
			.updateModuleFunctIdx({ funct_indexes: newFunctsPosition })
			.then((res: any) => {
				if (res.success) {
					notifySuccess("Cập nhật thành công");
					setCallback((pre: boolean) => !pre);
				}
			})
			.catch((err) => {
				notifyError(err.message);
			});
	};
	const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
		if (oldIndex !== newIndex) {
			let newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter((el: any) => !!el);
			console.log("Sorted items: ", newData);
			onUpdateIndexes(newData);
			setData(newData);
		}
	};
	const DraggableContainer = (props: SortableContainerProps) => (
		<SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
	);

	const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
		const index = data.findIndex((x: any) => x.id === restProps["data-row-key"]);
		return <SortableItem index={index} {...restProps} />;
	};
	console.log("data", updateData);
	return (
		<div className="customers">
			<div className="mainPages customers">
				<div className="contentSection">
					<Loading spinning={stateGetModuleFunct.isLoading || stateGetModuleFunct.isLoading || false}>
						{openCreateModal && (
							<CreateModuleFuct
								form={createForm}
								visible={openCreateModal}
								onCancel={onCancelCreateModal}
								isEdit={isEdit}
								data={updateData}
								onSave={onSearch}
								setCallback={setCallback}
							/>
						)}
						<div className="top-0 contentSection-filter">
							<FlexFilter
								onSearch={(v) => onSearch(v)}
								manipulation={[
									{
										paramsName: "btnAdd",
										icon: <PlusOutlined />,
										text: "Tạo mới"
									}
									// {
									// 	paramsName: "btnExport",
									// 	icon: <DownloadOutlined />,
									// 	text: "Tải file"
									// },
									// {
									// 	paramsName: "btnImport",
									// 	icon: <UploadOutlined />,
									// 	text: "Nhập file"
									// }
								]}
								elements={[
									{
										...FLEX_FILTER_ELEMENTS.input,
										placeholder: "Nhập tên,mã hoặc route của chức năng",
										paramsName: "search",
										width: 340
									},
									{
										...FLEX_FILTER_ELEMENTS.select,
										label: "Trạng thái",
										placeholder: "Chọn trạng thái",
										paramsName: "status",
										width: 160,
										options: {
											data: STATUS_BASE,
											keyOption: "id",
											valueOption: "id",
											nameOption: "name"
										}
									},
									{
										...FLEX_FILTER_ELEMENTS.btnSearch,
										icon: <SearchOutlined />
									},
									{ ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
								]}
							/>
						</div>
						<hr className=" mb-[10px] border-neutral_color_1_2" />
						<Row className="w-full" gutter={[14, 14]}>
							<Col xs={12} md={12}>
								<FormSelect
									name="app_id"
									label="Chọn Ứng dụng"
									dataSource={dataApp}
									placeholder="Chọn ứng dụng"
									slOption={{
										name: "app_name",
										value: "id"
									}}
									showSearch
									onChange={(e) => setAppId(e)}
									optionFilterProp="children"
									required
								/>
							</Col>
						</Row>
						<div className="contentSection-main">
							<TableStyled
								className="thisIsAnShit"
								rowKey="id"
								dataSource={data}
								bordered
								// onChange={onChangeTable}
								pagination={false}
								isRowLight={true}
								expandable={{
									childrenColumnName: "hide"
								}}
								columns={columnsData({ setIsEdit, setOpenCreateModal, setUpdateStatus, setUpdateData }) as any}
								components={{
									body: {
										wrapper: DraggableContainer,
										row: DraggableBodyRow
									}
								}}
								scroll={{
									x: data?.data?.length > 0 ? (columnsData({}).length - 1) * 100 : null,
									y: "calc(70vh - 80px)"
								}}
							/>
							<PanigationAntStyled
								style={{ marginTop: "8px" }}
								current={paramsFilter.page}
								pageSize={paramsFilter.limit}
								showSizeChanger
								onChange={onChangePaging}
								showTotal={(total, range) => (
									<div className="flex gap-2">
										<strong>
											{(paramsFilter?.page - 1) * 10 + 1}&nbsp;-&nbsp;
											{paramsFilter?.page * paramsFilter.limit < data?.page?.total
												? paramsFilter?.page * paramsFilter.limit
												: stateGetModuleFunct?.data?.page?.total}
										</strong>
										trong tổng
										<strong>{stateGetModuleFunct?.data?.page?.total}</strong>
									</div>
								)}
								total={stateGetModuleFunct?.data?.page?.total || 0}
							/>
						</div>
					</Loading>
				</div>
			</div>
		</div>
	);
};

export default ModuleFuct;
