import { Form, Input, Select, Button, InputNumber, Col, Row } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import { getGoodTypes, getProvinces, getServices } from "src/services/actions/global.actions";
import FormSelect from "src/components/form/FormSelect";
import { AppState } from "src/types";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "lucide-react";
import FormTag from "src/components/form/FormTag";
const { Option } = Select;
const CargoInfo = ({ form }: any) => {
	// const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	useEffect(() => {
		dispatch(getGoodTypes());
	}, []);
	return (
		<div>
			<h3 style={{ fontWeight: 600 }}>Hàng hóa</h3>
			<Row gutter={[16, 16]}>
				<Col md={8} xs={8} sm={8}>
					<Form.Item label="Dài (Cm)" name="length">
						<InputNumber placeholder="Dài (Cm)" min={0}/>
					</Form.Item>
				</Col>
				<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
					<Form.Item label="Rộng (Cm)" name="width">
						<InputNumber placeholder="Rộng (Cm)" min={0}/>
					</Form.Item>
				</Col>
				<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
					<Form.Item label="Cao (Cm)" name="height">
						<InputNumber placeholder="Cao (Cm)" min={0}/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item style={{ display: "none" }} name="goods_type_name" />
			<Row gutter={[16, 16]}>
				<Col md={8} xs={8} sm={8}>
					<FormSelect
						name="goods_type_id"
						label="Loại hàng hóa"
						dataSource={stateGoodTypes?.data || []}
						placeholder="Chọn loại hàng hóa"
						slOption={{
							name: "gst_name",
							value: "id"
						}}
						required={true}
						// disabled={disableAddress}
						onChange={(e) => {
							form.setFieldsValue({
								goods_type_name: (stateGoodTypes?.data || []).find((item: any) => item.id === e)?.gst_name
							});
						}}
						showSearch
						optionFilterProp="children"
					/>
				</Col>
				<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
					<Form.Item
						label="TL hàng hoá (Kg)"
						name="weight"
						rules={[{ required: true, message: "Vui lòng nhập trọng lượng" }]}
					>
						<InputNumber placeholder="TL hàng hoá (Kg)" min={0} className="w-full" />
					</Form.Item>
				</Col>
				<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
					<Form.Item
						label="Số kiện"
						name="package_number"
						rules={[{ required: true, message: "Vui lòng nhập số kiện" }]}
					>
						<InputNumber placeholder="Số kiện" min={0} className="w-full" />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				label="Giá trị hàng hoá (Vnd)"
				name="cargo_value"
				// rules={[{ required: true, message: "Vui lòng nhập giá trị hàng hoá" }]}
			>
				<InputNumber placeholder="30,300,000" min={0} className="w-full" />
			</Form.Item>

			<Form.Item label="Số Seal" name="seal_number">
				{/* <Input placeholder="5007838, 5007839, 5007840" className="w-full" /> */}
				<FormTag
					tagSource={[]}
					onChange={(tags: any) => {
						form.setFieldsValue({ seal_number: JSON.stringify(tags) });
					}}
				/>
			</Form.Item>

			<Form.Item label="Nội dung hàng hoá" name="cargo_content">
				<Input.TextArea placeholder="Balo giá trị cao, không quăng hay để vật nặng đè lên..." className="w-full" />
			</Form.Item>
		</div>
	);
};

export default CargoInfo;
