
import { IAction } from '../interfaces/root.interfaces';
import rolesTypes from './types/roles.types';

export const getRoles = (params?: any): IAction => {
  const action: IAction = {
    type: rolesTypes.START_GET_ROLES,
    payload: { params },
  }
  return action;
}

export const getRoleDetail = (params?: any): IAction => {
  const action: IAction = {
    type: rolesTypes.START_GET_ROLE_DETAIL,
    payload: { params },
  }
  return action;
}