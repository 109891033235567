import { Form, Input, Select, Button, InputNumber, Col, Row } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import FormSelect from "src/components/form/FormSelect";
const { Option } = Select;
const SenderInfo = ({ form }: any) => {
	// const [form] = Form.useForm();
	const [partnerList, setPartnerList] = useState<any[]>([]);
	const [partnerAddress, setPartnerAddress] = useState<any[]>([]);
	useEffect(() => {
		const getPartnerList = async () => {
			try {
				const partners: any = await _apiNotSaga.getPartners({});
				if (partners.success) {
					setPartnerList(partners.data);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getPartnerList();
		return () => {
			setPartnerList([]);
		};
	}, []);

	const onChangePartner = async (e: any) => {
		// form.setFieldsValue({ r_ward_id: undefined, r_ward_name: undefined, r_district_name: districts.find((item:any) => item.id === e)?.district_name });
		const partnerAddress: any = await _apiNotSaga.getPartnerAddresses(e, {});
		if (partnerAddress.success) {
			setPartnerAddress(partnerAddress.data);
		}
	};
	const onChangePartnerAddress = async (e: any) => {
		const _sender_info = partnerAddress.find((item: any) => item.id === e)
		console.log('_sender_info', _sender_info)
		form.setFieldsValue({
			s_ward_id: _sender_info.ward_id,
			s_ward_name: _sender_info.ward_name,
			s_district_name: _sender_info.district_name,
			s_district_id: _sender_info.district_id,
			s_province_name: _sender_info.province_name,
			s_province_id: _sender_info.province_id,
			s_address: _sender_info.address,
			s_name: _sender_info.name,
			s_phone: _sender_info.phone,
		});
	};
	return (
		<div>
			<h3 style={{ fontWeight: 600 }}>Thông tin người gửi</h3>
			{/* <Form form={form} className="space-y-4"> */}
			<FormSelect
				name="sender_id"
				label="Đối tác"
				dataSource={partnerList || []}
				placeholder="Chọn tỉnh thành"
				slOption={{
					name: "partner_name",
					value: "id"
				}}
				required={true}
				// disabled={disableAddress}
				onChange={onChangePartner}
				showSearch
				optionFilterProp="children"
			/>
			<FormSelect
				name="partner_address_id"
				label="Địa chỉ"
				dataSource={partnerAddress || []}
				placeholder="Chọn địa chỉ"
				slOption={{
					name: "sub_name",
					value: "id"
				}}
				required={true}
				// disabled={disableAddress}
				onChange={onChangePartnerAddress}
				showSearch
				optionFilterProp="children"
			/>

			<Form.Item style={{ display: "none" }} name="s_name" />
			<Form.Item style={{ display: "none" }} name="s_phone" />
			<Form.Item style={{ display: "none" }} name="s_address" />
			<Form.Item style={{ display: "none" }} name="s_province_id" />
			<Form.Item style={{ display: "none" }} name="s_district_id" />
			<Form.Item style={{ display: "none" }} name="s_ward_id" />
			<Form.Item style={{ display: "none" }} name="s_province_name" />
			<Form.Item style={{ display: "none" }} name="s_district_name" />
			<Form.Item style={{ display: "none" }} name="s_ward_name" />
			{/* <Form.Item
				label="Hãng hàng không"
				name="airline"
				rules={[{ required: true, message: "Vui lòng chọn hãng hàng không" }]}
			>
				<Select placeholder="Chọn hãng hàng không" className="w-full">
					<Option value="VJ">VJ - Vietnam Airline</Option>
				</Select>
			</Form.Item> */}
		</div>
	);
};

export default SenderInfo;
