import moment from "moment";

export function getKeyByValue(object : any, value : string | number) {
  return Object.keys(object).find(key => object[key] === value);
}

export const removeMoreThanOneSpace = (str : string) => str.replace(/\s\s+/g, ' ');

export const mergeArray = (initArr: any[], insertedArr: any[], index: number) => {
	const _initArr = [...initArr];
	return [...initArr.slice(0, index), ...insertedArr, ..._initArr.slice(index, initArr.length)];
};

export const cartesian =
  (...a : any) => a.reduce((a:any, b:any) => a.flatMap((d:any) => b.map((e:any) => [d, e].flat())));

export const renameFile = (originalFile: any, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile?.type,
    lastModified: originalFile?.lastModified
  });
};

export const generateCodeWithPrefix = (prefix: string, randomLength: number ) => {
  const monthYear = moment().format('MMYY');
  const randomNum = Math.floor(Math.random() * (9 * (Math.pow(10, randomLength)))) + (Math.pow(10, randomLength));
  return prefix + monthYear + randomNum;
}

export const exportPdf = (file:any) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  console.log('url', url)
  // link.setAttribute('download', 'Report_' + year + month + date + '.xlsx'); //or any other extension
  link.setAttribute("target", "_blank"); //or any other extension
  document.body.appendChild(link);
  link.click();
};
