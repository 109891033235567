/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, STATUS_BASE } from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataDetail } from "./data";
import TableStyled from "src/components/table/TableStyled";
import UserModal from "./UserModal";
import { useHistory } from "react-router-dom";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError, notifySuccess } from "src/components/notification";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const ListEmail = () => {
	const rawData = [
		{
			id: 11213,
			status: "Hồ sơ mới",
			name: "Xác nhận ứng tuyển",
			create_user: "5001043 - Lê Văn B",
			create_at: "20/10/2023 13:40",
			update_at: "12/12/2021 14:00"
		},
		{
			id: 11213,
			status: "Hồ sơ mới",
			name: "Xác nhận ứng tuyển",
			create_user: "5001043 - Lê Văn B",
			create_at: "20/10/2023 13:40",
			update_at: "12/12/2021 14:00"
		}
	];
	const [data, setData] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [paramsFilter, setParamsFilter] = useState<any>(DEFAULT_FILTER);
	const [visible, setVisible] = useState<any>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const [loading, setLoading] = useState<any>(false);
	const [total, setTotal] = useState(0);
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	let history = useHistory();
	const onChangePaging = (page: number, pageSize: number) => {
		setParamsFilter((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		const initpage = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getEmailTemplate(paramsFilter);
				setData(ress?.data);
				setTotal(ress?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpage();
	}, [paramsFilter,callback]); //eslint-disable-line
	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...paramsFilter,
			...flexParams,
			page: 1
		});
		setParamsFilter(newParams);
	};
	const onCreate: () => void = () => {
		history.push("/list-email/create");
	};
	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch
		// btnImport: btnImport,
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};
	const getDetail = async (id: number) => {
		history.push(`/list-email/details/` + id);
		// setDetail({ id });
		// setVisible(true);
	};
	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D",
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateEmailTemplate(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	return (
		<Loading spinning={loading}>
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}
							// {
							// 	paramsName: "btnSysc",
							// 	icon: <TransactionOutlined />,
							// 	text: "Tạo ticket thanh toán"
							// }
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập tên mẫu Email",
								label: "Tên mẫu",
								paramsName: "search"
							},

							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: STATUS_BASE,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "status"
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.createdRange,
							// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
							// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
							// 	width: 250
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							},
							{ ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={data}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: paramsFilter.page,
							showSizeChanger: true,
							defaultPageSize: paramsFilter.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={columnsData({ onEdit, onCancel,updateStatus, getDetail, pushUpApproval, onCreateTicket, user: account }) as any}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default ListEmail;
