import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useEffect, useState } from "react";
import { notifyError } from "../notification";
// import { PlusOutlined } from '@ant-design/icons';

const UpdaloadImage = ({ maxCount = 2, ...props }) => {
	const [previewVisible, setPreviewVisible] = useState(false);
	const [check, setCheck] = useState(true);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const [loading] = useState(false);
	const maxSizeFile = 1;

	useEffect(() => {
		if (check) {
			const _fileList = props?.fileList?.map((e, idx) => ({
				...e,
				name: e.name ? e.name : `Hình ảnh`
			}));
			setFileList(_fileList);
		}
	}, [props.fileList]); //eslint-disable-line

	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const handleCancel = () => setPreviewVisible(false);

	const handlePreview = (file) => {
		if (file.url || file.preview) {
			setPreviewImage(file.url || file.preview);
			setPreviewTitle(file.name);
		}
		setPreviewVisible(true);
	};

	const handleBeforeUpload = (file) => {
		let check = checkUpload(file);
		if (check === 1) {
			notifyError(`${file.name} phải là hình ảnh jpg hoặc png.`);
		} else if (check === 2) {
			notifyError(`Dung lượng hình ảnh phải nhỏ hơn ${maxSizeFile}MB`);
		}

		return false;
	};

	const checkUpload = (data) => {
		const isJpgOrPng = data.type === "image/jpeg" || data.type === "image/png" || data.type === "image/svg+xml";
		let isError = 0;
		if (!isJpgOrPng) {
			isError = 1;
		}
		// const isLt2M = data.size / 1024 / 1024 <= maxSizeFile;
		// if (!isLt2M) {
		// 	isError = 2;
		// }

		return isError;
	};

	const handleChange = async ({ fileList }) => {
		setCheck(false);
		let file_name = "";
		let img_base64 = "";
		let file = "";

		// console.log("fileList", fileList);
		if (fileList.length !== 0) {
			let check = await checkUpload(fileList[0]);

			if (!fileList[0].url && check === 0) {
				let img = await getBase64(fileList[0].originFileObj);
				img_base64 = img;
				file = fileList[0].originFileObj;
				file_name = fileList[0].name;
			}
			setPreviewImage(img_base64);
			setPreviewTitle(file_name);
		}
		setFileList(fileList);
		props.setChangeFileList(
			fileList
				.filter((e) => e.originFileObj)
				.map((item) => {
					return item.originFileObj;
				})
		);
		props.uploadImg({
			file_name,
			img_base64,
			file
		});
	};

	const uploadButton = (
		<div className="w-full h-full flex flex-col justify-center items-center">
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}> Tải lên </div>
			<p>Hỗ trợ định dạng: PNG, JPG, JPEG, WEBP
</p>
<p>Kích thước: 1200x628
</p>
<p>Dung lượng tối đa 2MB</p>
		</div>
	);
	const onRemove = (e) => {
		props.setRemoveListFile([...props.removeListFile, ...fileList.filter((item) => item.url == e.url)]);
	};
	return (
		<>
			<Upload
				id={props.id ? props.id : "upload_id"}
				// action=""
				className={'w-full h-[200px] border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center bg-gray-100'}
				listType="picture-card"
				// size={400}
				// width={"300px"}
				// height={"200px"}
				fileList={fileList}
				beforeUpload={handleBeforeUpload}
				onPreview={handlePreview}
				onChange={handleChange}
				// onRemove={false}
				onRemove={props.setRemoveListFile ? onRemove : ""}
				accept="image/*"
				disabled={props.disabled}
			>
				{fileList?.length >= maxCount ? null : uploadButton}
			</Upload>
			<Modal
				className="modal-full-padding"
				open={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					// style={{
					// 	width: "100%"
					// }}
					src={previewImage}
				/>
			</Modal>
		</>
	);
};

export default UpdaloadImage;
