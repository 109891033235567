import { Form, Input, Select, Button, InputNumber, Col, Row, Affix } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess, notifyWarning } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import SenderInfo from "./senderInfo";
import ReceiverInfo from "./receiverInfo";
import ServiceInfo from "./serviceInfo";
import CargoInfo from "./cargoInfo";
import { Footer } from "antd/lib/layout/layout";
import CreateOperationModal from "./CreateModal";
const { Option } = Select;
const CreateBill: React.FC = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<any>(false);
	const [visible, setVisible] = useState<any>(false);

	const onCreateBill = async (params: any) => {
		// form.setFieldsValue({ r_province_name: (stateProvinces?.data || []).find((item:any) => item.id === e)?.province_name,r_district_id: undefined, r_ward_id: undefined, r_district_name: undefined, r_ward_name: undefined});
		setLoading(true);
		try {
			const create_res: any = await _apiNotSaga.createBill(params);
			if (create_res.success) {
				form.resetFields();
				return notifySuccess(`Tạo vận đơn thành công. Mã vận đơn: ${create_res.data.bill_code}`);
			}
			return notifyError(`${create_res.message}`);
		} catch (error: any) {
			return notifyError(`${error.message}`);
		} finally {
			setLoading(false);
		}
	};

	const onFinish = (values: any, type: any = 1) => {
		console.log("Form Values:", values);
		values.bill_status_id = type;
		onCreateBill(values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Form Submission Failed:", errorInfo);
	};

	const handleSubmit = (type: any, additional_info:any = {}) => {
		console.log('additional_info', additional_info)
		form
			.validateFields()
			.then((values) => {
				values = {
					...values,
					...additional_info
				}
				onFinish(values, type); // Submit form data if validation is successful
			})
			.catch((errorInfo) => {
				onFinishFailed(errorInfo); // Handle validation errors
			});
	};

	return (
		<Loading spinning={loading}>
			<Form form={form} layout="vertical" onFinish={onFinish} className="space-y-4">
				<div className="contentSection">
					<Row gutter={[16, 16]} className="mt-8">
						<Col xs={12} md={12}>
							<div className="top-0 contentSection-main">
								<SenderInfo form={form} />
							</div>
							<div className="top-10 mt-10 contentSection-main">
								<ReceiverInfo form={form} />
							</div>
						</Col>

						<Col xs={12} md={12}>
							<div className="top-0 contentSection-main">
								<ServiceInfo form={form} />
							</div>
							<div className="top-10 mt-10 contentSection-main">
								<CargoInfo form={form} />
							</div>
						</Col>
					</Row>
					<Form.Item></Form.Item>
				</div>
				<Footer className="bg-white border-t border-gray-200 text-center p-4">
					<div className="flex justify-end items-center container mx-auto">
						<div className="flex space-x-4">
							<Button
								onClick={() => form.resetFields()}
								className="bg-gray-200 text-black border-none rounded-md hover:bg-gray-300"
							>
								Làm mới
							</Button>
							<Button
								className="bg-gray-700 text-white border-none rounded-md hover:bg-gray-800"
								onClick={() => form
									.validateFields()
									.then((values) => {
										setVisible(true); // Submit form data if validation is successful
									})
									.catch((errorInfo) => {
										onFinishFailed(errorInfo); // Handle validation errors
									})}
							>
								Tạo và giao hàng
							</Button>
							<Button
								className="bg-gray-700 text-white border-none rounded-md hover:bg-gray-800"
								onClick={() => handleSubmit(2)}
							>
								Tạo và lấy hàng
							</Button>
							<Button
								className="bg-red-500 text-white border-none rounded-md hover:bg-red-600"
								onClick={() => handleSubmit(1)}
							>
								Tạo đơn
							</Button>
						</div>
					</div>
				</Footer>
				{visible && (
					<CreateOperationModal
						visible={visible}
						onCancel={() => setVisible(false)}
						onConfirm={(params) => {handleSubmit(5, params)}}
						type={"DTW"}
					/>
				)}
			</Form>
		</Loading>
	);
};

export default CreateBill;
