import { Avatar, Button, Dropdown, Menu, Popconfirm, Popover, Progress, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgIcon00060 from "src/assets/svg/SvgIcon00060";
import SvgIcon00061 from "src/assets/svg/SvgIcon00061";
import SvgIcon00139 from "src/assets/svg/SvgIcon00139";
import SvgIcon00178 from "src/assets/svg/SvgIcon00178";
import { renderCell } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import { CND_URL, TICKET_URL } from "src/services/api/config";
import colors from "src/utils/colors";
import { numberWithCommas } from "src/utils/helpers/functions/textUtils";
import "./style.scss";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgPen from "src/assets/svg/SvgPen";
import moment from "moment";
import routerNames from "src/utils/data/routerName";
import SvgDel from "src/assets/svg/SvgDel";
import SvgView from "src/assets/svg/SvgView";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ dataBills, onDelete }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Link to={`/list-profile/${r?.candidate_id}`}>
							<Button>
								<SvgPen fill="black" />
								<span>Chỉnh sửa</span>
							</Button>
						</Link>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Mã vận đơn",
			align: "left",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<Link to={`/bills/${record?.bill_code}`}>
							<p className="text-sky-500 text-[13px] font-normal   underline leading-snug">{record?.bill_code}</p>
						</Link>
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  ">
								{moment(record?.created_at).format("DD/MM/YYYY")}
							</span>
						</div>
						<Tag color={"processing"}>{record?.bill_status_name}</Tag>
					</div>
				);
			}
		},
		{
			title: "Nơi gửi",
			width: "30%",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px] font-bold  ">{record?.s_address}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Nơi nhận",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-start items-center">
						<div className="flex flex-col justify-start items-start px-2">
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px] font-bold  ">{record?.r_name}</p>
							</div>
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px] font-bold  ">{record?.r_phone}</p>
							</div>
							<div className="flex justify-start items-center">
								{/* {record?.gender === "Nam" ? <img src="/Man.svg" /> : <img src="Woman.svg" />} */}
								<p className="text-black  text-opacity-90 text-[13px] font-bold  ">{record?.r_address}</p>
							</div>
						</div>
					</div>
				);
			}
		},
		{
			title: "Thông tin vận đơn",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Dịch vụ: </span>
							{record?.service_name}
						</p>
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Trọng lượng (Kg): </span>
							{record?.weight}
						</p>
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Số kiện: </span>
							{record?.package_number}
						</p>
						<p className="text-14 text-[#212E3B]">
							<span className="text-[#A6A6A6]">Loại hàng hoá: </span>
							{record?.cargo_content}
						</p>
					</div>
				);
			}
		},

		// {
		// 	title: "Đánh giá",
		// 	render: (record: any, index: number) => {
		// 		return <Tag color="processing">{record?.rate}</Tag>;
		// 	}
		// },

		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "13%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button icon={<SvgView />}></Button>
						{!["C", "D"].includes(dataBills.status) ? (
							<Popconfirm
								title="Bạn có muốn xóa hay không?"
								style={{ borderRadius: "10px" }}
								okButtonProps={{
									style: {
										background: "black",
										color: "#fdd800"
									}
								}}
								cancelButtonProps={{
									style: {
										background: "#dfe2e4",
										color: "black"
									}
								}}
								onConfirm={() => {
									onDelete(record);
								}}
								okText="Đồng ý"
								cancelText="Hủy"
							>
								<Button icon={<SvgDel />}></Button>
							</Popconfirm>
						) : (
							<></>
						)}
					</div>
				);
			}
		}
	];
};
export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};
