import { DownloadOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import PanigationAntStyled from "src/components/panigation/PanigationAntStyled";
import TableStyled from "src/components/table/TableStyled";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, STATUS_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { getRoles } from "src/services/actions/roles.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { useScrollYTable } from "src/utils/helpers/functions/useScrollYTable";
import { columnsData } from "./data";
import UserModal from "./UserModal";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import { exportReportUser } from "src/services/actions/export.actions";
import { useHistory } from "react-router-dom";

const TagetJob = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [params, setParams] = useState<any>(DEFAULT_FILTER);
	const [data, setData] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [dataDepartments, setDataDepartments] = useState<any>([]);
	const [total, setToal] = useState<number>(0);
	const [userTypes, setUserTypes] = useState<any>([]);
	const [departmentBlock, setDepartmentBlock] = useState<any>([]);
	const isMount = useIsMount();
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [callback, setCallback] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState<boolean>(false);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const scrollY = useScrollYTable();
	const [dataPosition, setDataPosition] = useState<any>([]);
	let history = useHistory();
	const [zone, setZone] = useState<any>([]);
	useEffect(() => {
		// if (stateGetUserTypes?.data) {
		// 	setUserTypes(stateGetUserTypes?.data?.data);
		// } else {
		// 	dispatch(getUserTypes());
		// }
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
		if (!stateGetRoles?.data) {
			dispatch(getRoles({ status: "A" }));
		}
		const getZone = async () => {
			const res: any = await _apiNotSaga.getZones({});

			setZone(res?.data);
		};
		getZone();
		const getDepartmentBlock = async () => {
			const res: any = await _apiNotSaga.getDepartmentBlock({});
			const _users = res?.data.map((e: any) => ({
				id: e.id,
				name: `${e?.department_block_name}`
			}));
			setDepartmentBlock(_users);
		};
		getDepartmentBlock();
		const initDepart = async () => {
			try {
				const res: any = await _apiNotSaga.getDepartment({ limit: 100, status: "A" });
				setDataDepartments(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initDepart();
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCareer({ limit: 100, status: "A" });
				setDataCareer(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
	}, [dispatch]); //eslint-disable-line

	const onChangeSelect = async (v: any) => {
		if (v.change === "department_id") {
			const initpositions = async () => {
				try {
					// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
					setLoading(true);
					const res: any = await _apiNotSaga.getPositions({ limit: 100, status: "A", department_id: v.department_id });
					setDataPosition(res?.data);
					setLoading(false);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			};
			initpositions();
		}
	};
	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, data, error } = stateGetUserTypes;
	// 	if (success) {
	// 		setUserTypes(data?.data);
	// 		return;
	// 	}
	// 	if (success === false || error) {
	// 		return notifyError(`${error}`);
	// 	}
	// }, [stateGetUserTypes.isLoading]);//eslint-disable-line
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getRecruimentTicket(params);
				setData(res?.data);
				setToal(res?.paging?.total || 0);
				if(!res?.success){
					notifyError(res.message);
				}
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [params, callback]); //eslint-disable-line
	const onCreate: () => void = () => {
		setDetail({});
		setVisible(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const onExport: () => void = () => {
		dispatch(exportReportUser(params));
	};

	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch
		// btnExport: onExport,
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;

		if (val?.idBtn === "changeSelect") {
			return onChangeSelect(val.params);
		}
		handleActions[idBtn](val);
	};

	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D",
			locked: status ? "Y" : "N"
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUser(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	const cancelModal = () => {
		setVisible(false);
	};
	const getDetail = async (id: number) => {
		setDetail({ id });
		setVisible(true);
	};
	const createPost = async (id: number) => {
		history.push(`/create-post?ticket=${id}`);
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	return (
		<>
			{visible && <UserModal visible={visible} detail={detail} onCancel={cancelModal} onSuccess={onSuccessModal} />}
			<div className="contentSection">
				<Loading spinning={loading}>
					<div className="top-0 contentSection-filter">
						<FlexFilter
							onSearch={(v) => actionsFilter(v)}
							elements={[
								{
									...FLEX_FILTER_ELEMENTS.input,
									paramsName: "search",
									placeholder: "Mã ticket",
									width: 150
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Khu vực",
									placeholder: "Chọn khu vực",
									paramsName: "zone_id",
									width: 150,
									options: {
										data: zone,
										keyOption: "id",
										valueOption: "id",
										nameOption: "zone_name"
									}
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Khối",
									placeholder: "Chọn khối",
									paramsName: "department_block_id",
									width: 150,
									options: {
										data: departmentBlock,
										keyOption: "id",
										valueOption: "id",
										nameOption: "name"
									}
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Chi nhánh",
									placeholder: "Chọn chi nhánh",
									paramsName: "org_id",
									width: 150,
									options: {
										data: stateGetOrgs?.data,
										keyOption: "id",
										valueOption: "id",
										nameOption: "org_name"
									}
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Phòng ban",
									placeholder: "Chọn phòng ban",
									paramsName: "department_id",
									// onChange: handleChangeDepartment(e),
									width: 180,
									options: {
										data: dataDepartments,
										keyOption: "id",
										valueOption: "id",
										nameOption: "department_name"
									},
									hasChild: ["position_id"]
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Ngành nghề",
									placeholder: "Chọn ngành nghề",
									paramsName: "career_id",
									width: 180,
									options: {
										data: dataCareer,
										keyOption: "id",
										valueOption: "id",
										nameOption: "career_name"
									}
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Chức danh",
									placeholder: "Chọn chức danh",
									paramsName: "position_id",
									width: 180,
									options: {
										data: dataPosition,
										keyOption: "id",
										valueOption: "id",
										nameOption: "position_name"
									}
								},
								{
									...FLEX_FILTER_ELEMENTS.createdRange,
									initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
									initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
									width: 250
								},
								{
									...FLEX_FILTER_ELEMENTS.btnSearch,
									icon: <SearchOutlined />,
									width: 128
								}
								// { ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined />, width: 120 },
								// {
								// 	...FLEX_FILTER_ELEMENTS.btn,
								// 	icon: <SvgIconPlus fill="black" />,
								// 	width: 128,
								// 	text: "Tạo mới",
								// 	paramsName: "btnAdd",
								// 	style: {
								// 		color: "black",
								// 		fontWeight: 600
								// 	}
								// }
							]}
							// manipulation={[
							// 	{
							// 		paramsName: "btnAdd",
							// 		icon: <PlusOutlined />,
							// 		text: "Tạo mới",
							// 	},
							// 		// {
							// 		// 	paramsName: "btnExport",
							// 		// 	icon: <DownloadOutlined />,
							// 		// 	text: "Tải báo cáo"
							// 		// },
							// ]}
						/>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<TableStyled
							rowKey="id"
							dataSource={data}
							bordered
							// scroll={{ x: 1500 }}
							pagination={false}
							isRowLight={true}
							columns={columnsData({ updateStatus, getDetail, createPost }) as any}
							scroll={{
								x: 1000,
								y: scrollY
							}}
						/>
						<PanigationAntStyled
							style={{ marginTop: "8px" }}
							current={params.page}
							pageSize={params.limit}
							showSizeChanger
							onChange={onChangePaging}
							total={total}
						/>
					</div>
				</Loading>
			</div>
		</>
	);
};

export default TagetJob;
