import { Form, Input, Select, UploadFile } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer as sortableContainer, SortableElement } from "react-sortable-hoc";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import TableStyled from "src/components/table/TableStyled";
import { getModuleFunctDetailChildren } from "src/services/actions/mouduleFunct.actioms";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
// import {
// 	fetchModuleFunctionsList,
// 	getChildrenModuleFunctionById,
// 	updateModuleFunction,
// 	updateModuleFunctionsIndexes
// } from "src/services/actions/moduleFunction.action";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { dateFormatYMD } from "src/utils/helpers/functions/date";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { mergeArray } from "src/utils/helpers/functions/utils";
import { notifyError, notifySuccess } from "../../../components/notification/index";
import { columnsData, TypeModalEnum } from "../edit/data";
import ModuleFunctModal from "./ModuleFunctModal";
// import ModuleFunctModal from "./ModuleFunctModal";

const _paramsFilter = {
	q: undefined,
	status: undefined,
	display_on_website: undefined,
	page: 1,
	limit: 10,
	created_at_start: undefined,
	created_at_end: undefined
};

enum modalType {
	"create" = "create",
	"edit" = "edit"
}

const FormFunction = ({
	visibleModal,
	setVisibleModal,
	typeModal,
	setTypeModal,
	form,
	setOpenImageOverlay,
	setImageOverlay,
	currentFunct,
	editForm
}: any) => {
	const isMount = useIsMount();
	const dispatch = useDispatch();
	const paramsUrl = useParams() as any;
	const [searchForm] = Form.useForm();
	const [showUpdateIndex, setShowUpdateIndex] = useState<boolean>(false);
	const [parentModule, setParentModule] = useState<any>(null);
	const paramsURL = useParams() as any;
	const [functsList, setFunctsList] = useState<any[]>([]);
	const [initialFunctsList, setInitialFunctsList] = useState<any[]>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [icon, setIcon] = useState<UploadFile[]>([]);
	const [icon2, setIcon2] = useState<UploadFile[]>([]);
	const [icon3, setIcon3] = useState<UploadFile[]>([]);
	const [callback, setCallback] = useState<boolean>(false);
	const [statusId, setStatusId] = useState<boolean>(true);
	const { stateGetModuleFunctDetailChildren } = useSelector((e: AppState) => e.moduleFunctReducer);
	useEffect(() => {
		dispatch(getModuleFunctDetailChildren(paramsUrl.id));
	}, [paramsUrl.id, callback]);

	useEffect(() => {
		if (isMount) return;
		const { success, message, error, data } = stateGetModuleFunctDetailChildren;
		if (success) {
			setFunctsList(data);
			setInitialFunctsList(data);
			setShowUpdateIndex(false);
		}

		if (success === false || error) {
			notifyError(`${message}`);
		}
	}, [stateGetModuleFunctDetailChildren.isLoading]);

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, message, error, data } = stateCreateModuleFunction;

	// 	if (success) {
	// 		notifySuccess(`${message}`);
	// 		handleCancelModal();
	// 		form.resetFields();
	// 		dispatch(getChildrenModuleFunctionById(paramsUrl.id, paramsFilter));
	// 	}

	// 	if (success === false || error) {
	// 		notifyError(`${message}`);
	// 	}
	// }, [stateCreateModuleFunction.isLoading]);

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, message, error, data } = stateUpdateModuleFunction;
	// 	if (success) {
	// 		notifySuccess(`${message}`);
	// 		handleCancelModal();
	// 		form.resetFields();
	// 		dispatch(getChildrenModuleFunctionById(paramsUrl.id, paramsFilter));
	// 	}

	// 	if (success === false || error) {
	// 		notifyError(`${message}`);
	// 	}
	// }, [stateUpdateModuleFunction.isLoading]);

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { data, message, success, error } = stateUpdateModuleFunctionIndexes;
	// 	if (success) {
	// 		notifySuccess(`${message}`);
	// 		dispatch(getChildrenModuleFunctionById(paramsUrl.id, paramsFilter));
	// 	}

	// 	if (success === false || error) {
	// 		notifyError(`${message}`);
	// 	}
	// }, [stateUpdateModuleFunctionIndexes.isLoading]);

	const onChangePaging = (page: number, pageSize: number) => {
		setParamsFilter({
			...paramsFilter,
			page: page,
			limit: pageSize
		});
	};

	const onSearch = () => {
		const values = searchForm.getFieldsValue();

		const queryParams: any = {
			page: parseInt(paramsFilter.page),
			limit: parseInt(paramsFilter.limit),
			q: values.q,
			display_on_website: values.display_on_website,
			status: values.status,
			created_at_start: values?.created_at_start?._d
				? moment(values?.created_at_start?._d).format(dateFormatYMD)
				: undefined,
			created_at_end: values?.created_at_end?._d ? moment(values?.created_at_end?._d).format(dateFormatYMD) : undefined
		};
		if (queryParams.created_at_start && !queryParams.created_at_end) {
			// queryParams["created_at_end"] = tomorrow();
		}
		// dispatch(getChildrenModuleFunctionById(paramsUrl.id, queryParams));
	};

	const handleSubmitSearch = (values: any) => {
		onSearch();
	};

	const handleCancelModal = () => {
		setVisibleModal(false);
		form.resetFields();
		setIcon([]);
		setIcon2([]);
		setIcon3([]);
		setStatusId(true);
		setParentModule(null);
	};

	const onViewFunctDetail = (record: any) => {
		let rawFunctCodeLv2=""

		const rawFunctCode = record.funct_code.split("__").slice(-1)[0];
		if(record.funct_code){
			if((record.funct_code.split("__").length-1)>2){
				rawFunctCodeLv2 = record.funct_code.split("__").slice(-2)[0];
			}
		}
		setStatusId(record.status === "A" ? true : false);
		form.setFieldsValue({
			...record,
			app_id: currentFunct?.app_id,
			relative_funct_ids: record.relative_funct_ids ? record.relative_funct_ids.split(',').map((e: any) => +e) : undefined,
			init_funct_code: record.funct_code,
			funct_code: record.funct_code,
			index: record.index,
			mobile_route: record.mobile_route,
			status: record.status === "A" ? true : false,
			raw_funct_code: record.level===1?rawFunctCode:rawFunctCodeLv2
		});
		if (record.ui_icon) {
			const start: any = record?.ui_icon!=="undefined"?[{
				url: `${CND_URL}/${record?.ui_icon}`,
				isNotFile: true
			}]:[]
			setIcon(start);
		}
		if (record.active_icon) {
			const end: any = record?.active_icon!=="undefined"?[
				{
					url: `${CND_URL}/${record.active_icon}`,
					isNotFile: true
				}
			]:[];
			setIcon2(end);
		}
		if (record.mobile_icon) {
			const mobie: any = record?.mobile_icon!=="undefined"?[
				{
					url: `${CND_URL}/${record.mobile_icon}`,
					isNotFile: true
				}
			]:[];
			setIcon3(mobie);
		}

		setTypeModal(TypeModalEnum.EDIT);
		setVisibleModal(true);
	};

	const onAddActions = (currentFunct: any) => {
		form.setFieldsValue({
			parent_id: currentFunct.id,
			level: currentFunct.level + 1,
			funct_code: currentFunct.funct_code,
			init_funct_code: currentFunct.funct_code,
			app_id: currentFunct?.app_id
		});
		setTypeModal(TypeModalEnum.CREATE);
		setVisibleModal(true);
	};

	const onRefreshSearch = () => {
		searchForm.resetFields();
		setParamsFilter(_paramsFilter);
		// dispatch(fetchModuleFunctionsList());
	};

	const checkFunctsDiff = (currentFuncts: any): boolean => {
		let isChanged = false;
		for (let [i, funct] of initialFunctsList.entries()) {
			if (currentFuncts[i].index !== funct.index) {
				isChanged = true;
				break;
			}
		}
		return isChanged;
	};

	const handleSortStart = (props: { node: any }) => {
		const tds = document.getElementsByClassName("row-dragging")[0].childNodes;
		if (tds) {
			props.node.childNodes.forEach((node: any, idx: any) => {
				let htmlElement = tds.item(idx) as HTMLElement;
				htmlElement.style.width = `${node.offsetWidth}px`;
				htmlElement.style.backgroundColor = colors.neutral_color_1_6;
				htmlElement.style.padding = "4px 21px 4px 21px";
			});
		}
	};

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		let _functsList = [...functsList];
		if (oldIndex !== newIndex) {
			const movingItem = _functsList[oldIndex];
			_functsList.splice(oldIndex, 1);
			_functsList = mergeArray(_functsList, [movingItem], newIndex);
		}
		setFunctsList(_functsList);
		onUpdateIndexes(_functsList);
	};

	const SortableContainer = sortableContainer((props: any) => <tbody {...props} />);
	const SortableItem = SortableElement((props: any) => {
		return <tr {...props} />;
	});
	const DraggableContainer = (props: any) => {
		return (
			<SortableContainer
				useDragHandle
				disableAutoscroll
				helperClass="row-dragging"
				onSortEnd={onSortEnd}
				onSortStart={handleSortStart}
				{...props}
			/>
		);
	};

	const DraggableBodyRow = (restProps: any) => {
		let index: number | undefined;
		index = functsList?.findIndex((x: any) => x.index === restProps["data-row-key"]);
		return <SortableItem index={index} {...restProps} />;
	};

	const onUpdateIndexes = (array: any) => {
		const newFunctsPosition = array.map((functItem: any, i: number) => {
			return { id: functItem.id, index: i };
		});
		_apiNotSaga
			.updateModuleFunctIdx({ funct_indexes: newFunctsPosition })
			.then((res: any) => {
				if (res.success) {
					notifySuccess("Cập nhật thành công");
					setCallback((pre: boolean) => !pre);
				}
			})
			.catch((err) => {
				notifyError(err.message);
			});
	};

	const onOpenEditActionModal = (record: any) => {
		form.setFieldsValue({ ...record });
		setVisibleModal(true);
		setTypeModal(TypeModalEnum.EDIT);
		setIcon(record.ui_icon);
	};

	const onOpenImageOverlay = (imageSrc: string) => {
		setOpenImageOverlay(true);
		setImageOverlay(imageSrc);
	};

	const onClickCreateButton = () => {
		form.resetFields();
		form.setFieldsValue({
			parent_id: currentFunct?.id,
			level: currentFunct.level + 1,
			init_funct_code: currentFunct.funct_code,
			funct_code: currentFunct.funct_code,
			app_id: currentFunct?.app_id
		});
		setVisibleModal(true);
		setTypeModal(TypeModalEnum.CREATE);
	};

	const onChangeStatus = (record: any, status: boolean) => {
		const _newData = {
			id: record.id,
			method: record.method,
			parent_id: record.parent_id,
			app_id: currentFunct?.app_id,
			status: status ? "A" : "D",
			ui_route: record.ui_route,
			mobile_route: record.mobile_route,
			level: record.level,
			index: record.index,
			funct_name: record.funct_name,
			funct_code: record.funct_code,
			children: record.children
		};
		_apiNotSaga
			.updateModuleFunct(record.id, _newData)
			.then((res: any) => {
				if (res.success) {
					notifySuccess("Cập nhật thành công");
					setCallback((pre: boolean) => !pre);
					handleCancelModal();
				}
			})
			.catch((err) => {
				notifyError(err.message);
			});
	};
	const renameFile = (originalFile: any, newName: string) => {
		return new File([originalFile], newName, {
			type: originalFile?.type,
			lastModified: originalFile?.lastModified
		});
	};
	const submitEdit = (values: any) => {
		console.log('values',values);
		
		let files: any = [];
		if (values.icon_start_svg && !values.icon_start_svg?.isNotFile)
			files.push(renameFile(values.icon_start_svg, `_ICON_START_SVG.${values.icon_start_svg.name.split('.').pop()}`));
		if (values.activate_icon_end_svg && !values.activate_icon_end_svg?.isNotFile)
			files.push(
				renameFile(values.activate_icon_end_svg, `_ACTIVATE_ICON_END_SVG.${values.activate_icon_end_svg.name.split('.').pop()}`)
			);
		if (values.icon_mobie_svg && !values.icon_mobie_svg?.isNotFile)
			files.push(
				renameFile(values.icon_mobie_svg, `_ICON_MOBIE_SVG.${values.icon_mobie_svg.name.split('.').pop()}`)
			);
		if(typeof (values.status)==="boolean"){
			values.status=values.status ? "A" : "D";
		}
		let params = {
			method: stateGetModuleFunctDetailChildren?.data?.data?.method || "GET",
			funct_name: values?.funct_name,
			funct_code: values?.funct_code,
			status: values?.status,
			ui_route: currentFunct?.ui_route,
			index:currentFunct?.index,
			mobile_route: currentFunct?.mobile_route,
			files
		};
		
		_apiNotSaga
			.updateModuleFunct(paramsUrl.id, params)
			.then((res: any) => {
				if (res.success) {
					notifySuccess("Cập nhật thành công");
					setCallback((pre: boolean) => !pre);
					handleCancelModal();
				}
			})
			.catch((err) => {
				notifyError(err.message);
			});
	};
	return (
		<div className="contentSection">
			{
				visibleModal &&
				<ModuleFunctModal
					form={form}
					visible={visibleModal}
					setVisible={setVisibleModal}
					handleCancel={handleCancelModal}
					type={typeModal}
					icon={icon}
					statusId={statusId}
					setStatusId={setStatusId}
					setCallback={setCallback}
					setIcon={setIcon}
					icon2={icon2}
					icon3={icon3}
					setIcon2={setIcon2}
					setIcon3={setIcon3}
				/>
			}
			<div className="contentSection-main">
				<Form
					form={editForm}
					onFinish={submitEdit}
					id="editForm"
					layout="vertical"
					style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
				>
					<Form.Item
						rules={[{ required: true, message: "Vui lòng không bỏ trống!" }]}
						label="Display name"
						name="funct_name"
						style={{ width: "calc((100% - 272px) /3)", margin: "0" }}
					>
						<Input className="defaultInput" />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: "Vui lòng không bỏ trống!" }]}
						label="Function code"
						name="funct_code"
						style={{ width: "calc((100% - 272px) /3)", margin: "0" }}
					>
						<Input className="defaultInput" />
					</Form.Item>

					<Form.Item label="Trạng thái" name="status" style={{ width: "calc((100% - 272px) /3)", margin: "0" }}>
						<Select
							className="defaultSelect"
							options={[
								{ label: "Hoạt động", value: "A" },
								{ label: "Ẩn", value: "D" }
							]}
						/>
					</Form.Item>
					<div className="defaultButton" onClick={() => editForm.submit()} style={{ marginTop: "13px" }}>
						<SvgIconStorage style={{ transform: "scale(0.7)" }} />
						&nbsp;Lưu
					</div>
					<div className="defaultButton" onClick={() => onClickCreateButton()} style={{ marginTop: "13px" }}>
						<SvgIconPlus style={{ transform: "scale(0.7)" }} />
						&nbsp;Thêm module
					</div>
				</Form>
			</div>
			<div className="contentSection-main" style={{ marginTop: "16px" }}>
				<TableStyled
					rowKey="index"
					dataSource={[...functsList]}
					defaultExpandAllRows={paramsFilter?.q?.length > 0 ? true : false}
					bordered
					isRowLight={true}
					pagination={false}
					columns={
						columnsData({
							onViewFunctDetail,
							onAddActions,
							onOpenImageOverlay,
							onChangeStatus
						}) as any
					}
					expandable={{
						expandIconColumnIndex: 1
					}}
					components={{
						body: {
							wrapper: DraggableContainer,
							row: DraggableBodyRow
						}
					}}
				/>
			</div>

			{/* <PanigationAntStyled
					style={{ marginTop: "8px" }}
					current={paramsFilter.page}
					pageSize={paramsFilter.limit}
					showSizeChanger
					onChange={onChangePaging}
					showTotal={() => `Tổng ${stateChildrenModuleFunctionById?.data?.page?.total} functions `}
					total={stateChildrenModuleFunctionById?.data?.page?.total}
				/> */}
		</div>
	);
};

export default FormFunction;
