import { Button, Col, Form, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const JobModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [departmentBlock, setDepartmentBlock] = useState<any>([]);
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [icon, setIcon] = useState<any>([]);

	useEffect(() => {
		if (isMount) return;
		const { success, data, error } = stateGetUserTypes;
		if (success) {
			setUserTypes(data?.data);
			return;
		}
		if (success === false || error) {
			return notifyError(`${error}`);
		}
	}, [stateGetUserTypes.isLoading]); //eslint-disable-line
	useEffect(() => {
		const getDepartmentBlock = async () => {
			const res: any = await _apiNotSaga.getDepartmentBlock({});
			const _users = res?.data.map((e: any) => ({
				id: e.id,
				name: `${e?.department_block_name}`
			}));
			setDepartmentBlock(_users);
		};
		getDepartmentBlock();
	}, []);
	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.careerDetail(detail.id);
				const mobie: any =
					res?.data?.icon !== "undefined"
						? [
								{
									url: `${CND_URL}/${res?.data?.icon}`,
									isNotFile: true
								}
						  ]
						: [];
				setFileListCover(mobie);
				setIcon(res?.data?.icon)
				form.setFieldsValue({
					...res?.data
					// full_name: res?.data?.profile?.full_name,
					// user_code: res?.data?.profile?.user_code,
					// role_id: res?.data?.role?.id,
					// phone: res?.data?.phone,
					// email: res?.data?.email,
					// user_type_id: res?.data?.profile?.user_type_id,
					// parent_id: res?.data?.profile?.parent_id ? res.data.profile.parent_id : undefined,
					// org_code: res?.data?.profile?.org_code
				});
				// onGetRoles(res?.data?.profile?.user_type_id);
				setStatus(res?.data?.status === "A");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, [departmentBlock.length]); //eslint-disable-line

	const onSubmit = async (val: any) => {
		// setLoading(true);
		const params = {
			...val,
			status: status ? "A" : "D",
			// locked: lock ? "Y" : "N",
			department_block_name: departmentBlock?.find((e: any) => e.id === val.department_block_id)?.name,
			icon: icon
		};
		console.log(params);
		try {
			const res: any = isCreate
				? await _apiNotSaga.createCareer(params)
				: await _apiNotSaga.updateCareer(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const uploadImgCover = (info: any) => {
		console.log(info);
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					console.log(response);
					setIcon(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} ngành nghề`}
				style={{
					maxWidth: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormInput
									name="career_name"
									label="Tên ngành nghề"
									type="text"
									size="large"
									placeholder="Nhập Tên ngành nghề"
									required
									// onBlur={checkUserCodeOnERP}
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="career_code"
									label="Mã ngành nghề"
									type="text"
									size="large"
									placeholder="Nhập mã ngành nghề"
									required
									// onBlur={checkUserCodeOnERP}
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="slug"
									label="Slug"
									type="text"
									size="large"
									placeholder="Nhập Slug"
									required
									// onBlur={checkUserCodeOnERP}
									// disabled={!isCreate}
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="department_block_id"
									label="Khối"
									dataSource={departmentBlock}
									placeholder="Chọn khối"
									slOption={{
										name: "name",
										value: "id"
									}}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<Form.Item name="meta_image" label="Ảnh icon">
									<UpdaloadImage
										fileList={fileListCover}
										className=""
										setChangeFileList={setFileListCover}
										// uploadImg={uploadImg}
										uploadImg={(info: any) => uploadImgCover(info)}
										maxCount={1}
										width={"100%"}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default JobModal;
