/* eslint-disable */
import React from "react";
import { DatePicker, Form, Select } from "antd";
import { geneUniqueKey, removeSign } from "../../utils/helpers/functions/textUtils";
import arrow from "../../assets/images/arrow.svg";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FormInstance } from "./FormPattern";
import { NamePath } from "antd/lib/form/interface";

const { Item } = Form;
const { Option } = Select;

interface OptionProp {
	name: string;
	value?: string;
	disabled?: boolean;
}
interface SelectProp {
	name?: NamePath;
	label?: string;
	onChange?: (value: any, option: any) => void;
	disabled?: boolean | null | undefined;
	classItem?: string;
	id?: string;
	defaultValue?: any;
	value?: any;
	required?: boolean;
	classSelect?: string;
}

const FormSelectDate: React.FC<SelectProp> = ({
	name,
	label,
	onChange,
	id,
	classItem,
	required,
	defaultValue,
	value,
	classSelect,
	...rest
}) => {
	const validator = (type: string | number) => {
		const item = new (FormInstance as any)(type);
		return item.validate();
	};
	return (
		<Item
			className={classItem}
			name={name}
			label={label}
			style={{ marginBottom: "12px", borderRadius: "6px" }}
			{...rest}
			rules={required && validator("text")}
		>
			<DatePicker.RangePicker
				className={classSelect}
				id={id}
				format="DD/MM/YYYY"
				placeholder={["Từ ngày", "Đến ngày"]}
				onChange={onChange}
				defaultValue={defaultValue}
				value={value}
				style={{ borderRadius: "6px" }}
			/>
		</Item>
	);
};

export default FormSelectDate;
