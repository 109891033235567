/*eslint-disable */

import { CloseOutlined } from "@ant-design/icons";
import {
	Affix,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Space,
	Tabs
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import Editor2 from "src/components/editor/EditorV2";
import FormInput from "src/components/form/FormInput";
import FormInputNumber from "src/components/form/FormInputNumber";
import FormSelect from "src/components/form/FormSelect";
import FormUpload from "src/components/form/FormUpload";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { REASON_BASE, SALARY_BASE, WORK_EXP_BASE, WORK_FROM_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";
import { AppState, RouteParams } from "src/types";
import { dateFormatDMY } from "src/utils/helpers/functions/date";
import { localGetAccount } from "src/utils/localStorage";

const DetailsCreatePost = (props: any) => {
	const [isUpload, setIsUpload] = useState<boolean>(false);
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [fileListPopup, setFileListPopup] = useState<any>([]);
	const [formCreate] = Form.useForm();
	const [provinces, setProvinces] = useState<any>([]);
	const editorRef = useRef<any>(null);
	const [ticketID, setTicketID] = useState<any>("");
	const paramsURL = useParams<RouteParams>();
	const [recruimentSeoId, setRecruimentSeoId] = useState<any>("");
	const _account = localGetAccount();
	// const [searchParams, setSearchParams] = useSearchParams();
	const editor1Ref = useRef<any>(null);
	const [metaTitle, setMetaTitle] = useState<any>("");
	const editor2Ref = useRef<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [dataDepartment, setDataDepartment] = useState<any>([]);
	const [dataEducation, setDataEducation] = useState<any>([]);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [isPopup, setIsPopup] = useState<boolean>(false);
	const [isHot, setIsHot] = useState<boolean>(false);
	const [dataZone, setDataZone] = useState<any>([]);
	const dispatch = useDispatch();
	const [stateContent, setStateContent] = useState<any>("");
	const [metadesc, setMetadesc] = useState<any>("");
	const [requestDesc, setRequestDesc] = useState<any>("");
	const [policy, setPolicy] = useState<any>("");
	const [dataUser, setDataUser] = useState<any>([]);
	const [metakeywork, setMetakeywork] = useState<any>("");
	const query = new URLSearchParams(props.location.search);
	const ticketid = query.get("ticket");
	const [metaslug, setMetaslug] = useState<any>("");
	const [metaImage, setMetaImage] = useState<any>("");
	const [jobDesc, setJobDesc] = useState<any>("");
	const [urlPopup, setUrlPopup] = useState<any>("");
	const [showTicket, setShowTicket] = useState<boolean>(false);
	const [statusNews, setStatusNews] = useState<any>("");
	const [slug, setSlug] = useState<any>("");
	const [rangeType, setRangeType] = useState<any>("");
	const [isLoad, setIsLoad] = useState<boolean>(false);
	const [postOffice, setPostOffice] = useState<any>([]);
	useEffect(() => {
		// getRecruimentNewsDetails(paramsURL.id);
		const getProvince = async () => {
			const res: any = await _apiNotSaga.getProvinces({});

			setProvinces(res?.data);
		};
		getProvince();
		const initu = async () => {
			try {
				setLoading(true);
				const res: any = await _apiNotSaga.getRecruimentNewsDetails(paramsURL.id);
				// console.log(moment(res?.data?.expired_at).format(dateFormatDMY));
				// setDataNews(res?.data);
				const initp = async () => {
					try {
						// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
						setLoading(true);
						const ress: any = await _apiNotSaga.getPositions({
							limit: 100,
							status: "A",
							department_id: res?.data?.department_id
						});
						setDataPosition(ress?.data);
						setLoading(false);
					} catch (error: any) {
						setLoading(false);
						notifyError(error.message);
					}
				};
				initp();
				const initS = async () => {
					try {
						// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
						setLoading(true);
						const resss: any = await _apiNotSaga.getRecruimentNewsSeo({ recruitment_news_id: paramsURL.id });
						let dataseo = resss?.data[0] || {};
						setRecruimentSeoId(dataseo?.id);
						setMetaslug(dataseo?.meta_slug);
						setMetadesc(dataseo?.meta_desc);
						setMetaTitle(dataseo?.meta_title);
						setMetakeywork(dataseo?.meta_keywork);
						setMetaImage(dataseo?.meta_image);
						// editorRef.current
						// editor1Ref.current.setContent(dataseo?.request_desc);
						// editor2Ref.current.setContent(dataseo?.policy);
						setJobDesc(dataseo?.job_desc);
						setRequestDesc(dataseo?.request_desc);
						setPolicy(dataseo?.policy);
						const mobie: any =
							dataseo?.meta_image !== "" && dataseo?.meta_image
								? [
										{
											url: `${CND_URL}/${dataseo?.meta_image}`,
											isNotFile: true
										}
								  ]
								: [];
						setFileListCover(mobie);
						formCreate.setFieldsValue({
							meta_slug: dataseo?.meta_slug,
							meta_title: dataseo?.meta_title,
							meta_desc: dataseo?.meta_desc,
							meta_keywork: dataseo?.meta_keywork,
							meta_image: dataseo?.meta_image || ""
						});
						setLoading(false);
					} catch (error: any) {
						setLoading(false);
						notifyError(error.message);
					}
				};
				initS();
				setStatusNews(res?.data?.status);
				setSlug(res?.data?.slug);
				setIsUpload(res?.data?.is_cv === 1 ? true : false);
				setIsPopup(res?.data?.is_popup === 1 ? true : false);
				setIsHot(res?.data?.is_hot === 1 ? true : false);
				setShowTicket(res?.data?.ticket_id !== "" && res?.data?.ticket_id !== 0 ? true : false);
				setTicketID(res?.data?.ticket_id);
				const initz = async () => {
					try {
						let params = {
							ClientKey: "mobileapp",
							// Type: "01",
							CityCode: res?.data?.province_id.toString()
						};
						// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
						setLoading(true);
						const ress: any = await _apiNotSaga.getPostOffices(params);
						if (ress?.ReturnCode === "200") {
							const uniqueAddresses = (addresses:any) => {
								const seen = new Set();
								return addresses.filter((item:any) => {
									const duplicate = seen.has(item.address);
									seen.add(item.address);
									return !duplicate;
								});
							};
							const uniqueAddressArray = uniqueAddresses(ress.Data);
							setPostOffice(uniqueAddressArray);
						}
						// setDataZone(res?.data);
						setLoading(false);
					} catch (error: any) {
						setLoading(false);
						notifyError(error.message);
					}
				};
				initz();
				const mobies: any =
					res?.data?.url_popup !== "" && res?.data?.url_popup
						? [
								{
									url: `${CND_URL}/${res?.data?.url_popup}`,
									isNotFile: true
								}
						  ]
						: [];
				setFileListPopup(mobies);
				setUrlPopup(res?.data?.url_popup);
				formCreate.setFieldsValue({
					...res?.data,
					work_location: res?.data?.work_location !== "" ? JSON?.parse(res?.data?.work_location) : [],
					expired_at: moment(res?.data?.expired_at),
					public_at: moment(res?.data?.public_at),
					salary_type: res?.data?.salary_type?.toString()
				});
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	}, [isLoad]);
	// useEffect(() => {
	// 	if (ticketid) {
	// 		setLoading(true);
	// 		const init = async () => {
	// 			try {
	// 				// if (users.length === 0 || !stateGetOrgs?.data) return;
	// 				setLoading(true);
	// 				const res: any = await _apiNotSaga.recruimentTicketDetails(ticketid);
	// 				const initp = async () => {
	// 					try {
	// 						// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 						setLoading(true);
	// 						const ress: any = await _apiNotSaga.getPositions({
	// 							limit: 100,
	// 							status: "A",
	// 							department_id: res?.data?.department_id
	// 						});
	// 						setDataPosition(ress?.data);
	// 						setLoading(false);
	// 					} catch (error: any) {
	// 						setLoading(false);
	// 						notifyError(error.message);
	// 					}
	// 				};
	// 				initp();
	// 				formCreate.setFieldsValue({
	// 					career_id: res?.data?.career_id,
	// 					career_name: res?.data?.career_name,
	// 					department_block_id: res?.data?.department_block_id,
	// 					department_block_name: res?.data?.department_block_name,
	// 					department_id: res?.data?.department_id,
	// 					department_name: res?.data?.department_name,
	// 					org_id: res?.data?.org_id,
	// 					org_name: res?.data?.org_name,
	// 					position_id: res?.data?.position_id,
	// 					position_name: res?.data?.position_name,
	// 					work_location_id: res?.data?.work_location_id,
	// 					work_location_name: res?.data?.work_location_name,
	// 					zone_id: res?.data?.zone_id,
	// 					zone_name: res?.data?.zone_name,
	// 					ticket_id: res?.data?.ticket_id
	// 					// qty: res?.data?.qty?.toString(),
	// 				});
	// 				// handleChangeOrg(res?.data?.org_id);
	// 				// handleChangeDepartment(res?.data?.department_id);
	// 				// setStatus(res?.data?.status === "A");
	// 				// setLock(res?.data?.locked === "Y");
	// 				setLoading(false);
	// 			} catch (error: any) {
	// 				setLoading(false);
	// 				notifyError(error.message);
	// 			}
	// 		};
	// 		init();
	// 	}
	// }, []);
	const onSubmitForm = async (val: any) => {
		console.log(val?.salary_from);
		delete val.meta_slug;
		delete val.meta_title;
		delete val.meta_image;
		if (val?.work_location?.length === 0 || !val?.work_location) return notifyError("Vui lòng nhập địa điểm làm việc");
		if (val?.public_at > val?.expired_at) return notifyError("Ngày công bố không được lớn hơn ngày hết hạn");
		if (val?.salary_from > val?.salary_to) return notifyError("Lương từ không được lớn hơn lương đến");
		if (parseInt(val?.qty) < 1) return notifyError("Số lượng tuyển không được nhỏ hơn 1");
		if (parseInt(val?.salary_from) < 0) return notifyError("Lương từ không được nhỏ hơn 0");
		if (isPopup && !urlPopup) return notifyError("Vui lòng nhập ảnh popup");
		if (editorRef?.current?.getContent().length === 0 ) return notifyError("Vui lòng nhập nội dung mô tả công việc");
		if (editor1Ref?.current?.getContent().length === 0) return notifyError("Vui lòng nhập nội dung yêu cầu công việc");
		if (editor2Ref?.current?.getContent().length === 0 ) return notifyError("Vui lòng nhập nội dung chính sách công việc");
		const _newParams = {
			...val,
			status: "O",
			career_name: dataCareer?.find((e: any) => e.id === val?.career_id)?.career_name,
			department_name: dataDepartment?.find((e: any) => e.id === val?.department_id)?.department_name,
			position_name: dataPosition?.find((e: any) => e.id === val?.position_id)?.position_name,
			education_name: dataEducation?.find((e: any) => e.id === val?.education_id)?.education_name,
			zone_name: dataZone?.find((e: any) => e.id === val?.zone_id)?.zone_name,
			org_name: stateGetOrgs?.data?.find((e: any) => e.id === val?.org_id)?.org_name,
			work_location: JSON?.stringify(val?.work_location),
			public_at: moment(val?.public_at).format("YYYY-MM-DD"),
			expired_at: moment(val?.expired_at).format("YYYY-MM-DD"),
			salary_type: val?.salary_type?.toString(),
			is_cv: isUpload ? 1 : 0,
			is_popup: isPopup ? 1 : 0,
			is_hot: isHot ? 1 : 0,
			url_popup: urlPopup,
			province_name: provinces?.find((e: any) => e.id === val?.province_id)?.province_name
			// created_by: `${dataUser?.find((e: any) => e.id === val?.created_by)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.created_by)?.full_name}`,
			// approved_by: `${dataUser?.find((e: any) => e.id === val?.approved_by)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.approved_by)?.full_name}`,
			// user_cv_id: `${dataUser?.find((e: any) => e.id === val?.user_cv_id)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.user_cv_id)?.full_name}`,
		};
		console.log(_newParams);
		if (metaTitle === "" && metaslug === "" && metadesc === "" && metakeywork === "" && metaImage === "") {
			return notifyError("Vui lòng nhập đầy đủ thông tin SEO");
		}
		try {
			const res: any = await _apiNotSaga.updateRecruimentNews(paramsURL.id, _newParams);
			console.log(recruimentSeoId);
			if (recruimentSeoId) {
				const _newParams2 = {
					recruitment_news_id: paramsURL.id,
					job_desc: editorRef?.current?.getContent() || jobDesc,
					request_desc: editor1Ref?.current?.getContent() || requestDesc,
					policy: editor2Ref?.current?.getContent() || policy,
					meta_title: metaTitle,
					meta_slug: metaslug,
					meta_desc: metadesc,
					meta_keywork: metakeywork,
					meta_image: metaImage
				};
				console.log(_newParams2);
				const ress: any = await _apiNotSaga.updateRecruimentNewsSeo(recruimentSeoId, _newParams2);
				setLoading(false);
				
			}
			setIsLoad(!isLoad);
			notifySuccess(res.message);
			// onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
		// isEdit
		// 	? dispatch(updateVehicleGroup(stateGetVehicleGroupById?.data?.id, _newParams))
		// 	: dispatch(createVehicleGroup(_newParams));
	};
	const handleAccept = async (val: any) => {
		const _newParams = {
			status: "A"
		};
		const res: any = await _apiNotSaga.updateRecruimentNews(paramsURL.id, _newParams);
		setIsLoad(!isLoad);
		notifySuccess(res.message);
	};
	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getUsers({ limit: 100, status: "A" });
				setDataUser(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCareer([]);
				setDataCareer(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();

		const initd = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getDepartment([]);
				setDataDepartment(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initd();
		const inite = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getEducation([]);
				setDataEducation(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		inite();
		const initz = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getZones([]);
				setDataZone(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initz();
	}, []); //eslint-disable-line
	const handleChangeDepartment = (e: any) => {
		console.log(e);
		const initp = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPositions({ limit: 100, status: "A", department_id: e });
				setDataPosition(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initp();
	};
	const uploadImgCover = (info: any) => {
		console.log(info);
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					console.log(response);
					setMetaImage(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	const uploadImgPopup = (info: any) => {
		console.log(info);
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					console.log(response);
					setUrlPopup(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	const handleChangeRange = (e: any) => {
		setRangeType(e);
	};
	const handleChangeProvince = (e: any) => {
		console.log(e);
		const initz = async () => {
			try {
				let params = {
					ClientKey: "mobileapp",
					// Type: "01",
					CityCode: e.toString()
				};
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPostOffices(params);
				if (res?.ReturnCode === "200") {
					const uniqueAddresses = (addresses:any) => {
						const seen = new Set();
						return addresses.filter((item:any) => {
							const duplicate = seen.has(item.address);
							seen.add(item.address);
							return !duplicate;
						});
					};
					const uniqueAddressArray = uniqueAddresses(res.Data);
					setPostOffice(uniqueAddressArray);
				}
				// setDataZone(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initz();
	};
	const { features, menu: _menu, ..._user } = _account ? JSON.parse(_account) : null;
	return (
		<div className="p-6 trip-detail">
			<Form name="myForm" layout="vertical" form={formCreate} onFinish={onSubmitForm}>
				<Card className="p-0 rounded-md shadow-sm border border-neutral-300 ">
					<Tabs
						className="bg-white"
						defaultActiveKey="1"
						// onChange={onChange}
						items={[
							{
								label: `Thông tin chung`,
								key: "1",
								children: (
									<div className="">
										<div className={`bg-white p-8 rounded-2xl border border-slate-300 m-4`}>
											<div className="flex w-full justify-between items-center">
												<Title level={4}>Thông tin chung</Title>
												{showTicket ? (
													<p className="font-bold">
														Ticket ID:{" "}
														<a target="_blank" href={`https://ticketdev.ntlogistics.vn/tickets/${ticketID}`}>
															<span className="text-link">#{ticketID}</span>
														</a>
													</p>
												) : null}
											</div>

											<Row gutter={[24, 24]}>
												<Col md={12} xs={24} sm={24}>
													<Row gutter={[24, 24]}>
														<Col md={24} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="thread"
																label="Tiêu đề"
																type="text"
																size="large"
																required
																placeholder="Nhập tiêu đề"
																classItem="mb-0"
															/>
														</Col>
														{/* <Col md={12} xs={24} sm={24}>
														<FormInput
															id="form-carGroup_name"
															name="carGroup_name"
															label="SEO"
															type="text"
															size="large"
															placeholder="Nhập SEO"
															classItem="mb-0"
														/>
													</Col> */}
													</Row>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="province_id"
																label="Tỉnh thành"
																dataSource={provinces}
																placeholder="Tỉnh thành"
																slOption={{
																	name: "province_name",
																	value: "id"
																}}
																showSearch
																onChange={(e) => handleChangeProvince(e)}
																optionFilterProp="children"
																required
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="career_id"
																label="Ngành nghề"
																required
																dataSource={dataCareer}
																placeholder="Chọn ngành nghề"
																slOption={{
																	name: "career_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="department_id"
																label="Phòng ban"
																required
																dataSource={dataDepartment}
																placeholder="Chọn Phòng ban"
																slOption={{
																	name: "department_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																onChange={handleChangeDepartment}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="position_id"
																label="Chức danh"
																required
																dataSource={dataPosition}
																placeholder="Chọn chức danh"
																slOption={{
																	name: "position_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="education_id"
																label="Bằng cấp"
																dataSource={dataEducation}
																required
																placeholder="Chọn Bằng cấp"
																slOption={{
																	name: "education_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="form_work"
																required
																label="Loại hình công việc"
																dataSource={WORK_FROM_BASE}
																placeholder="Chọn Loại hình công việc"
																slOption={{
																	name: "name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="reason"
																label="Lý do tuyển"
																required
																dataSource={REASON_BASE}
																placeholder="Chọn Lý do tuyển"
																slOption={{
																	name: "name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="zone_id"
																label="Vùng miền"
																required
																dataSource={dataZone}
																placeholder="Chọn Vùng miền"
																slOption={{
																	name: "zone_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="org_id"
																label="Chi nhánh"
																required
																dataSource={stateGetOrgs?.data}
																placeholder="Chọn Chi nhánh"
																slOption={{
																	name: "org_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="work_experience"
																label="Kinh nghiệm"
																required
																dataSource={WORK_EXP_BASE}
																placeholder="Chọn kinh nghiệm"
																slOption={{
																	name: "name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
												</Col>
												<Col md={12} xs={24} sm={24}>
													<div className="bg-neutral-100 w-full h-[326px] overflow-y-scroll px-4">
														<Form.List name={"work_location"}>
															{(subFields, subOpt) => (
																<div className="w-full py-4 " style={{ display: "flex", flexDirection: "column" }}>
																	{subFields.map((subField) => (
																		<div key={subField.key}>
																			<Row gutter={[8, 8]}>
																				<Col md={16} xs={24} sm={24}>
																					<Form.Item noStyle name={[subField.name, "loc"]}>
																						{/* <Input placeholder="Địa điểm làm việc" /> */}
																						<Select showSearch allowClear className="w-full">
																							{postOffice?.map((e: any) => (
																								<Select.Option value={e.address}>{e.address}</Select.Option>
																							))}
																						</Select>
																					</Form.Item>
																				</Col>
																				<Col md={7} xs={24} sm={24}>
																					<Form.Item
																						rules={[
																							{
																								type: "number",
																								min: 0,
																								max: 99,
																								message: "SL tuyển lớn hơn 0 và nhỏ hơn 100!"
																							}
																						]}
																						// noStyle
																						name={[subField.name, "qty"]}
																					>
																						<InputNumber placeholder="SL tuyển" required />
																					</Form.Item>
																				</Col>
																				<Col md={1} xs={24} sm={24}>
																					<div className="pt-1">
																						<CloseOutlined
																							onClick={() => {
																								subOpt.remove(subField.name);
																							}}
																						/>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	))}
																	<div className="defaultButtonDetail mr-0 w-full" onClick={() => subOpt.add()}>
																		<SvgIconPlus style={{ transform: "scale(0.7)" }} />
																		&nbsp;<p className="text-[14px] font-[900]">Thêm địa điểm</p>
																	</div>
																	{/* <Button type="dashed" onClick={() => subOpt.add()} block>
																+ Thêm địa điểm
															</Button> */}
																</div>
															)}
														</Form.List>
														{/* <Row gutter={[24, 24]}>
														<Col md={16} xs={24} sm={24}>
															<FormSelect
																name="vehicle_group"
																label="Địa điểm làm việc"
																dataSource={[]}
																placeholder="Chọn Địa điểm làm việc"
																slOption={{
																	name: "group_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={6} xs={24} sm={24}>
															<FormInputNumber
																id="form-km_number"
																name="km_number"
																label="SL tuyển"
																placeholder="0"
																size="large"
																classItem="mb-0"
																formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																// required
															/>
														</Col>
													</Row>
													<div
														className="defaultButtonDetail mr-0"
														onClick={() => {
															// setVisible(true);
														}}
													>
														<SvgIconPlus style={{ transform: "scale(0.7)" }} />
														&nbsp;<p className="text-[14px] font-[900]">Thêm địa điểm</p>
													</div> */}
													</div>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<Form.Item
																name="public_at"
																rules={[{ required: true, message: "Không được bỏ trống!" }]}
																label="Ngày công bố"
															>
																<DatePicker
																	disabledDate={(current) => current && current <= moment().startOf("day")}
																	placeholder="Ngày công bố"
																	format={dateFormatDMY}
																	onChange={(val) => {
																		// const { routing_road_id, start_time_at } = createForm.getFieldsValue([
																		// 	"routing_road_id",
																		// 	"start_time_at"
																		// ]);
																		// loadRoutingRoadPlaces(routing_road_id, val, start_time_at);
																	}}
																/>
															</Form.Item>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<Form.Item
																name="expired_at"
																rules={[{ required: true, message: "Không được bỏ trống!" }]}
																label="Ngày hết hạn"
															>
																<DatePicker
																	disabledDate={(current) => current && current <= moment().startOf("day")}
																	placeholder="Ngày hết hạn"
																	format={dateFormatDMY}
																	onChange={(val) => {
																		// const { routing_road_id, start_time_at } = createForm.getFieldsValue([
																		// 	"routing_road_id",
																		// 	"start_time_at"
																		// ]);
																		// loadRoutingRoadPlaces(routing_road_id, val, start_time_at);
																	}}
																/>
															</Form.Item>
														</Col>
														{/* <Col md={12} xs={24} sm={24}>
														<FormSelect
															name="vehicle_group"
															label="Công ty"
															dataSource={[]}
															placeholder="Chọn Công ty"
															slOption={{
																name: "group_name",
																value: "id"
															}}
															showSearch
															optionFilterProp="children"
															// onChange={handleChangeGroup}
														/>
													</Col> */}
													</Row>
												</Col>
											</Row>
											{/* <Row gutter={[24, 24]}>
												<Col xs={24} md={8}>
													<FormSelect
														name="province_id"
														label="Tỉnh thành"
														dataSource={provinces}
														placeholder="Tỉnh thành"
														slOption={{
															name: "province_name",
															value: "id"
														}}
														showSearch
														optionFilterProp="children"
														required
													/>
												</Col>
											</Row> */}
										</div>
										<div className={`bg-white p-8 rounded-2xl border border-slate-300 m-4 mb-[120px]`}>
											<Row gutter={[24, 24]}>
												<Col md={12} xs={24} sm={24}>
													<Title level={4}>Mức thu nhập</Title>
													<Row gutter={[24, 24]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="salary_type"
																label="Mức lương"
																required
																dataSource={SALARY_BASE}
																placeholder="Chọn Mức lương"
																slOption={{
																	name: "name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																onChange={handleChangeRange}
																// onChange={handleChangeGroup}
															/>
														</Col>
														{rangeType !== "1" && (
															<Col md={6} xs={24} sm={24}>
																<FormInputNumber
																	// id="form-km_number"
																	name="salary_from"
																	label="Từ"
																	placeholder="0"
																	size="large"
																	classItem="mb-0"
																	formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																	parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																	required
																/>
															</Col>
														)}
														{rangeType !== "1" && (
															<Col md={6} xs={24} sm={24}>
																<FormInputNumber
																	// id="form-km_number"
																	name="salary_to"
																	label="Đến"
																	placeholder="0"
																	size="large"
																	classItem="mb-0"
																	formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																	parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																	required
																/>
															</Col>
														)}
													</Row>
													<Row gutter={[8, 8]}>
														<Col md={24} xs={24} sm={24}>
															<Checkbox checked={isUpload} onChange={(e) => setIsUpload(!isUpload)}>
																Yêu cầu Upload CV khi ứng tuyển
															</Checkbox>
														</Col>

														<Col md={24} xs={24} sm={24}>
															<Checkbox checked={isHot} onChange={(e) => setIsHot(!isHot)}>
																Đặt tin Hot
															</Checkbox>
														</Col>
														<Col md={24} xs={24} sm={24}>
															<Checkbox checked={isPopup} onChange={(e) => setIsPopup(!isPopup)}>
																Đặt tin Popup
															</Checkbox>
														</Col>
														{isPopup && (
															<Col md={24} xs={24} sm={24}>
																<Form.Item name="url_popup" label="Ảnh popup">
																	<UpdaloadImage
																		fileList={fileListPopup}
																		className=""
																		setChangeFileList={setFileListPopup}
																		// uploadImg={uploadImg}
																		uploadImg={(info: any) => uploadImgPopup(info)}
																		maxCount={1}
																		width={"100%"}
																	/>
																</Form.Item>
															</Col>
														)}
													</Row>
												</Col>
												<Col md={12} xs={24} sm={24}>
													<Title level={4}>Thông tin liên hệ</Title>
													<Row gutter={[8, 8]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="created_by"
																label="Người đăng tin"
																dataSource={dataUser}
																required
																placeholder="Chọn Người đăng tin"
																slOption={{
																	name: "full_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="created_phone"
																label="Số điện thoại liên hệ"
																type="phone"
																required
																size="large"
																placeholder="Nhập Số điện thoại liên hệ"
																classItem="mb-0"
															/>
														</Col>
														{/* <Col md={12} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="work_hour"
																label="Giờ làm việc"
																required
																type="text"
																size="large"
																placeholder="Nhập Giờ làm việc"
																classItem="mb-0"
															/>
														</Col> */}
														<Col md={12} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="created_email"
																label="Email liên hệ"
																required
																type="email"
																size="large"
																placeholder="Nhập Email liên hệ"
																classItem="mb-0"
															/>
														</Col>
														<Col md={24} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="address_interview"
																required
																label="Địa chỉ phỏng vấn"
																type="text"
																size="large"
																placeholder="Nhập Địa chỉ phỏng vấn"
																classItem="mb-0"
															/>
															{/* <FormSelect
															name="address_interview"
															label="Địa chỉ phỏng vấn"
															dataSource={[]}
															placeholder="Chọn Địa chỉ phỏng vấn"
															slOption={{
																name: "group_name",
																value: "id"
															}}
															showSearch
															optionFilterProp="children"
															// onChange={handleChangeGroup}
														/> */}
														</Col>
													</Row>
													<Title level={4}>Hội đồng tuyển dụng</Title>
													<Row gutter={[8, 8]}>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="approved_by"
																label="Người duyệt tin"
																required
																dataSource={dataUser}
																placeholder="Chọn Người duyệt tin"
																slOption={{
																	name: "full_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={12} xs={24} sm={24}>
															<FormSelect
																name="user_cv_id"
																label="Người duyệt hồ sơ"
																required
																dataSource={dataUser}
																placeholder="Chọn Người duyệt hồ sơ"
																slOption={{
																	name: "full_name",
																	value: "id"
																}}
																showSearch
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</div>
									</div>
								)
							},
							{
								label: `Nội dung & SEO`,
								key: "2",
								children: (
									<>
										<div className={`m-4`}>
											<Row gutter={[24, 8]}>
												<Col className={``} md={16} xs={24} sm={24}>
													<Title level={4}>Nội dung</Title>
													<p className="text-16 font-medium mt-5 mb-1"><span className='text-red-500 text-[10px] align-middle'>✽</span> Mô tả</p>
													<Editor2
														refEditor={editorRef}
														textareaName="job_desc"
														onInit={(evt: any, editor: any) => (editorRef.current = editor)}
														initialValue={jobDesc || ""}
													/>
													<p className="text-16 font-medium  mb-1 mt-[30px]"><span className='text-red-500 text-[10px] align-middle'>✽</span> Yêu cầu công việc</p>
													<Editor2
														refEditor={editor1Ref}
														textareaName="content"
														onInit={(evt: any, editor: any) => (editor1Ref.current = editor)}
														initialValue={requestDesc || ""}
													/>
													<p className="text-16 font-medium  mb-1 mt-[30px]"><span className='text-red-500 text-[10px] align-middle'>✽</span> Chính sách</p>
													<Editor2
														refEditor={editor2Ref}
														textareaName="content"
														onInit={(evt: any, editor: any) => (editor2Ref.current = editor)}
														initialValue={policy || ""}
													/>
												</Col>
												<Col className={``} md={8} xs={24} sm={24}>
													<Title level={4}>SEO</Title>
													<div className="flex justify-between items-center">
														<div className="w-[83px] text-black text-opacity-90 text-sm font-normal   leading-snug">
															Slug
														</div>
													</div>
													<FormInput
														// id="form-carGroup_name"
														name="meta_slug"
														// label="Giờ làm việc"
														onChange={(value: any) => {
															setMetaslug(value.target.value);
														}}
														type="text"
														size="large"
														placeholder="Nhập Slug"
														classItem="mb-0"
													/>
													<div className="flex justify-between items-center">
														<div className="w-[83px] text-black text-opacity-90 text-sm font-normal   leading-snug">
															Meta Title
														</div>
														<div className="w-[148px] text-right">
															<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
																Số ký tự đã dùng:
															</span>
															<span className="text-black text-opacity-90 text-xs font-normal   leading-snug">
																{" "}
																{metaTitle?.length}/70
															</span>
														</div>
													</div>
													<FormInput
														// id="form-carGroup_name"
														name="meta_title"
														// label="Giờ làm việc"
														onChange={(value: any) => {
															setMetaTitle(value.target.value);
														}}
														type="text"
														size="large"
														placeholder="Nhập tiêu đề / Bỏ trống hệ thống tự tạo tiêu đề"
														classItem="mb-0"
													/>
													<div className="flex justify-between items-center mt-[20px]">
														<div className=" text-black text-opacity-90 text-sm font-normal   leading-snug">
															Meta Descriptions
														</div>
														<div className="w-[148px] text-right">
															<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
																Số ký tự đã dùng:
															</span>
															<span className="text-black text-opacity-90 text-xs font-normal   leading-snug">
																{" "}
																{metadesc?.length}/320
															</span>
														</div>
													</div>
													<TextArea
														rows={6}
														placeholder="Nhập metadesription
								"
														onChange={(value: any) => {
															setMetadesc(value.target.value);
														}}
														value={metadesc}
													/>
													<div className="w-[133px] mt-[20px] text-black text-opacity-90 text-sm font-normal   leading-snug">
														Meta Keywords
													</div>
													<TextArea
														rows={6}
														placeholder="Autosize height based on content lines
								"
														onChange={(value: any) => {
															setMetakeywork(value.target.value);
														}}
														value={metakeywork}
													/>
													{/* <div className="w-[133px] mt-[20px] text-black text-opacity-90 text-sm font-normal   leading-snug">
														Meta Image
													</div> */}
													<Form.Item name="meta_image" className="mt-2" label="Ảnh cover/ Meta Image">
														<UpdaloadImage
															fileList={fileListCover}
															className=""
															setChangeFileList={setFileListCover}
															// uploadImg={uploadImg}
															uploadImg={(info: any) => uploadImgCover(info)}
															maxCount={1}
															width={"100%"}
														/>
													</Form.Item>
												</Col>
											</Row>
										</div>
									</>
								)
							}
						]}
					/>
				</Card>
				<Affix offsetBottom={2}>
					<div className="bg-[#404040] h-[60px] flex justify-end items-center w-full shadow-2xl border border-black">
						{(statusNews === "A" || statusNews === "D") && (
							<Button
								size="large"
								className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
							>
								<a
									href={`${TUYENDUNG_URL}${slug}/${metaslug}`}
									target="_blank"
									className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold"
								>
									Xem Tin
								</a>
							</Button>
						)}
						{(statusNews === "O" || statusNews === 'W') && formCreate.getFieldValue('approved_by') === _user?.user?.id   && (
							<Button
								size="large"
								// block={true}
								// type="ghost"
								className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
								onClick={() => {
									handleAccept(formCreate.getFieldsValue());
								}}
							>
								<p className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold">Duyệt tin</p>
							</Button>
						)}
						<Button
							size="large"
							// block={true}
							// type="link"
							htmlType="submit"
							form="myForm"
							className="mr-[20px] bg-[#FADB14] hover:bg-[#FADB14] text-[#404040] font-bold"
						>
							<p className="text-[15px] text-[#404040] hover:text-[#404040]">Cập nhật</p>
						</Button>
					</div>
				</Affix>
			</Form>
		</div>
	);
};

export default DetailsCreatePost;
