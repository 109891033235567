/*eslint-disable */

import { Affix, Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Tabs, TimePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import Editor2 from "src/components/editor/EditorV2";
import FormInput from "src/components/form/FormInput";
import FormInputNumber from "src/components/form/FormInputNumber";
import FormSelect from "src/components/form/FormSelect";
import FormUpload from "src/components/form/FormUpload";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { dateFormatDMY } from "src/utils/helpers/functions/date";
import { convertToSlug } from "src/utils/helpers/functions/rules";

const CreateNew = (props: any) => {
	const formatTime = "HH:mm";
	let history = useHistory();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isUpload, setIsUpload] = useState<boolean>(false);
	const [formCreate] = Form.useForm();
	const [metaTitle, setMetaTitle] = useState<string>("");
	const [metaDes, setMetaDes] = useState<string>("");
	const editorRef = useRef<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [dataPostType, setDataPostType] = useState<any>([]);
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [coverFile, setCoverFile] = useState<any>([]);
	const [fileListMeta, setFileListMeta] = useState<any>([]);
	const [metaFile, setMetaFile] = useState<any>([]);
	const [content, setContent] = useState<any>("");
	const onSubmitFormDraf = async (val: any) => {
		if (val.post_type_id === undefined) return notifyError("Vui lòng chọn loại tin");
		if (val.thread === undefined) return notifyError("Vui lòng nhập tiêu đề");
		if (val.slug === undefined) return notifyError("Vui lòng nhập slug");
		const _newParams = {
			...val,
			post_type_name: dataPostType?.find((e: any) => e.id === val.post_type_id)?.post_type_name,
			site: dataPostType?.find((e: any) => e.id === val.post_type_id)?.site,
			status: "D",
			content: editorRef?.current?.getContent(),
			public_at: val.public_at
				? moment(val.public_at).format("YYYY-MM-DD HH:mm:ss")
				: moment().format("YYYY-MM-DD HH:mm:ss")
		};
		console.log(_newParams);
		try {
			const res: any = await _apiNotSaga.createPostNews(_newParams);
			setLoading(false);
			notifySuccess(res.message);
			// onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onSubmitForm = async (val: any) => {
		if (val.post_type_id === undefined) return notifyError("Vui lòng chọn loại tin");
		if (val.thread === undefined) return notifyError("Vui lòng nhập tiêu đề");
		if (val.slug === undefined) return notifyError("Vui lòng nhập slug");
		const _newParams = {
			...val,
			post_type_name: dataPostType?.find((e: any) => e.id === val.post_type_id)?.post_type_name,
			site: dataPostType?.find((e: any) => e.id === val.post_type_id)?.site,
			status: "P",
			content: editorRef?.current?.getContent(),
			public_at: val.public_at
				? moment(val.public_at).format("YYYY-MM-DD HH:mm:ss")
				: moment().format("YYYY-MM-DD HH:mm:ss")
		};
		console.log(_newParams);
		try {
			const res: any = await _apiNotSaga.createPostNews(_newParams);
			setLoading(false);
			notifySuccess(res.message);
			history.push(`/list-new`);
			// onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPostType({});
				setDataPostType(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []);
	const uploadImgCover = (info: any) => {
		console.log(info);
		if (info.file) {
			let file = info.file;
			setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					console.log(response);
					return formCreate.setFieldsValue({
						thumbnail: response.data[0],
						meta_image: response.data[0]
					});
				}
			});
		}
	};
	// const uploadImgMeta = (info: any) => {
	// 	if (info.file) {
	// 		let file = info.file;
	// 		setMetaFile(file);
	// 		uploadFile(file, (response: any) => {
	// 			if (response) {
	// 				console.log(response);
	// 				return formCreate.setFieldsValue({
	// 					meta_image: response.data[0]
	// 				});
	// 			}
	// 		});
	// 	}
	// };
	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const handlePreview = async (val: any) => {
		setContent(editorRef?.current?.getContent());
		setIsModalOpen(true);
	};
	return (
		<div className="p-8 trip-detail">
			<Form name="myForm" layout="vertical" form={formCreate} onFinish={onSubmitForm}>
				<Modal title="Xem trước" width={800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
					<div
						className="text-justify text-18 font-medium"
						dangerouslySetInnerHTML={{
							__html: content
						}}
					/>
				</Modal>
				<Card className="p-0 rounded-md shadow-sm border border-neutral-300 ">
					<>
						<div className={`m-4`}>
							<Row gutter={[24, 8]}>
								<Col className={``} md={16} xs={24} sm={24}>
									<Title level={4}>Thông tin chung</Title>
									<p className="text-16 font-medium mt-5 mb-1">Mô tả</p>
									<Row gutter={[24, 8]}>
										<Col className={``} md={6} xs={24} sm={24}>
											<FormSelect
												name="post_type_id"
												label="Loại tin"
												dataSource={dataPostType}
												placeholder="Chọn loại tin"
												slOption={{
													name: "post_type_name",
													value: "id"
												}}
												showSearch
												required
												optionFilterProp="children"
											/>
										</Col>
										<Col className={``} md={18} xs={24} sm={24}>
											<FormInput
												// id="form-carGroup_name"
												name="thread"
												label="Tiêu đề"
												type="text"
												size="large"
												required
												placeholder="Nhập tiêu đề "
												onChange={(e) => {
													formCreate.setFieldsValue({
														slug: convertToSlug(e.target.value),
														meta_title: e.target.value
													});
													// setMetaslug(convertToSlug(e.target.value));
													// setMetaTitle(e.target.value);
												}}
												classItem="mb-0"
											/>
										</Col>
										{/* <Col className={``} md={18} xs={24} sm={24}>
										<FormInput
											// id="form-carGroup_name"
											name="slug"
											label="Slug"
											type="text"
											required
											size="large"
											placeholder="Nhập slug "
											classItem="mb-0"
										/>
									</Col> */}
									</Row>
									<div className="text-black text-opacity-90 text-sm font-normal mt-2 leading-snug">Nội dung</div>
									<Editor2
										refEditor={editorRef}
										textareaName="content"
										onInit={(evt: any, editor: any) => (editorRef.current = editor)}
										// toolbar={
										// 	'undo redo | formatselect | forecolor backcolor| ' +
										// 	'bold italic backcolor image link media | alignleft aligncenter alignright alignjustify |' +
										// 	'removeformat | help preview table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
										// }
									/>
								</Col>
								<Col className={``} md={8} xs={24} sm={24}>
									<Title level={4}>SEO</Title>
									<div className="flex justify-between items-center">
										<div className="w-[83px] text-black text-opacity-90 text-sm font-normal   leading-snug">
											Meta Title
										</div>
										<div className="w-[148px] text-right">
											<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
												Số ký tự đã dùng:
											</span>
											<span className="text-black text-opacity-90 text-xs font-normal   leading-snug">
												{" "}
												{metaTitle?.length}/70
											</span>
										</div>
									</div>
									<FormInput
										id="form-carGroup_name"
										name="meta_title"
										// label="Giờ làm việc"
										type="text"
										size="large"
										onChange={(value: any) => {
											setMetaTitle(value.target.value);
										}}
										placeholder="Nhập tiêu đề / Bỏ trống hệ thống tự tạo tiêu đề"
										classItem="mb-0"
									/>
									<div className="flex justify-between items-center mt-[20px]">
										<FormInput
											id="form-carGroup_name"
											name="slug"
											label="Slug"
											type="text"
											required
											size="large"
											placeholder="Nhập slug "
											classItem="mb-0"
											className="w-full"
										/>
									</div>
									<div className="flex justify-between items-center mt-[20px]">
										<div className=" text-black text-opacity-90 text-sm font-normal   leading-snug">
											Meta Descriptions
										</div>
										<div className="w-[148px] text-right">
											<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
												Số ký tự đã dùng:
											</span>
											<span className="text-black text-opacity-90 text-xs font-normal   leading-snug">
												{" "}
												{metaDes?.length}/320
											</span>
										</div>
									</div>
									<Form.Item name={"meta_desc"}>
										<Input.TextArea onChange={(e) => setMetaDes(e.target.value)} placeholder="Meta Descriptions" />
									</Form.Item>
									{/* <TextArea
									rows={6}
									name="meta_desc"
									placeholder="Meta Descriptions
								"
									onChange={(value: any) => {
										// setNotiContent(value.target.value);
									}}
									// value={notiContent}
								/> */}
									<div className="w-[133px] mt-[20px] text-black text-opacity-90 text-sm font-normal   leading-snug">
										Meta Keywords
									</div>
									<Form.Item name={"meta_keyword"}>
										<Input.TextArea placeholder="Meta Keywords" />
									</Form.Item>
									<Row gutter={[8, 8]}>
										<Col md={24} xs={24} sm={24}>
											<Form.Item name="thumbnail" label="Ảnh cover /Meta Image">
												<UpdaloadImage
													fileList={fileListCover}
													className=""
													setChangeFileList={setFileListCover}
													// uploadImg={uploadImg}
													uploadImg={(info: any) => uploadImgCover(info)}
													maxCount={1}
													width={"100%"}
												/>
											</Form.Item>
										</Col>
										<Col md={24} xs={24} sm={24}>
											<Form.Item
												name="public_at"
												// rules={[{ required: true, message: "Không được bỏ trống!" }]}
												label="Hẹn giờ lên bài"
											>
												<DatePicker
													showTime
													disabledDate={(current) => current && current <= moment()}
													placeholder="Hẹn giờ lên bài"
													// format={dateFormatDMY}
													onChange={(val) => {}}
												/>
											</Form.Item>
										</Col>
										{/* <Col md={24} xs={24} sm={24}>
										<Form.Item name="meta_image" label="Meta Image">
											<UpdaloadImage
												fileList={fileListMeta}
												className=""
												setChangeFileList={setFileListMeta}
												// uploadImg={uploadImg}
												uploadImg={(info: any) => uploadImgMeta(info)}
												maxCount={1}
												width={"100%"}
											/>
										</Form.Item>
									</Col> */}
									</Row>
								</Col>
							</Row>
						</div>
					</>
					<Affix offsetBottom={2}>
						<div className="bg-[#404040] h-[60px] flex justify-end items-center w-full shadow-2xl border border-[#404040]">
							<Button
								size="large"
								// block={true}
								type="link"
								className="mr-[20px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold"
								onClick={() => {
									handlePreview(formCreate.getFieldsValue());
								}}
							>
								<p className="text-[15px]">Xem trước</p>
							</Button>
							<Button
								size="large"
								onClick={() => {
									onSubmitFormDraf(formCreate.getFieldsValue());
								}}
								// form="myForm"
								style={{
									border: "1px solid #FFFFB8"
								}}
								className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
							>
								<p className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold">Lưu nháp</p>
							</Button>
							<Button
								size="large"
								// htmlType="submit"
								onClick={() => {
									onSubmitForm(formCreate.getFieldsValue());
									// searchVehilce();
								}}
								// form="myForm"
								className="mr-[20px] bg-[#FADB14] hover:bg-[#FADB14] text-[#404040] font-bold"
							>
								<p className="text-[15px] text-[#404040] hover:text-[#404040]">Tạo mới</p>
							</Button>
							{/* <Button
							size="middle"
							block={true}
							type="link"
							form="myForm"
							className="mx-[20px]"
							onClick={() => {
								onSubmitForm(formCreate.getFieldsValue());
								// searchVehilce();
							}}
							style={{
								backgroundColor: "yellow",
								height: "40px",
								width: "100px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>Tạo mới</span>
						</Button> */}
						</div>
					</Affix>
				</Card>
			</Form>
		</div>
	);
};

export default CreateNew;
