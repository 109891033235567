import React from "react";

function SvgIcon00061(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" fill="none" viewBox="0 0 17 18" {...props}>
			<path
				fill="#000"
				d="M13.66 3.667l-7.524 7.405-2.79-2.744a1.577 1.577 0 00-2.208 0 1.517 1.517 0 000 2.173l3.893 3.832c.306.3.705.448 1.105.448.399 0 .798-.148 1.104-.449l8.621-8.491a1.517 1.517 0 000-2.174 1.58 1.58 0 00-2.202 0z"
			></path>
		</svg>
	);
}

export default SvgIcon00061;
