import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useEffect, useState } from "react";
import { notifyError } from "../notification";

const UploadFile = ({ accept, ...props }) => {
	const maxSizeFile = 1;
	const [files, setFiles] = useState([]);
	const [check, setCheck] = useState(false);
	const handleBeforeUpload = (file) => {
		if (accept.length > 0 && !accept.includes(file.type)) {
			notifyError("Chỉ chấp nhận Ảnh(.png, .jpg, .jpeg) hoặc PDF(.pdf)");
		}else if (!(file.size / 1024 / 1024 <= maxSizeFile)) {
			notifyError(`Dung lượng file phải nhỏ hơn ${maxSizeFile}MB`);
		}
		return false;
	};
	
	useEffect(() => {
		if (!check) {
			setFiles(props.defaultFileList);
		}
	}, [props.defaultFileList]);
	const handleChange = async ({ file, fileList }) => {
		setCheck(true);
		const deleteFile = file.isInit && file.status === "removed" ? file.object_type : "";
		const _files = fileList.map((e) =>
			e?.isInit
				? e
				: accept.includes(e.type)
				? { ...e, status: "done" }
				: { ...e, status: "error", response: "Lỗi Upload File" }
		);
		setFiles(_files);
		props.setChangeFileList(
			_files
				.filter((e) => e.status === "done")
				.map((item) => {
					return item.originFileObj ? item.originFileObj : item;
				}),
			deleteFile
		);
	};

	return (
		<>
			<Upload
				className={props.className}
				id={props.id ? props.id : "upload_id"}
				beforeUpload={handleBeforeUpload}
				onChange={handleChange}
				fileList={files}
				multiple={props.multiple || false}
			>
				<Button
					style={{ border: "1px solid #808080" }}
					className="text-black focus:text-black hover:text-gray-500"
					icon={<UploadOutlined />}
				>
				{props.name || 'Upload'}
				</Button>
			</Upload>
		</>
	);
};

export default UploadFile;
