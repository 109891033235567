import { Button, Col, Form, Modal, Row, UploadFile, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import { TYPE_YEP_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

import { dateFormatDMY } from "src/utils/helpers/functions/date";
import moment from "moment";
import { format } from 'date-fns';

// Hàm formatDate sử dụng date-fns để định dạng ngày
const formatDate = (dateString: string): string => {
	const date = new Date(dateString);
	return format(date, 'dd/MM/yyyy');
  };

const UserModal = ({ visibleExten = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [dataPage, setDataPage] = useState<any>([]);


	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
	}, []); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getRecruimentNewsDetails(detail.id);
				form.setFieldsValue({
					...res?.data,
					// work_location: res?.data?.work_location !== "" ? JSON?.parse(res?.data?.work_location) : [],
					expired_at: moment(res?.data?.expired_at),
					public_at: formatDate(res?.data?.public_at) // Định dạng ngày public_at
					// salary_type: res?.data?.salary_type?.toString()
				});
				console.log("res", res)
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		// if (isCreate) {
		// 	form.resetFields();
		// } else {
			init();
		// }
	}, []); //eslint-disable-line

	const onSubmit = async (val: any) => {
		setLoading(true);
		// let date = val?.date;

		// delete val.date;
		const params = {
			...val,
			public_at: moment(val?.public_at).format("YYYY-MM-DD"),
			expired_at: moment(val?.expired_at).format("YYYY-MM-DD"),
			// is_check: "0",
			// org_name: stateGetOrgs?.data?.find((e: any) => e.org_code === val.org_code)?.org_name
		};
		// try {
		// 	const res: any = isCreate
		// 		? await _apiNotSaga.createEmailField(params)
		// 		: await _apiNotSaga.updateEmailField(detail.id, params);
		// 	setLoading(false);
		// 	notifySuccess(res.message);
		// 	onSuccess();
		// } catch (error: any) {
		// 	setLoading(false);
		// 	notifyError(error.message);
		// }
		try {
			setLoading(true);

			const res: any = await _apiNotSaga.updateRecruimentNews(detail.id, params);
			// setIsLoad(!isLoad);
			setLoading(false);
			onSuccess();
			notifySuccess(res.message);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};


	return (
		<>
			<Modal
				open={visibleExten}
				onCancel={onCancel}
				// maskClosable={false}
				title={` Gia hạn thời gian đăng tuyển dụng`}
				style={{
					maxWidth: "80vw"
				}}
				width={"60%"}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div> */}
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					{/* <Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError> */}
					<Form name="myForm" layout="vertical" form={form} onFinish={onSubmit}>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[8, 8]}>
							<Col md={12} xs={24} sm={24}>
							<FormInput
									name="public_at"
									label="Ngày công bố"
									type="text"
									size="large"
									// placeholder="Nhập key"
									// required
									disabled
								/>
							</Col>
							<Col md={12} xs={24} sm={24}>
								<Form.Item
									name="expired_at"
									rules={[{ required: true, message: "Không được bỏ trống!" }]}
									label="Ngày hết hạn"
								>
									<DatePicker
										disabledDate={(current) => current && current <= moment().startOf("day")}
										placeholder="Ngày hết hạn"
										format={dateFormatDMY}
										onChange={(val) => {
											// const { routing_road_id, start_time_at } = createForm.getFieldsValue([
											// 	"routing_road_id",
											// 	"start_time_at"
											// ]);
											// loadRoutingRoadPlaces(routing_road_id, val, start_time_at);
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
