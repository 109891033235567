import { ArrowLeftOutlined } from "@ant-design/icons";
import {
	Affix,
	Avatar,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Progress,
	Rate,
	Row,
	Spin,
	Steps,
	Tabs,
	Tag,
	Typography
} from "antd";
import { Calendar, Coffee, Globe, Heart, Mail, MapPin, Phone } from "lucide-react";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import TableStyled from "src/components/table/TableStyled";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsDataDetail } from "./data";
// import { columnsDataExport, columnsDataRow, columnsTrackSration } from "../list/data";
// import "./styles.scss";
const { Title } = Typography;

const ListProfileDetailsTmp = () => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const isMount = useIsMount();
	const [numPages, setNumPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState<number>(1);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}
	const [formRating] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const datas = [
		{
			title: "Học vấn – Kinh nghiệm",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 1,
							title: "Học vấn / Kiến thức chuyên môn"
						},
						{
							id: 2,
							title: "Kinh nghiệm làm việc - cùng ngành"
						},
						{
							id: 1,
							title: `Thâm niên, kỹ năng phù hợp 
							(Theo Mô tả CV`
						}
					]
				}
			]
		},
		{
			title: "Năng lực Hành vi – Giá trị cốt lõi",
			data: [
				{
					title: "1. Trách nhiệm",
					datachild: [
						{
							id: 1,
							title: `Ý thức trách nhiệm với công việc, 
							tinh thần hợp tác.  Làm việc hiệu quả 
							với thành viên khác để hoàn thành 
							mục tiêu`
						},
						{
							id: 2,
							title: `Ứng viên có khả năng giải quyết vấn 
							đề, sự cố trong công việc. Có thể làm
							được việc “không thể thành có thể”, 
							tạo sự đột phá (có những trải nghiệm
							thực tế trong quá khứ).`
						}
					]
				}
			]
		}
	];
	const history = [
		{
			mnv: "5001043",
			name: "Lê Bá Khiêm",
			chucvu: "Nhân viên",
			time: "12/12/2021",
			noidung: "Đã tiếp nhận"
		},
		{
			mnv: "5001043",
			name: "Lê Bá Khiêm",
			chucvu: "Nhân viên",
			time: "12/12/2021",
			noidung: "Đã tiếp nhận"
		},
		{
			mnv: "5001043",
			name: "Lê Bá Khiêm",
			chucvu: "Nhân viên",
			time: "12/12/2021",
			noidung: "Đã tiếp nhận"
		}
	];
	const onSubmitForm = (val: any) => {};
	return (
		<div className="p-8 trip-detail">
			<Link to={`/trip-inner`} style={{ cursor: "pointer" }}>
				<div className="flex items-center">
					<ArrowLeftOutlined className="-mt-[1px]" />
					<span className="ml-1">Quay lại danh sách</span>
				</div>
			</Link>
			<Spin spinning={loading}>
				<Row gutter={[16, 16]} className="mt-8">
					<Col xs={24} md={8}>
						<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
							<div className="flex justify-start items-center mb-[20px]">
								<Avatar size={80}>USER</Avatar>
								<div className="flex flex-col justify-start items-start mx-4">
									<div className="text-black text-[16px] font-normal  ">Nguyễn Đình Long</div>
									<Rate disabled defaultValue={4} />
									<div className="flex">
										<Tag color="warning">Hồ sơ mới</Tag>
										<Tag color="success">Đạt</Tag>
									</div>
								</div>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center">
									<Mail size={'14'} className="mr-2" /> Email:
								</span>
								<span className="font-semibold ml-1">long.nguyendinh@gmail.com</span>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center"><Phone size={'14'} className="mr-2" /> Số điện thoại:</span>
								<span className="font-semibold ml-1">0819823345</span>
							</div>
							<div className="flex sm:block md:flex justify-between w-full">
								<span className="text-neutral-500 whitespace-nowrap items-center flex"><MapPin size={'14'} className="mr-2" /> Địa chỉ:</span>
								<span className="font-semibold ml-1 pl-[40px]">
									59/23/23 Liên khu 10-11, Phường Bình Trị Đông, Quận Tân Bình, TP. Hồ Chí Minh
								</span>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center"><Heart size={'14'} className="mr-2" /> Hôn nhân:</span>
								<span className="font-semibold ml-1">Kết hôn</span>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center" ><Calendar size={'14'} className="mr-2" />  Ngày sinh:</span>
								<span className="font-semibold ml-1">25/11/1994</span>
							</div>
							<div className=" text-black text-[18px] font-bold mt-2  "> Vị trí ứng tuyển</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center"> <Coffee size={'14'} className="mr-2" />Vị trí:</span>
								<span className="font-semibold ml-1">Tài xế</span>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center"><Calendar size={'14'} className="mr-2" /> Ngày nộp:</span>
								<span className="font-semibold ml-1">12/12/2023</span>
							</div>
							<div className="flex sm:block md:flex justify-between ">
								<span className="text-neutral-500 flex items-center"><Globe size={'14'} className="mr-2" /> Nguồn:</span>
								<span className="font-semibold ml-1">Website tuyển dụng</span>
							</div>
							<div className="w-full p-4 mt-2 bg-neutral-50 rounded-lg border border-zinc-100 flex flex-col">
								<div className="text-sky-500 text-[15px] font-normal   underline leading-snug">
									Chuyên viên Điều Phối (Củ Chi)
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">SL tuyển:</span>
									<span className="font-semibold ml-1">10</span>
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Cty:</span>
									<span className="font-semibold ml-1">Nhất Tín Logistics</span>
								</div>
								<Progress percent={30} size="small" />
							</div>
							<div className=" text-black text-[18px] font-bold mt-2  ">Quy trình ứng tuyển</div>
							<Steps
								direction="vertical"
								current={1}
								size="small"
								items={[
									{
										title: "Đã tiếp nhận"
									},
									{
										title: "Đang xét duyệt"
									},
									{
										title: "Gọi phỏng vấn"
									},
									{
										title: "Phỏng vấn trực tiếp đợt 1"
									},
									{
										title: "Phỏng vấn trực tiếp đợt 2"
									}
								]}
							/>
						</Card>
					</Col>
					<Col xs={24} md={16}>
						<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
							<Tabs
								className="activate-tab"
								defaultActiveKey="1"
								// onChange={onChange}
								items={[
									{
										label: `CV`,
										key: "1",
										children: (
											<div className="h-[600px]">
												<object
													data="https://cdn.ntlogistics.vn/uploads/vieclam/2023/12/11/cv/978/1702271186_doan_thi_thanh_trang_cv.pdf"
													type="application/pdf"
													width="100%"
													height="100%"
												>
													<p>
														Alternative text - include a link{" "}
														<a href="https://cdn.ntlogistics.vn/uploads/vieclam/2023/12/11/cv/978/1702271186_doan_thi_thanh_trang_cv.pdf">
															to the PDF!
														</a>
													</p>
												</object>
											</div>
										)
									},

									{
										label: `Lịch sử`,
										key: "4",
										children: (
											<>
												<TableStyled
													rowKey="id"
													dataSource={history}
													bordered
													isRowLight={true}
													columns={columnsDataDetail() as any}
												/>
											</>
										)
									}
								]}
							/>
						</Card>
					</Col>
				</Row>
				<Affix offsetBottom={2}>
					<div className="bg-white h-[60px] flex justify-end items-center w-full shadow-2xl border border-black">
						<Button
							size="middle"
							block={true}
							type="link"
							className="mx-[20px]"
							onClick={() => {
								// searchVehilce();
							}}
							style={{
								backgroundColor: "#fff",
								height: "40px",
								width: "100px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>Gán tin</span>
						</Button>
						<Button
							size="middle"
							block={true}
							type="link"
							className="mx-[20px]"
							onClick={() => {
								// searchVehilce();
							}}
							style={{
								backgroundColor: "#fff",
								height: "40px",
								width: "200px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
								Cập nhật thông tin cá nhân
							</span>
						</Button>

						<Button
							size="middle"
							block={true}
							type="link"
							className="mx-[20px]"
							onClick={() => {
								// searchVehilce();
							}}
							style={{
								backgroundColor: "#fff",
								height: "40px",
								width: "110px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>Upload CV</span>
						</Button>
						<Button
							size="middle"
							block={true}
							type="link"
							className="mx-[20px]"
							onClick={() => {
								// searchVehilce();
							}}
							style={{
								backgroundColor: "black",
								height: "40px",
								width: "100px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "white" }}>Lưu</span>
						</Button>
					</div>
				</Affix>
			</Spin>
		</div>
	);
};

export default ListProfileDetailsTmp;
