/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Layout } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidenav from "./Sidenav";
const { Header: AntHeader, Content, Sider } = Layout;

function Main(props: { children: any }) {
	const { children } = props;
	const [openNav, setOpenNav] = useState(true);
	const [collapsed, setCollapsed] = useState(false);

	let { pathname } = useLocation();
	pathname = pathname.replace("/", "");

	useEffect(() => {
		if (window.innerWidth < 1024) {
			setOpenNav(false);
		}
		const handleResize = () => {
			if (window.innerWidth < 1024) {
				setOpenNav(false);
			}
			else {
				setOpenNav(true);
			}
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	/****************************START**************************/
	/*                         Life Cycle                      */

	/**************************** END **************************/

	/****************************START**************************/
	/*                          Function                       */

	/**************************** END **************************/

	/****************************START**************************/
	/*                         Component                       */

	const _renderBody = () => {
		return (
			<Layout>
				<div className="mainBody">{children}</div>
				<Footer />
			</Layout>
		);
	};

	const _renderSideMenu = () => {
		return (
			// <Sider
			// 	collapsible
			// 	collapsed={openNav}
			// 	breakpoint="lg"
			// 	collapsedWidth="0"
			// 	trigger={null}
			// 	width={245}
			// 	theme="light"
			// 	id="siderStyled"
			// 	// className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""} ${
			// 	// 	openNav ? "openNav" : "closeNav"
			// 	// }`}
			// 	style={{ background: sidenavType, padding: 0 }}
			// >
			// 	<Sidenav isOpenMenu={openNav}/>
			// </Sider>

			<Sider 
				trigger={null} 
				collapsible 
				width={245} 
				collapsedWidth={40}
				collapsed={!openNav}
			>
				<Sidenav isOpenMenu={openNav}/>
			</Sider>
		);
	}

	/**************************** END **************************/

	/****************************START**************************/
	/*                      Return Component                   */

	const _renderHeader = () => {
		return <Header onOpenMenu={setOpenNav} isOpenMenu={openNav} />;
	  };

	return (
		<Layout
			className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""} ${
				pathname === "rtl" ? "layout-dashboard-rtl" : ""
			}`}
		>
			{_renderHeader()}
			{_renderSideMenu()}
			{_renderBody()}
		</Layout>
	);

	/**************************** END **************************/
}

export default Main;
