import * as React from "react";

const SvgIcon00139 = (props) => (
	<svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_12108_16098)">
			<path
				d="M24.4806 18.7476L15.6493 2.51337C14.2304 0.124652 10.7715 0.121478 9.35073 2.51337L0.519867 18.7476C-0.930621 21.1884 0.825483 24.2793 3.6684 24.2793H21.3313C24.1718 24.2793 25.931 21.1909 24.4806 18.7476ZM12.5 21.3497C11.6925 21.3497 11.0352 20.6923 11.0352 19.8848C11.0352 19.0773 11.6925 18.42 12.5 18.42C13.3075 18.42 13.9648 19.0773 13.9648 19.8848C13.9648 20.6923 13.3075 21.3497 12.5 21.3497ZM13.9648 15.4903C13.9648 16.2978 13.3075 16.9551 12.5 16.9551C11.6925 16.9551 11.0352 16.2978 11.0352 15.4903V8.16606C11.0352 7.35854 11.6925 6.70121 12.5 6.70121C13.3075 6.70121 13.9648 7.35854 13.9648 8.16606V15.4903Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_12108_16098">
				<rect width="25" height="25" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgIcon00139;
