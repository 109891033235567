/*eslint-disable*/
/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "antd/dist/antd.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";
import Main from "./components/layout/Main";
// import 'draft-js/dist/Draft.css';
// import Dashboard from "./pages/dashboard/Dashboard";
import { useEffect } from "react";
import { routerNotAuth } from "./constants";
import NotFound from "./pages/notFound/notFound";
import Roles from "./pages/roles/list";
import SignIn from "./pages/signIn";
// import SumaryWaitingBook2 from "./pages/sumary-waiting-book/index_2";
import Users from "./pages/users/list";
import { putSignIn } from "./services/actions/global.actions";
import { useAuth } from "./services/authorRouter";
import routerNames from "./utils/data/routerName";
import { useIsMount } from "./utils/helpers/functions/useIsMount";
import { localGetAccount, localGetToken } from "./utils/localStorage";
// import ReportProductivity from "./pages/report_productivity/list";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./app.css";
import "./index.css";
import CreateNew from "./pages/News";
import ListNew from "./pages/News/ListNew";
import DetailsNews from "./pages/News/detailsNews";
import Banner from "./pages/banner";
import CategoryJob from "./pages/categoryJob";
import CreatePost from "./pages/createPost";
import DetailsCreatePost from "./pages/createPost/detailsCreatePost";
import ListEmail from "./pages/email";
import CreateEmail from "./pages/email/CreateEmail";
import DetailsEmail from "./pages/email/DetailsEmail";
import EmailField from "./pages/email/emailField";
import ListPost from "./pages/listPost";
import ListProfile from "./pages/listProfile";
import ListProfileDetails from "./pages/listProfile/Details";
import ListProfileTemp from "./pages/listProfileTemp";
import ListProfileDetailsTmp from "./pages/listProfileTemp/Details";
import ModuleFunctionsEdit from "./pages/module-fuct/edit";
import ModuleFuct from "./pages/module-fuct/list";
import RoleDetail from "./pages/roles/list/RoleDetail";
import TagetJob from "./pages/tagetJob";
import YEP from "./pages/yep";
import ApproveList from "./pages/listProfile/ApproveList";
import ApproveDetails from "./pages/listProfile/ApproveDetails";
import XetDuyetLuong from "./pages/listProfile/XetDuyetLuong";
import CCTV from "./pages/cctv";
import Apps from "./pages/apps";
import Customer from "./pages/Customer/list";
import ListBill from "./pages/ListBill";
import InWareHouse from "./pages/InWarehouse";
import CreateBill from "./pages/CreateBill";
import WHOperation from "./pages/DetailedWhOperation";
import WarehouseToWarehouse from "./pages/WarehouseToWarehouse";
import WarehouseToCustomer from "./pages/WarehouseToCustomer";
import CustomerToWarehouse from "./pages/CustomerToWarehouse";
import Tracuuvandon from "./pages/Tracuuvandon";
import DetailedBill from "./pages/DetailedBill";

function App() {
	const auth = useAuth();
	const dispatch = useDispatch();
	const isMount = useIsMount();
	let history = useHistory();
	// const { stateSignIn, phone, email } = useSelector((state: AppState) => state.accountReducer);
	const { pathname } = useLocation();
	/****************************START**************************/
	/*                         Life Cycle                      */

	useEffect(() => {
		function checkAuthor() {
			let token = localGetToken();
			let account = localGetAccount();
			const _rootPathname = pathname.split("/");
			if (token && account) {
				dispatch(putSignIn(true));
				auth.signIn(token, account, () => {
					if (pathname === "/" || pathname === routerNames.SIGN_IN) history.push(routerNames.DASHBOARD);
					// else history.push(pathname);
				});
			} else if (_rootPathname[1] && routerNotAuth.includes(`/${_rootPathname[1]}`)) {
				history.push(pathname);
			} else {
				history.push({
					pathname: routerNames.SIGN_IN
				});
			}
		}
		checkAuthor(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		// const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
		// const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
		// resizeObserverErrDiv.setAttribute("style", "display: none");
		window.addEventListener("error", (e) => {
			if (
				[
					"ResizeObserver loop limit exceeded",
					"ResizeObserver loop completed with undelivered notifications."
				].includes(e.message)
			) {
				const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
				const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		});
	}, []);

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success } = stateSignIn;
	// 	if (success) {
	// 		dispatch(putSignIn(true));
	// 	}
	// }, [stateSignIn.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
	// useEffect(() => {
	// 	let _formLogin = localGetFormLogin();
	// 	if (!phone && !email && _formLogin) {
	// 		// let _dataUser = JSON.parse(_formLogin);
	// 		// signInForm.setFieldsValue({
	// 		//   username: _dataUser.username,
	// 		//   password: _dataUser.password
	// 		// });
	// 	}
	// }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	const getPaymentMethods = async () => {
	// 		let token = localGetToken();
	// 		let uuid = localGetAuthUUID();
	// 		if (token && uuid) {
	// 			try {
	// 				await _apiNotSaga.getCurrentUser();
	// 			} catch (error: any) {
	// 				if (error?.statusCode === 408 || error?.statusCode === 409) {
	// 					localStorage.clear();
	// 					history.push({
	// 						pathname: routerNames.SIGN_IN
	// 					});
	// 				}
	// 			}
	// 		} else {
	// 			history.push({
	// 				pathname: routerNames.SIGN_IN
	// 			});
	// 		}
	// 	};
	// 	if (history.location.pathname !== routerNames.SIGN_IN) {
	// 		getPaymentMethods();
	// 	}
	// }, [history.location.pathname]);
	return (
		// <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}>
		<div className="App">
			<Route path={routerNames.SIGN_IN} exact component={SignIn} />
			{pathname !== "/sign-in" && (
				<Main>
					<Switch>
						{/* <Route exact path={routerNames.DASHBOARD} component={Dashboard} /> */}
						<Route exact path={routerNames.LISTBILL} component={ListBill} />
						<Route exact path={routerNames.CREATE_BILL} component={CreateBill} />
						<Route exact path={routerNames.LIST_POST} component={ListPost} />
						<Route exact path={routerNames.LIST_PROFILE} component={ListProfile} />

						<Route exact path={`${routerNames.LIST_PROFILE}/:id`} component={ListProfileDetails} />
						<Route exact path={`${routerNames.APPROVEDETAILS}/:id`} component={ApproveDetails} />

						<Route exact path={`${routerNames.LIST_PROFILE_TEMP}`} component={ListProfileTemp} />
						<Route exact path={`${routerNames.LIST_PROFILE_TEMP}/:id`} component={ListProfileDetailsTmp} />
						<Route exact path={`${routerNames.CREATE_NEW}`} component={CreateNew} />
						<Route exact path={`${routerNames.LIST_NEW}`} component={ListNew} />
						<Route exact path={`${routerNames.USERS}`} component={Users} />

						<Route exact path={`${routerNames.CATEGORY_JOB}`} component={CategoryJob} />
						<Route exact path={`${routerNames.LIST_EMAIL}`} component={ListEmail} />
						<Route exact path={`${routerNames.LIST_EMAIL}/details/:id`} component={DetailsEmail} />
						<Route exact path={`${routerNames.TARGET_JOB}`} component={TagetJob} />
						<Route exact path={`${routerNames.BANNER_LIST}`} component={Banner} />
						<Route exact path={`${routerNames.ROLES}`} component={Roles} />
						<Route exact path={`${routerNames.MOUDULE_FUNCT}`} component={ModuleFuct} />
						<Route exact path={`${routerNames.MOUDULE_FUNCT}/:id`} component={ModuleFunctionsEdit} />
						<Route exact path={`${routerNames.ROLES}/:id`} component={RoleDetail} />
						<Route exact path={`${routerNames.LIST_NEW}/:id`} component={DetailsNews} />

						<Route exact path={`${routerNames.EMAIL_FIELD}`} component={EmailField} />
						<Route exact path={`${routerNames.YEP}`} component={YEP} />
						<Route exact path={`${routerNames.LIST_EMAIL}/create`} component={CreateEmail} />
						<Route exact path={`${routerNames.CREATEPOST}/:id`} component={DetailsCreatePost} />
						<Route exact path={`${routerNames.APPROVELIST}`} component={ApproveList} />
						<Route exact path={`${routerNames.XETDUYETLUONG}`} component={XetDuyetLuong} />
						<Route exact path={`${routerNames.CCTV}`} component={CCTV} />
						<Route exact path={`${routerNames.APPS}`} component={Apps} />
						<Route exact path={`${routerNames.CUSTOMER}`} component={Customer} />
						<Route exact path={`${routerNames.INWAREHOUSE}`} component={InWareHouse} />
						<Route exact path={`${routerNames.WAREHOUSE_TO_CUSTOMER}`} component={WarehouseToCustomer} />
						<Route exact path={`${routerNames.WAREHOUSE_TO_WAREHOUSE}`} component={WarehouseToWarehouse} />
						<Route exact path={`${routerNames.CUSTOMER_TO_WAREHOUSE}`} component={CustomerToWarehouse} />
						<Route exact path={`${routerNames.WH_OPERATION}/:id`} component={WHOperation} />
						<Route exact path={`${routerNames.TCVD}`} component={Tracuuvandon} />
						<Route exact path={`${routerNames.DETAILED_BILL}/:bill_code`} component={DetailedBill} />
						
						{/* <Route exact path={`${routerNames.VEHICLE_MANAGEMENT_EDIT}/:id`} component={VehicleEdit} />
						<Route exact path={routerNames.VEHICLE} component={vehicle} />
						<Route exact path={routerNames.DRIVER} component={driver} />
						<Route exact path={`${routerNames.DRIVER}/:id`} component={DriverEdit} />
						<Route exact path={`${routerNames.VEHICLE_GROUP}`} component={FleetConfiguration} />
						<Route exact path={`${routerNames.VEHICLE_TYPE}`} component={VehicleType} />
						<Route exact path={`${routerNames.MAINTENANCELIST}`} component={MaintenanceList} />
						<Route exact path={`${routerNames.LISTOFSUPPLIES}`} component={ListOfSupplies} />
						<Route exact path={`${routerNames.REPAIRREGULATION}`} component={RepairRegulation} />
						<Route exact path={`${routerNames.MAINTENANCE}`} component={maintenance} />
						<Route exact path={`${routerNames.DASHBOARD_TRIPS}`} component={dashboardTrips} />
						<Route exact path={`${routerNames.WAREHOUSE_INVENTORY}`} component={WarehouseInventory} />
						<Route exact path={`${routerNames.TRIPS_MANAGEMENT}`} component={tripManagement} />
						<Route exact path={`${routerNames.TRIPS_MANAGEMENT}/:id`} component={tripDetail} />
						<Route exact path={`${routerNames.WAITING_BOOK_MANAGEMENT}`} component={loadReportManager} />
						<Route exact path={`${routerNames.LIST_OF_EXPENSES}`} component={listsOfExpenses} />
						<Route exact path={`${routerNames.WAITING_BOOK_INCURRED}`} component={waitingBookIncurred} />
						<Route exact path={`${routerNames.SUMARY_WAITING_BOOK}`} component={SumaryWaitingBook} />
						<Route exact path={`${routerNames.ROUTING_GROUPS}`} component={RoutingGroups} />
						<Route exact path={`${routerNames.ROUTES}`} component={Routes} />
						<Route exact path={`${routerNames.BILL_JOURNEY}/:id`} component={BillJourney} />
						<Route exact path={`${routerNames.BILL_JOURNEY}`} component={BillJourney} />
						<Route exact path={`${routerNames.WAREHOUSES}`} component={Places} />
						<Route exact path={`${routerNames.ROAD_COST_MANAGEMENT}`} component={RoadCostMangement} />
						<Route exact path={`${routerNames.PRICE_OFF_TYPE}`} component={PriceOfType} />
						<Route exact path={`${routerNames.ROAD_COST_STATION}`} component={RoadCostStation} />
						<Route exact path={`${routerNames.PRICE_OFF_CATEGOTY}`} component={PriceOffCategory} />
						<Route exact path={`${routerNames.VEHICLE_DIARY}`} component={VehicleDiary} />
						<Route exact path={`${routerNames.MAINTENANCE}/:id`} component={maintenanceDetail} />
						<Route exact path={`${routerNames.ROLES}`} component={Roles} />
						<Route exact path={`${routerNames.MOUDULE_FUNCT}`} component={ModuleFuct} />
						<Route exact path={`${routerNames.MOUDULE_FUNCT}/:id`} component={ModuleFuctEdit} />
						<Route exact path={`${routerNames.ROLES}/:id`} component={RoleDetail} />
						<Route exact path={`${routerNames.USERS}`} component={Users} />
						<Route exact path={`${routerNames.REPAIR_PARTNER}`} component={RepairPartner} />
						<Route exact path={`${routerNames.TRANSPORTAUTOCONFIG}`} component={AutoCreateTrip} />
						<Route exact path={`${routerNames.MONITOR}`} component={Monitor} />

						<Route exact path={`${routerNames.REPORTIDENTIFIER}`} component={ReportIdentifier} />
						<Route exact path={`${routerNames.REPORTONTIME}`} component={ReportOntime} />
						<Route exact path={`${routerNames.REPORT_ONTIME_IN_CITY}`} component={ReportOntime} />
						<Route exact path={`${routerNames.REPORTPROBLEM}`} component={ReportProblems} />
						<Route exact path={`${routerNames.REPORTWAREHOURECONTROL}`} component={ReportWarehoureControls} />
						<Route exact path={`${routerNames.REPORTEVALLUATION}`} component={ReportEvaluation} />
						<Route exact path={`${routerNames.REPORTEVALLUATIONDRIVER}`} component={ReportEvaluationDriver} />
						<Route exact path={`${routerNames.REPORTPERFORMANCE}`} component={ReportPerformance} />
						<Route exact path={`${routerNames.REPORTPRODUCTIVITY}`} component={ReportProductivity} />
						<Route exact path={`${routerNames.REPORTPRODUCTIVITY}/:id`} component={ReportProductivityDetail} />
						<Route exact path={`${routerNames.CONFIGKPI}`} component={ConfigKpi} />
						<Route exact path={`${routerNames.CONFIG_OILFUEL}`} component={ConfigOilfuel} />
						<Route exact path={`${routerNames.CONFIG_VEHICLE_GROUP_WEIGHT}`} component={VehicleGroupWeightConfig} />

						<Route exact path={`${routerNames.TRIPS_INNER}`} component={TripInner} />
						<Route exact path={`${routerNames.TRIPS_INNER}/:id`} component={TripInnerDetail} />

						<Route exact path={`${routerNames.DASHBOARD_WARN}`} component={DashboardWarn} />
						<Route exact path={`${routerNames.LIST_EXPORT_WAREHOUSE}`} component={ListExportWarehouse} /> */}

						<Route component={NotFound} />
					</Switch>
				</Main>
			)}
		</div>
		// </GoogleOAuthProvider>
	);
}

export default App;
