import routerNames from "src/utils/data/routerName";
import { CustomerTypeEnum, ProductLevelEnum, UserGenderEnum } from "./enum";
import moment from "moment";
import colors from "src/utils/colors";
export const REPASSWORD = "rePassword";
export const PHONE = "phone";
export const CCCD = "cccd";
export const TEXT = "text";
export const PASSWORD = "password";
export const EMAIL = "email";
export const URL = "url";
export const EMPTY = "empty";
export const NEW_PASSWORD = "new-password";
export const PHONEANDEMAIL = "phoneAndEmail";
export const JUST_NUMBER = "justNumber";
export const NUMBER_AND_TEXT = "numberAndText";
export const INCLUDE_SOME_SPECIAL_CHAR = "includeSomeSpecialChar";
export const NUMBER = "number";
export const OFF = "off";
export const screenSignIn = 445;
export const MINUTES_OF_DAY = 24 * 60;
export const DEFAULT_LOCATION = {
	center: {
		lat: 15.8,
		lng: 106.65
	},
	zoom: 5
};
export const EXPORT_TEMPLATE_TYPE = {
	TEMPLATE_BOOKING: "BOOKING" // template báo tải
};
export const typeScreenManagerSignIn = {
	SIGN_IN: 1,
	REGISTER: 2,
	FORGET_PASSWORD: 3,
	CREATE_ACCOUNT_SUCCESS: 4,
	ACTIVE_ACCOUNT_SUCCESS: 5,
	OTP: 6,
	CHANGE_PASSWORD: 7,
	CHANGE_PASSWORD_SUCCESS: 8
};
export const SUPPLIES_UNIT = [
	{ id: "Cái", name: "Cái" },
	{ id: "Chiếc", name: "Chiếc" }
];
export const STATUS_BASE = [
	{ id: "A", name: "Hoạt động" },
	{ id: "D", name: "Ẩn" }
];
export const CHECKIN_BASE = [
	{ id: "1", name: "Đã checkin" },
	{ id: "0", name: "Chưa checkin" }
];
export const STATUS_NEWS = [
	{ id: "O", name: "Mới tạo" },
	{ id: "P", name: "Chờ duyệt" },
	{ id: "A", name: "Đã duyệt" },
	{ id: "H", name: "Ẩn" }
];

export const STATUS_ONTIME = [
	{ id: "I", name: "Chưa hoạt động", color: "#808A94" },
	{ id: "A", name: "Hoạt động", color: "#2F80ED" },
	{ id: "C", name: "Hoàn thành", color: "#3CAEA4" },
	{ id: "F", name: "Kết thúc chuyến", color: "#9B51E0" }
];
export const STATUS_BASE_COLOR: any = {
	A: { color: "#3CAEA4", name: "Hoạt động" },
	D: { color: "gray", name: "Ẩn" }
};
export const STATUS_WAITING_BOOK: any = {
	O: { color: colors.neutral_color_1_1, name: "Book mới" },
	I: { color: colors.accent_color_4_5, name: "Lên chuyến chưa hết" },
	C: { color: "#F2994A", name: "Đã lên chuyến" },
	default: {
		name: "Không tìm thấy trạng thái",
		color: colors.neutral_color_1_2
	}
};

export const MESSAGE_VALIDATE = {
	text: {
		type: "text",
		placeholder: "",
		message: "Không được bỏ trống!"
	},
	phone: {
		type: "number",
		placeholder: "Nhập số điện thoại",
		message: "Vui lòng nhập số điện thoại!",
		errorMess: "Số điện thoại chưa đúng định dạng!"
	},
	cccd: {
		type: "number",
		placeholder: "Nhập cmnd/cccd",
		message: "Vui lòng nhập số cmnd/cccd!",
		errorMess: "Cmnd/cccd chưa đúng định dạng!"
	},
	email: {
		placeholder: "Nhập email",
		message: "Vui lòng nhập email!",
		errorMess: "Email chưa đúng định dạng!"
	},
	phoneAndEmail: {
		// placeholder: "Nhập số điện thoại hoặc email",
		message: "Vui lòng nhập số điện thoại hoặc email!"
		// errorMess: "Email chưa đúng định dạng!",
	},
	password: {
		placeholder: "Nhập password",
		message: "Vui lòng nhập password!",
		atleast: "Mật khẩu tối thiểu tám ký tự",
		character: "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt"
	},
	url: {
		placeholder: "Nhập url",
		message: "Vui lòng nhập url!",
		errorMess: "URL chưa đúng định dạng"
	},
	empty: {
		placeholder: "",
		message: "Vui lòng nhập đầy đủ!"
	}
};

export const dataProduct_Type = [
	{ name: "Thường", value: "1" },
	{ name: "IMEI", value: "2" },
	{ name: "Combo", value: "3" },
	{ name: "Dịch vụ", value: "4" }
];

export const dataProductTypeDDV = [
	{ name: "Hàng công ty", value: "1" },
	{ name: "Hàng xách tay", value: "2" }
];

export const dataProductType = [
	{ name: "Sản phẩm cha", value: "1" },
	{ name: "Sản phẩm con", value: "2" },
	{ name: "Sản phẩm combo", value: "3" },
	{ name: "Sản phẩm độc lập", value: "4" }
];

export const dataSourceOrder = [
	{ name: "Zalo", value: "1" },
	{ name: "Youtube", value: "2" },
	{ name: "Order Online", value: "3" },
	{ name: "Hotline", value: "4" },
	{ name: "Zalo", value: "5" },
	{ name: "Facebook", value: "6" },
	{ name: "Instagram", value: "7" },
	{ name: "Website DDV", value: "8" },
	{ name: "E-COM", value: "9" },
	{ name: "Trực tiếp - Cửa hàng", value: "10" }
];

export const dataOrderStatus = [
	{ name: "Đơn được tạo nhưng chưa hoạt động", value: "0" },
	{ name: "Đơn đã hoạt động, chờ xác nhận", value: "1" },
	{ name: "Đã xác nhận", value: "2" },
	{ name: "Đang chuẩn bị", value: "3" },
	{ name: "Đang đóng gói", value: "4" },
	{ name: "Đang vận chuyển", value: "5" },
	{ name: "Không bắt máy", value: "6" },
	{ name: "Thành công", value: "7" },
	{ name: "Không thành công", value: "8" }
];

export const dataOrderType = [
	{ name: "Mua tại quầy", value: "1" },
	{ name: "Đặt trước", value: "2" },
	{ name: "Tự vận chuyển", value: "3" },
	{ name: "Hãng vận chuyển", value: "4" }
];

export const dataPaymentOrder = [
	{ name: "Tiền mặt", value: "1" },
	{ name: "Chuyển khoản ngân hàng", value: "2" },
	{ name: "Momo", value: "3" },
	{ name: "ZaloPay", value: "4" },
	{ name: "Shopee Pay", value: "5" }
];

export const dataProductStatus = [
	{ name: "Mới", value: "1" },
	{ name: "Đang bán", value: "2" },
	{ name: "Hết hàng", value: "3" },
	{ name: "Ngừng bán", value: "4" }
];

export const dataStatus = [
	{ name: "Hiển thị", value: "A" },
	{ name: "Ẩn", value: "D" }
];

export const dataOrg = [
	{ name: "TP Hồ Chí Minh", value: 59 },
	{ name: "Hà Nội", value: 29 }
];
export const dataStatusMaintenaces = [
	{ name: "Mới", value: "O" },
	{ name: "Đã tiếp nhận", value: "R" },
	{ name: "Hủy", value: "H" },
	{ name: "Từ chối", value: "E" },
	{ name: "Đã chốt", value: "C" }
];
export const MAINTENANCES: any = [
	{ name: "Chờ thanh toán", value: "N" },
	{ name: "Từ chối", value: "R" },
	{ name: "Đã thanh toán", value: "P" }
];
export const dataStatusActive = [
	{ name: "Chưa áp dụng", value: "1" },
	{ name: "Đang áp dụng", value: "2" },
	{ name: "Hết hạn", value: "3" },
	{ name: "Ngừng áp dụng", value: "4" }
];

export const dataStatusPaymentBill = [
	{ name: "Chưa thanh toán", value: "1" },
	{ name: "Thanh toán thành công", value: "2" },
	{ name: "Thanh toán thất bại", value: "3" }
];

export const dataStatus_Type = [
	{ name: "Demo", value: "1" },
	{ name: "Like new", value: "2" },
	{ name: "Đã sử dụng", value: "3" },
	{ name: "Test", value: "4" }
];

export const dataTypeCustomer = [
	{ name: "Khách lẻ", value: "1" },
	{ name: "Khách buôn", value: "2" },
	{ name: "Đại lý", value: "3" },
	{ name: "Khách vip", value: "4" },
	{ name: "Khách online", value: "5" }
];

export const dataTypeTabFlashSale = [
	{ name: "Không hiển thị tab", value: "0" },
	{ name: "Hiển thị tab", value: "1" }
];

export const dataGender = [
	{ name: "Nam", value: "1" },
	{ name: "Nữ", value: "0" }
];

export const selectOptionsId = {
	name: "name",
	value: "id"
};

export const selectOptionsValue = {
	name: "name",
	value: "value"
};

export const transports = [
	{
		label: "Nhất Tín Express",
		value: 1
	},
	{
		label: "Shopee Express",
		value: 2
	},
	{
		label: "Giao hàng nhanh",
		value: 3
	},
	{
		label: "Giao hàng tiết kiệm",
		value: 4
	},
	{
		label: "Nija Văn",
		value: 5
	}
];

export const paymentMethods = [
	{
		label: "Tiền mặt",
		value: 1
	},
	{
		label: "COD",
		value: 2
	},
	{
		label: "Quẹt thẻ",
		value: 3
	},
	{
		label: "Chuyển khoản",
		value: 4
	}
];
export const arrOptionButton = {
	create: {
		type: "button",
		id: "filter_btn_reset",
		// icon: IconCreate,
		classFilter: "w-auto h-[40px] rounded-lg px-8",
		nameEvent: "btnReset",
		name: "Đặt lại",
		col: 3
	}
};
export const DEFAULT_FILTER = {
	page: 1,
	limit: 20
};
export const YEP_FILTER = {
	pageNumber: 1,
	pageSize: 10
};
export const renameFile = (originalFile: any, newName: string) => {
	return new File([originalFile], newName, {
		type: originalFile?.type,
		lastModified: originalFile?.lastModified
	});
};
export const arrSelectDate = [
	{
		key: "now_days",
		title: "Hôm nay",
		value: 0,
		disabled: true,
		result: [moment().startOf("day"), moment().endOf("day")]
	},
	{
		key: "7_days",
		title: "7 Ngày trước",
		value: 7,
		disabled: true,
		result: [moment().subtract(7, "days").startOf("day"), moment().endOf("day")]
	},
	{
		key: "14_days",
		title: "14 ngày trước",
		value: 14,
		disabled: true,
		result: [moment().subtract(14, "days").startOf("day"), moment().endOf("day")]
	},
	{
		key: "30_days",
		title: "30 ngày trước",
		value: 30,
		disabled: true,
		result: [moment().subtract(30, "days").startOf("day"), moment().endOf("day")]
	},
	{
		key: "this_month",
		title: "Tháng này",
		value: 0,
		disabled: true,
		result: [moment().startOf("month"), moment().endOf("day")]
	},
	{
		key: "last_month",
		title: "Tháng trước",
		value: 1,
		disabled: true,
		result: [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
	},
	{
		key: "option",
		title: "Tùy chọn",
		value: 0,
		disabled: false,
		result: null
	}
];
export const arrOptionFilter = {
	search: {
		placeholder: "Nội dung tìm kiếm",
		type: "input",
		// suffix: IonSearchGrey,
		nameEvent: "q",
		id: "filter_input_search"
	},
	status: {
		type: "select",
		placeholder: "Trạng thái",
		id: "filter_select_status",
		nameEvent: "status"
		// data: sttType(10),
	},
	datePicker: {
		type: "rangePickerSelect",
		placeholder: "",
		id: "filter_select_date",
		nameEvent: "date"
		// data: sttType(10),
	}
};
export const FLEX_FILTER_ELEMENTS = {
	input: {
		type: "input",
		position: "left",
		width: 200,
		paramsName: "inputParams",
		placeholder: "Nhập nội dung tìm kiếm",
		label: "Nội dung cần tìm",
		initValue: undefined,
		isDisable: false
	},
	select: {
		type: "select",
		position: "left",
		width: 180,
		paramsName: "selectParams",
		placeholder: "Chọn giá trị",
		label: "Chọn giá trị",
		options: {
			data: [{}],
			keyOption: "",
			valueOption: ""
		},
		initValue: undefined,
		isDisable: false
	},
	btn: {
		type: "button",
		position: "left",
		width: 200,
		paramsName: "btnSingle",
		text: "Tìm kiếm",
		antIcon: true
	},
	btnSearch: {
		type: "btnSearch",
		position: "left",
		width: 120,
		paramsName: "btnSearch",
		text: "Tìm kiếm",
		antIcon: true
	},
	btnAction: {
		type: "btnAction",
		position: "left",
		width: 120,
		paramsName: "btnAction"
	},
	btnFilterMore: {
		type: "btnFilterMore",
		position: "left",
		width: 150,
		text: "Lọc thêm",
		paramsName: "btnFilterMore"
	},
	btnAdd: {
		type: "btnAdd",
		position: "left",
		width: 150,
		text: "Tạo mới",
		paramsName: "btnAdd"
	},
	btnPrint: {
		type: "btnPrint",
		position: "left",
		width: 150,
		text: "In đơn",
		paramsName: "btnPrint"
	},
	btnCloseBag: {
		type: "btnFilterMore",
		position: "left",
		width: 150,
		text: "Đóng kiện",
		paramsName: "btnFilterMore"
	},
	datpicker: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "datePicker",
		label: "Tìm theo thời hạn",
		hasSelectDate: true,
		selectDateWidth: 150,
		isDisable: false
	},
	paymentRange: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "paymentRange",
		label: "Ngày trả",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	repairRange: {
		type: "datePicker",
		position: "left",
		width: 300,
		paramsName: "repairRange",
		label: "Ngày gửi",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	createdRange: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "createdRange",
		label: "Ngày tạo",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	startRange: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "startRange",
		label: "Ngày tạo",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	endRange: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "endRange",
		label: "Ngày tạo",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	updatedRange: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "updatedRange",
		label: "Ngày cập nhật",
		hasSelectDate: false,
		selectDateWidth: 150,
		isDisable: false
	},
	datpickerShort: {
		type: "datePickerShort",
		position: "left",
		width: 400,
		paramsName: "datpickerShort",
		label: "Tìm theo thời hạn",
		hasSelectDate: true,
		selectDateWidth: 150,
		isDisable: false
	},
	datpicker_2: {
		type: "datePicker",
		position: "left",
		width: 400,
		paramsName: "datePicker",
		label: "Ngày báo",
		selectDateWidth: 150,
		isDisable: false
	},
	space: {
		type: "space",
		position: "left",
		width: 100,
		paramsName: "space"
	},
	custom: {
		type: "custom",
		label: "Custom",
		paramsName: "custom"
	}
};
export const defaultStyless = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "100%",
	marginTop: "36px",
	background: colors.neutral_color_1_8,
	height: "41px",
	border: `1px solid ${colors.neutral_color_1_5}`,
	borderRadius: "5px",
	padding: "0 12px"
};
export const TRIP_STATUS: any = {
	A: { color: "#2F80ED", name: "Hoạt động" },
	I: { color: "#808A94", name: "Chưa hoạt động" },
	C: { color: "#3CAEA4", name: "Đã hoàn thành" },
	H: { color: "#EB5757", name: "Đã hủy" },
	default: {
		name: "Không tìm thấy trạng thái",
		color: colors.neutral_color_1_2
	}
};
export const DEFAUT_STATUS: any = [
	{
		value: "A",
		name: "Hoạt động"
	},
	{
		value: "D",
		name: "Ẩn"
	}
];
export const TRIP_STATION_STATUS: any = {
	IC: { color: "#808A94", name: "Dự kiến" },
	C: { color: "#38C173", name: "Đã đến" },
	L: { color: "#2F80ED", name: "Đã đi" }
};
export const VEHICLE_DIARY_STT: any = {
	O: { color: "#001529", name: "Mới" },
	undefined: { color: "#001529", name: "Mới" },
	R: { color: "#2F80ED", name: "Đã xác nhận" },
	C: { color: "#3CAEA4", name: "Đã chốt" },
	H: { color: "#EB5757", name: "Hủy" },
	E: { color: "#EB5757", name: "Từ chối" }
};
export const VEHICLE_DIARY_PAY_STT: any = {
	W: { color: "#F2994A", name: "Chờ thanh toán" },
	undefined: { color: "#F2994A", name: "Chờ thanh toán" },
	P: { color: "#3CAEA4", name: "Đã thanh toán" },
	R: { color: "#891B1A", name: "Từ chối thanh toán" }
};
export const DRIVERS_STT: any = {
	DVC: { color: "#FBB243", name: "Đang vận chuyển" },
	undefined: { color: "#FBB243", name: "Đang vận chuyển" },
	DCC: { color: "#2F80ED", name: "Đã có chuyến" },
	CC: { color: "#808A94", name: "Chưa có chuyến" }
};
export const DRIVERS_SORT: any = [
	{
		name: "Đang vận chuyển",
		value: "DVC"
	},
	{
		name: "Đã có chuyến",
		value: "DCC"
	},
	{
		name: "Chưa có chuyến",
		value: "CCC"
	}
];
export const MAINTENANCE: any = {
	O: { color: "#808a94", name: "Mới" },
	R: { color: "#2f80ed", name: "Đã xác nhận" },
	H: { color: "#EB5757", name: "Hủy" },
	E: { color: "#E46463", name: "Từ chối" },
	C: { color: "#3CAEA4", name: "Đã chốt" }
};
export const GOOGLE_MAP_KEY = "AIzaSyDNI_ZWPqvdS6r6gPVO50I4TlYkfkZdXh8";
export const MAINTENANCEDETAIL: any = {
	Mới: { color: "#808a94", name: "Mới" },
	"Đã tiếp nhận": { color: "#f2994a", name: "Đã tiếp nhận" },
	Hủy: { color: "#EB5757", name: "Hủy" },
	"Từ chối": { color: "#E46463", name: "Từ chối" },
	"Đã chốt": { color: "#3CAEA4", name: "Đã chốt" },
	"Đã thanh toán": { color: "#3CAEA4", name: "Đã thanh toán" },
	"Chờ thanh toán": { color: "#3CAEA4", name: "Chờ thanh toán" }
};

export const MAINTENANCETOTAL: any = {
	W: { color: "#808a94", name: "Chờ thanh toán" },
	R: { color: "#eb5757", name: "Từ chối" },
	P: { color: "#3caea4", name: "Đã thanh toán" }
};
export const VEHICLE_DIARY_STT_FILTER: any = [
	{ id: "O", name: "Mới" },
	{ id: "R", name: "Đã tiếp nhận" },
	{ id: "C", name: "Đã chốt" },
	{ id: "H", name: "Hủy" },
	{ id: "E", name: "Từ chối" }
];
export const VEHICLE_DIARY_PAY_STT_FILTER: any = [
	{ id: "W", name: "Chờ thanh toán" },
	{ id: "P", name: "Đã thanh toán" },
	{ id: "R", name: "Từ chối" }
];

export const FILE_MARK = "_systms_";

export const gendersList = Object.entries(UserGenderEnum).map(([key, val]) => ({ label: key, value: val }));

export const customerTypesList = Object.entries(CustomerTypeEnum).map(([key, val]) => ({ label: key, value: val }));

export const routerNotAuth = [routerNames.ACTIVE_OTP];

export const acceptImageTypes = ["image/png", "image/svg", "image/jpeg", "image/jpg", "image/gif", "image/ico"];

export const inProgressStatus = [
	{
		value: 1,
		label: "Mới"
	},
	{
		value: 2,
		label: "Thanh toán thất bại"
	}
];

export const confirmedStatus = [
	{
		value: 3,
		label: "Đã xác nhận"
	},
	{
		value: 4,
		label: "Thanh toán thành công"
	}
];

export const packagedStatus = [
	{
		value: 5,
		label: "Chờ đóng gói"
	},
	{
		value: 6,
		label: "Thanh toán thất bại"
	}
];

export const shippingStatus = [
	{
		value: 7,
		label: "Chờ lấy hàng"
	},
	{
		value: 8,
		label: "Đang vận chuyển"
	},
	{
		value: 9,
		label: "Đang giao hàng"
	},
	{
		value: 10,
		label: "Giao thành công"
	},
	{
		value: 11,
		label: "Lỗi giao hàng"
	},
	{
		value: 12,
		label: "Chờ giao lại"
	}
];

export const failShipStatus = [
	{
		value: 13,
		label: "Đang chuyển hoàn"
	},
	{
		value: 14,
		label: "Đã chuyển hoàn"
	}
];

export const completedStatus = [
	{
		value: 15,
		label: "Giao thành công"
	}
];

export const totalStatus = [
	{
		value: 1,
		label: "Mới"
	},
	{
		value: 2,
		label: "Thanh toán thất bại"
	},
	{
		value: 3,
		label: "Đã xác nhận"
	},
	{
		value: 4,
		label: "Thanh toán thành công"
	},
	{
		value: 5,
		label: "Chờ đóng gói"
	},
	{
		value: 6,
		label: "Thanh toán thất bại"
	},
	{
		value: 7,
		label: "Chờ lấy hàng"
	},
	{
		value: 8,
		label: "Đang vận chuyển"
	},
	{
		value: 9,
		label: "Đang giao hàng"
	},
	{
		value: 10,
		label: "Giao thành công"
	},
	{
		value: 11,
		label: "Lỗi giao hàng"
	},
	{
		value: 12,
		label: "Chờ giao lại"
	},
	{
		value: 13,
		label: "Đang chuyển hoàn"
	},
	{
		value: 14,
		label: "Đã chuyển hoàn"
	},
	{
		value: 15,
		label: "Giao thành công"
	}
];

export const VEHICLE_SERVICES = [
	{ id: "Công ty", name: "Công ty" },
	{ id: "Thuê ngoài", name: "Thuê ngoài" }
];
export const TIME_FILTER = [
	{ id: 1, name: "6 giờ" },
	{ id: 2, name: "12 giờ" },
	{ id: 3, name: "24 giờ" },
	{ id: 4, name: "1 ngày trước" },
	{ id: 5, name: "2 ngày trước" },
	{ id: 6, name: "3 ngày trước" }
];
export const TYPE_DATE = [
	{ id: "exp_registration_at", name: "Hạn đăng kiểm" },
	{ id: "exp_maintenance_at", name: "Hạn bảo dưỡng" },
	{ id: "exp_insurance_at", name: "Hạn bảo hiểm" }
];
export const VEHICLE_MANUFACTURES = [
	{ id: "Honda", name: "Honda" },
	{ id: "Hino", name: "Hino" },
	{ id: "Suzuki", name: "Suzuki" },
	{ id: "HyunDai", name: "HyunDai" },
	{ id: "Isuzu", name: "Isuzu" },
	{ id: "Kia", name: "Kia" },
	{ id: "Ford Transsit", name: "Ford Transsit" }
];
export const VEHICLE_SOURCES = [
	{ id: "Trong nước", name: "Trong nước" },
	{ id: "Nhập khẩu", name: "Nhập khẩu" }
];
export const VEHICLE_COLORS = [
	{ id: "Vàng", name: "Vàng" },
	{ id: "Trắng", name: "Trắng" }
];
export const VEHICLE_TRANSMISSION = [
	{ id: "Số sàn", name: "Số sàn" },
	{ id: "Số tự động", name: "Số tự động" }
];
export const VEHICLE_WHEEL_DRIVE = [
	{ id: "RWD: Cầu sau", name: "RWD: Cầu sau" },
	{ id: "FWD: Cầu trước", name: "FWD: Cầu trước" }
];
export const VEHICLE_FUEL = [
	{ id: "Xăng", name: "Xăng" },
	{ id: "Dầu", name: "Dầu" },
	{ id: "Hỗn hợp", name: "Hỗn hợp" }
];
export const VEHICLE_FUEL_PROVIDER = [
	{ id: "Xăng", name: "Xăng" },
	{ id: "Dầu", name: "Dầu" }
];
export const messageRequired = "Vui lòng không bỏ trống!";

export const PRODUCT_LEVEL = Object.freeze([
	{
		product_level: ProductLevelEnum["Sản phẩm cha"],
		label: "Sản phẩm cấu hình",
		icon: "productParent",
		color: ""
	},
	{
		product_level: ProductLevelEnum["Sản phẩm con"],
		label: "Sản phẩm tuỳ biến",
		icon: "productChild",
		color: ""
	},
	{
		product_level: ProductLevelEnum["Sản phẩm độc lập"],
		label: "Sản phẩm độc lập",
		icon: "productSingle",
		color: ""
	}
]);

export const VEHICLE_STATUS: any = {
	A: {
		name: "Đang vận chuyển",
		color: colors.primary_color_1_5
	},
	D: {
		name: "Chưa có chuyến",
		color: colors.neutral_color_1_3
	},
	M: {
		name: "Bảo trì bảo dưỡng",
		color: colors.accent_color_2_2
	},
	R: {
		name: "Sữa chữa",
		color: colors.accent_color_4_2
	},
	default: {
		name: "Không tìm thấy trạng thái",
		color: colors.neutral_color_1_2
	}
};
export interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
export const INIT_LOCATION_STATE = Object.freeze({
	longitude: 106.653686,
	latitude: 10.801377,
	zoom: 3.5,
	bearing: 0,
	pitch: 35
});

export const STATUS_WH_DASHBOARD_WARN: any = Object.freeze({
	A: {
		name: "CheckIn và Out đúng giờ",
		class_name: "warn-A",
		type: 0
	},
	B: {
		name: "CheckIn vào kho",
		class_name: "warn-B",
		type: 0
	},
	C: {
		name: "CheckIn hoặc Out trễ",
		class_name: "warn-C",
		type: 0
	},
	D: {
		name: "Chưa CheckIn hoặc Out",
		class_name: "warn-D",
		type: 0
	},
	E: {
		name: "Quá giờ khởi hành",
		class_name: "warn-E",
		type: 1
	},
	F: {
		name: "Quá giờ kết thúc",
		class_name: "warn-F",
		type: 1
	},
	G: {
		name: "Bỏ trạm",
		class_name: "warn-G",
		type: 0
	}
});
export const STATUS_WH_DASHBOARD_WARN_ARR = Object.entries(STATUS_WH_DASHBOARD_WARN).map(
	([key, { name, class_name, type }]: any) => ({ status: key, class_name, name, type })
);

export const PERCENT_WEIGHT_DASHBOARD_WARN: any = Object.freeze({
	A: {
		name: "< 50%",
		class_name: "tms-bg-warn-lt-50"
	},
	B: {
		name: "50 - 75%",
		class_name: "tms-bg-warn-lt-75"
	},
	C: {
		name: "75 - 95%",
		class_name: "tms-bg-warn-lt-95"
	},
	D: {
		name: ">= 95%",
		class_name: "tms-bg-warn-gte-95"
	},
	E: {
		name: "-",
		class_name: "tms-bg-not-vehicle"
	}
});
export const PERCENT_WEIGHT_DASHBOARD_WARN_ARR = Object.entries(PERCENT_WEIGHT_DASHBOARD_WARN).map(
	([key, { name }]: any) => ({ value: key, name: name })
);

export const SITE_BASE = [
	{ id: "tuyendung", name: "Tuyển dụng" },
	{ id: "ntlogistics", name: "Website" }
];

export const POS_BASE = [
	{ id: "top", name: "Top" },
	{ id: "bot", name: "Bottom" },
	{ id: "right", name: "Right" },
	{ id: "left", name: "Left" }
];

export const TYPE_YEP_BASE = [
	{ id: "NTL", name: "Nhân viên NTL" },
	{ id: "KH", name: "Ngoài NTL" }
];

export const WORK_FROM_BASE = [
	{ id: "Toàn thời gian", name: "Toàn thời gian" },
	{ id: "Bán thời gian", name: "Bán thời gian" }
];
export const ISHOT = [
	{ id: "1", name: "Có" },
	{ id: "0", name: "Không" }
];
export const REASON_BASE = [
	{ id: "Tuyển mới", name: "Tuyển mới" },
	{ id: "Tuyển thay thế", name: "Tuyển thay thế" }
];

export const SALARY_BASE = [
	{ id: "1", name: "Thoả thuận" },
	{ id: "0", name: "Cụ thể" }
];

export const WORK_EXP_BASE = [
	{ id: "Chưa có kinh nghiệm", name: "Chưa có kinh nghiệm" },
	{ id: "Dưới 1 năm kinh nghiệm", name: "Dưới 1 năm kinh nghiệm" },
	{ id: "2 năm kinh nghiệm", name: "2 năm kinh nghiệm" },
	{ id: "3 năm kinh nghiệm", name: "3 năm kinh nghiệm" },
	{ id: "4 năm kinh nghiệm", name: "4 năm kinh nghiệm" },
	{ id: "5 năm kinh nghiệm", name: "5 năm kinh nghiệm" },
	{ id: "Trên 5 năm kinh nghiệm", name: "Trên 5 năm kinh nghiệm" },
];

export const STATUS_COLOR_NEW: any = {
	O: "#808a94",
	P: "#2f80ed",
	D: "#EB5757",
	H: "#E46463",
	A: "#3CAEA4"
};

export const STATUS_POST_BASE = [
	// { id: "O", name: "Tin mới" },
	{ id: "W", name: "Chờ duyệt" },
	{ id: "A", name: "Đã duyệt" },
	{ id: "D", name: "Nháp" },
	{ id: "H", name: "Ẩn" }
];

export const STATUS_WH_OPERATION_COLOR: any = {
	O: "#808a94",
	P: "#2f80ed",
	D: "#EB5757",
	C: "#3CAEA4"
};

export const STATUS_WH_OPERATION_MAPPING:any = {
	O: "Mới",
	P: "Ghi tạm",
	D: "Hủy",
	C: "Hoàn thành"
}
