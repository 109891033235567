import { Button, Form, Modal, Switch } from "antd";
import axios from "axios";
// import JSMpeg from "jsmpeg-player";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { loadPlayer } from "rtsp-relay/browser";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	// const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [status, setStatus] = useState(true);
	const canvas = useRef<HTMLCanvasElement>(null);
	const [loading, setLoading] = useState(false);
	const [ws, setWs] = useState(null); // State to hold the websocket instance

	useEffect(() => {
		// const init = async () => {
		// 	try {
		// 		// if (users.length === 0 || !stateGetOrgs?.data) return;
		// 		setLoading(true);

		// 		axios
		// 			.post("http://localhost:3001/stream-url", {
		// 				name: "name",
		// 				streamUrl: `rtsp://${detail?.user}:${detail?.pass}@${detail?.ip}:${detail?.port}/Streaming/Channels/${detail?.channel}`,
		// 				wsPort: detail?.wsport,
		// 				ffmpegOptions: {
		// 					"-stats": "",
		// 					"-r": 30
		// 				}
		// 			})
		// 			.then((response) => {
		// 				var videoUrl = `ws://127.0.0.1:${detail?.wsport}`;

		// 				var player = new JSMpeg.VideoElement("#video-canvas", videoUrl, {
		// 					autoplay: true
		// 				});

		// 				console.log(player);
		// 			})
		// 			.catch((error) => {
		// 				console.error("Error:", error);
		// 			});
		// 		setLoading(false);
		// 	} catch (error) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		if (!canvas.current) throw new Error("Ref is null");
		if (detail?.type === "hikvision") {
			loadPlayer({
				url: `ws://localhost:3002/api/stream/${detail?.ip}?user=${detail?.user}&pass=${detail?.pass}&port=${detail?.port}`,
				canvas: canvas.current,
				videoBufferSize: 10 * 1024 * 1024
			});
		}
		if (detail?.type === "kbvision") {
			loadPlayer({
				url: `ws://localhost:3002/api/streamkbvision/${detail?.ip}?user=${detail?.user}&pass=${detail?.pass}&port=${detail?.port}`,
				canvas: canvas.current,
				videoBufferSize: 10 * 1024 * 1024
			});
		}
	}, []); //eslint-disable-line
	// useEffect(() => {}, [status]);
	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`CCTV`}
				style={{
					maxWidth: "80vw"
				}}
				width={"60%"}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
						</div>
						{/* <Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{}</p>
						</Button> */}
					</div>
				}
			>
				<Loading spinning={loading}>
					{/* <div id="video-canvas" style={{ height: "500px", width: "100%" }}></div> */}
					<canvas ref={canvas} style={{ height: "500px", width: "100%" }} />
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
