import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { textVersion } from "src/utils/text";
import moment from "moment";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter>
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          className="copyright"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          ©LSC {moment().format('YYYY')}. All rights reserved
        </div>
       
      </Row>
    </AntFooter>
  );
}

export default Footer;
