/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import { useLocation, useParams } from "react-router-dom";
import FormSelect from "src/components/form/FormSelect";
import { localGetAccount } from "src/utils/localStorage";
import moment from "moment";
import { Row, Col, Timeline, Card, Tag } from "antd";

const DetailedBill = ({ billCode }: any) => {
	const [loading, setLoading] = useState<any>(false);
	const [billInfo, setBillInfo] = useState<any>({});
	useEffect(() => {
		// let USER_INFO: any = localGetAccount();
		// USER_INFO = JSON.parse(USER_INFO);
		// console.log("USER_INFO", USER_INFO);
		// setUser(USER_INFO);
		getDetailedBill(billCode);
	}, [billCode]);

	const getDetailedBill = async (billCode: any) => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			setLoading(true);
			const res: any = await _apiNotSaga.showByCode(billCode, {});
			setLoading(false);
			setBillInfo(res?.data);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	return (
		<div style={{ padding: "20px" }}>
			<Loading spinning={loading}>
				{!billInfo.bill_code ? (
					<Card bordered={false}></Card>
				) : (
					<Card
						title={
							<div style={{ display: "flex" }}>
								<h1>
									<strong>{billInfo.bill_code}</strong>
								</h1>
								<Tag style={{ marginLeft: 10 }} color={"processing"}>
									{billInfo?.bill_status_name}
								</Tag>
							</div>
						}
						bordered={false}
					>
						<Row gutter={[16, 16]}></Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Card title="Thông tin người gửi" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
									<p>
										Sân bay: <strong>{billInfo.s_name}</strong>
									</p>
									<p>
										Hãng bay: <strong>{billInfo.partner_name}</strong>
									</p>
									<p>
										Ngày tạo: <strong>{moment(billInfo.created_at).format("DD-MM-YYYY HH:mm:ss")}</strong>
									</p>
								</Card>
							</Col>

							<Col span={12}>
								<Card title="Thông tin người nhận" size="small" bordered={false} headStyle={{ fontWeight: 600 }}>
									<p>
										Họ tên: <strong>{billInfo.r_name}</strong>
									</p>
									<p>
										SDT: <strong>{billInfo.r_phone}</strong>
									</p>
									<p>
										Địa chỉ:{" "}
										<strong>
											{billInfo.r_address}, {billInfo.r_ward_name}, {billInfo.r_district_name},{" "}
											{billInfo.r_province_name}
										</strong>
									</p>
								</Card>
							</Col>
						</Row>
						<Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
							<Col span={12}>
								<Card title="Thông tin vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
									<p>
										Dịch vụ: <strong>{billInfo.service_name}</strong>
									</p>
									<p>
										Trọng lượng (Kg): <strong>{billInfo.weight}</strong>
									</p>
									<p>
										Kích thước hàng hoá (Cm):{" "}
										<strong>
											{billInfo.length}x{billInfo.width}x{billInfo.height}
										</strong>
									</p>
									<p>
										Số kiện: <strong>{billInfo.package_number}</strong>
									</p>
									<p>
										Loại hàng hoá: <strong>{billInfo.goods_type_name}</strong>
									</p>
									<p>
										Số SEAL: <strong>{billInfo.seal_number ? JSON.parse(billInfo.seal_number).join(",") : ""}.</strong>
									</p>
									<p>
										Ghi chú: <strong>{billInfo.note}</strong>
									</p>
								</Card>
							</Col>

							<Col span={12}>
								<Card title="Hành trình vận đơn" bordered={false} size="small" headStyle={{ fontWeight: 600 }}>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Timeline mode="left">
												{(billInfo.log || []).map((item: any) => (
													<Timeline.Item label={moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}>
														<p>{item.desc_history}</p>
													</Timeline.Item>
												))}
											</Timeline>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Card>
				)}
			</Loading>
		</div>
	);
};

export default DetailedBill;
