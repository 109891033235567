import { Button, Col, Form, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [zone, setZone] = useState<any>([]);
	const [departmentBlock, setDepartmentBlock] = useState<any>([]);
	const [dataDepartments, setDataDepartments] = useState<any>([]);
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [dataWorkLocation, setDataWorkLocation] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [dataUser, setDataUser] = useState<any>([]);
	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
		const getZone = async () => {
			const res: any = await _apiNotSaga.getZones({});

			setZone(res?.data);
		};
		getZone();
		const getDepartmentBlock = async () => {
			const res: any = await _apiNotSaga.getDepartmentBlock({});
			const _users = res?.data.map((e: any) => ({
				id: e.id,
				name: `${e?.department_block_name}`
			}));
			setDepartmentBlock(_users);
		};
		getDepartmentBlock();
		const initUser = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getUsers({ limit: 100, status: "A" });
				setDataUser(res.data)
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initUser();
		const initDepart = async () => {
			try {
				const res: any = await _apiNotSaga.getDepartment({ limit: 100, status: "A" });
				setDataDepartments(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initDepart();
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCareer({ limit: 100, status: "A" });
				setDataCareer(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
		
		
	}, []); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.recruimentTicketDetails(detail.id);
				
				form.setFieldsValue({
					...res?.data,
					qty: res?.data?.qty?.toString(),
				});
				handleChangeOrg(res?.data?.org_id);
				handleChangeDepartment(res?.data?.department_id);
				setStatus(res?.data?.status === "A");
				// setLock(res?.data?.locked === "Y");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, [ stateGetOrgs.isLoading]); //eslint-disable-line
	
	const onSubmit = async (val: any) => {
		
		// setLoading(true);
		const params = {
			...val,
			status: status ? "A" : "D",
			department_block_name: departmentBlock?.find((e: any) => e.id === val.department_block_id)?.name,
			department_name: dataDepartments?.find((e: any) => e.id === val.department_id)?.department_name,
			org_name: stateGetOrgs?.data?.find((e: any) => e.id === val.org_id)?.org_name,
			position_name: dataPosition?.find((e: any) => e.id === val.position_id)?.position_name,
			zone_name: zone?.find((e: any) => e.id === val.zone_id)?.zone_name,
			work_location_name: dataWorkLocation?.find((e: any) => e.id === val.work_location_id)?.post_name,
			career_name: dataCareer?.find((e: any) => e.id === val.career_id)?.career_name,
		};
		console.log(params);
		try {
			const res: any = isCreate
				? await _apiNotSaga.createRecruimentTicket(params)
				: await _apiNotSaga.updateRecruimentTicket(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleChangeOrg = (e: any) => {
		const initworklocation = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getWorkLocation({ limit: 100, status: "A",org_code: stateGetOrgs?.data?.find((es: any) => es.id === e)?.org_code, });
				setDataWorkLocation(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initworklocation();
	}
	const handleChangeDepartment = (e: any) => {
		const initpositions = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPositions({ limit: 100, status: "A", department_id: e });
				setDataPosition(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpositions();
	}
	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} người dùng`}
				style={{
					maxWidth: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="zone_id"
									label="Khu vực"
									dataSource={zone}
									placeholder="Chọn khu vực"
									slOption={{
										name: "zone_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="department_block_id"
									label="Khối"
									dataSource={departmentBlock}
									placeholder="Chọn khối"
									slOption={{
										name: "name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="org_id"
									label="Chi nhánh"
									dataSource={stateGetOrgs?.data}
									placeholder="Chi nhánh"
									onChange={(e) => handleChangeOrg(e)}
									slOption={{
										name: "org_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="department_id"
									label="Phòng ban"
									dataSource={dataDepartments}
									onChange={(e) => handleChangeDepartment(e)}
									placeholder="Phòng ban"
									slOption={{
										name: "department_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="career_id"
									label="Ngành nghề"
									dataSource={dataCareer}
									placeholder="Ngành nghề"
									slOption={{
										name: "career_name",
										value: "id"
									}}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="work_location_id"
									label="Nơi làm việc"
									dataSource={dataWorkLocation}
									placeholder="Nơi làm việc"
									slOption={{
										name: "post_name",
										value: "id"
									}}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="position_id"
									label="Chức danh"
									dataSource={dataPosition}
									placeholder="Chức danh"
									slOption={{
										name: "position_name",
										value: "id"
									}}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="qty"
									label="Số lượng tuyển"
									type="text"
									size="large"
									placeholder="Nhập Số lượng tuyển"
									required
									// disabled
								/>
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="respon_staff_id"
									label="Nhân viên tuyển dụng"
									dataSource={dataUser}
									placeholder="Nhân viên tuyển dụng"
									slOption={{
										name: "full_name",
										value: "id"
									}}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col> */}
							<Col xs={24} md={12}>
								<FormInput
									name="ticket_id"
									label="Mã Ticket Tuyển dụng"
									type="text"
									size="large"
									placeholder="Nhập Mã Ticket Tuyển dụng"
									required
									// disabled
								/>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
