import {
	ArrowLeftOutlined,
	ExclamationCircleFilled,
	LeftOutlined,
	RightOutlined,
	UploadOutlined
} from "@ant-design/icons";
import {
	Affix,
	Avatar,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Form,
	Input,
	Modal,
	Popconfirm,
	Progress,
	Radio,
	Rate,
	Row,
	Spin,
	Steps,
	Tabs,
	Tag,
	Typography,
	Upload
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import TableStyled from "src/components/table/TableStyled";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsDataDetail } from "./data";
import { RouteParams } from "src/types";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { notifyError, notifySuccess } from "src/components/notification";
import moment from "moment";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";
import UserModal from "./UserModal";
import { localGetAccount, localGetToken } from "src/utils/localStorage";
import FormInputNumber from "src/components/form/FormInputNumber";
import UploadFile from "src/components/upload/UploadFile";
import html2PDF from "jspdf-html2canvas";
import ModalEmail from "./ModalEmail";
const { Search } = Input;
// import { columnsDataExport, columnsDataRow, columnsTrackSration } from "../list/data";
// import "./styles.scss";
const { Title } = Typography;
const { confirm } = Modal;
const ListProfileDetails = () => {
	const [dataProfile, setDataProfile] = useState<any>();
	const [callback, setCallback] = useState<boolean>(false);
	const [tabActive, setTabActive] = useState<string>("1");
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const isMount = useIsMount();
	const [numPages, setNumPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState<number>(1);
	const paramsURL = useParams<RouteParams>();
	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}
	const [dataLevel, setDataLevel] = useState<any>();

	const [bpDanhgia, setBpDanhgia] = useState("a");
	const [dataLog, setDataLog] = useState<any>();
	const [formRating] = Form.useForm();
	const [location, setLocation] = useState([]);
	const [qty, setQty] = useState(0);
	const [datas, setDatas] = useState([
		{
			title: "Học vấn – Kinh nghiệm",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 1,
							title: "Học vấn / Kiến thức chuyên môn",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 2,
							title: "Kinh nghiệm làm việc - cùng ngành",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 3,
							title: `Thâm niên, kỹ năng phù hợp 
							(Theo Mô tả CV`,
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Năng lực Hành vi – Giá trị cốt lõi",
			data: [
				{
					title: "1. Trách nhiệm",
					datachild: [
						{
							id: 4,
							title: `Ý thức trách nhiệm với công việc, 
							tinh thần hợp tác.  Làm việc hiệu quả 
							với thành viên khác để hoàn thành 
							mục tiêu`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 5,
							title: `Ứng viên có khả năng giải quyết vấn 
							đề, sự cố trong công việc. Có thể làm
							được việc “không thể thành có thể”, 
							tạo sự đột phá (có những trải nghiệm
							thực tế trong quá khứ).`,
							activeTab: "",
							activeTab1: ""
						}
					]
				},
				{
					title: "2. Trung thực",
					datachild: [
						{
							id: 6,
							title: `Trung thực, trân trọng lời nói`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 7,
							title: `Sử dụng ngôn ngữ lượng hoá, không đổ lỗi hoàn cảnh. Sẵn sàng đưa ra cam kết và thực hiện đúng cam kết. Ứng viên có khả năng tạo ảnh hưởng đến người khác.`,
							activeTab: "",
							activeTab1: ""
						}
					]
				},
				{
					title: "3. Chiến đấu",
					datachild: [
						{
							id: 8,
							title: `Yêu thích sự thành công, ứng viên có khả năng vượt qua khó khăn để tạo ra kết quả xuất sắc`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 9,
							title: `Tư duy cởi mở và luôn muốn đấu tranh để dẫn đầu. Không bao giờ nói không, không bao giờ bỏ cuộc`,
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Kỹ năng/ Yếu tố khác",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 10,
							title: "Ngoại ngữ",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 11,
							title: "Phong cách ngoại hình",
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Đánh giá về động cơ phù hợp",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 12,
							title:
								"Mức độ quan tâm đến công ty và CV ứng tuyển (Ứng viên đã tham khảo thông tin Công ty trên Website, xem qua giá trị cốt lõi)",
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		}
	]);
	const [fileList, setFileList] = useState<any>([]);
	const [fileListCV, setFileListCV] = useState<any>([]);
	const [isRatingId, setIsRatingId] = useState(undefined);
	const [noidungkhac, setNoidungkhac] = useState("");
	const [noidungkhacE, setNoidungkhacE] = useState("");
	const [datcheck, setDatcheck] = useState("");
	const [datcheckE, setDatcheckE] = useState("");
	const [dinhhuong, setDinhhuong] = useState("");
	const [dinhhuongE, setDinhhuongE] = useState("");
	const [nhanxetchung, setNhanxetchung] = useState("");
	const [nhanxetchungE, setNhanxetchungE] = useState("");
	const [deleteFiles, setDeleteFiles] = useState<any>([]);
	const [urlJobGoal, setUrlJobGoal] = useState("");
	const [isUncheck, setIsUncheck] = useState(false);
	const [isQDCC, setIsQDCC] = useState(false);
	const [isDat, setIsDat] = useState(false);
	const [isModalPheduyet, setIsModalPheduyet] = useState(false);
	const [dataUser, setDataUser] = useState<any>([]);
	const [assignedBy, setAssignedBy] = useState<any>();
	const [isAssign, setIsAssign] = useState(false);
	const [show, setShow] = useState(false);
	const [dataEmail, setDataEmail] = useState<any>([]);
	const [visibleEmail, setVisibleEmail] = useState(false);
	const [contentEmail, setContentemail] = useState("");
	const [titleEmail, setTitleEmail] = useState("");
	const [searchtxt, setSearchtxt] = useState("");
	const history = useHistory();
	const [data, setData] = useState<any>([]);
	const [hidePrevious, setHidePrevious] = useState(false);
	const [hideNext, setHideNext] = useState(false);
	const [items1, setItems1] = useState<any>([
		[
			{
				key: 12,
				label: "Tuyển dụng "
			},
			{
				key: 8,
				label: "Nhận việc mytrang4249@gmail.com"
			},
			{
				key: 6,
				label: "Xác nhận nhận việc"
			},
			{
				key: 4,
				label: "email test"
			},
			{
				key: 1,
				label: "Xác nhận ứng tuyển"
			}
		]
	]);
	// let location :any = [];
	useEffect(() => {
		if (dataProfile?.work_location) {
			setLocation(JSON?.parse(dataProfile?.work_location));
			// location = JSON?.parse(dataProfile?.work_location)
		}
	}, [dataProfile?.work_location]);
	useEffect(() => {
		if (dataProfile?.recruitment_news_id) {
			const init = async () => {
				try {
					// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
					setLoading(true);
					const res: any = await _apiNotSaga.getProfileList({
						recruitment_news_id: dataProfile?.recruitment_news_id,
						limit: 100,
						page: 1
					});
					setData(res?.data);
					setLoading(false);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			};
			init();
		}
	}, [dataProfile?.recruitment_news_id]);
	useEffect(() => {
		if (data?.length > 0) {
			const currentIndex = data.findIndex((item: any) => item.candidate_id == paramsURL.id);
			console.log("currentIndex", currentIndex);
			if (currentIndex !== -1) {
				const previousIndex = currentIndex - 1;
				if (previousIndex >= 0) {
					setHidePrevious(false);
				} else {
					setHidePrevious(true);
					// Xử lý khi không tìm thấy phần tử đứng trước
				}
				const nextIndex = currentIndex + 1;
				if (nextIndex < data.length) {
					setHideNext(false);
				} else {
					setHideNext(true);
					// Xử lý khi không tìm thấy phần tử đứng sau
				}
			}
		}
	}, [data]);
	useEffect(() => {
		if (location.length > 0) {
			let qtys = location.map((item: any) => parseInt(item?.qty)).reduce((a: any, b: any) => a + b, 0);
			setQty(qtys);
		}
	}, [location]);
	useEffect(() => {
		const initLevel = async () => {
			try {
				setLoading(true);
				const ress: any = await _apiNotSaga.getLevel({});
				setDataLevel(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initLevel();
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getUsers({ limit: 100, status: "A" });
				setDataUser(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
		const initemail = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getEmailTemplate({ limit: 100, status: "A" });
				setDataEmail(ress?.data);

				setItems1(ress?.data?.map((item: any) => ({ key: item.id, label: item.email_template_name })));
				// setTotal(ress?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initemail();
	}, []);
	const [loading, setLoading] = useState(false);
	const tieuchi = ["Chưa phù hợp", "Tạm chấp nhận", "Phù hợp", "Rất Phù hợp"];

	useEffect(() => {
		const initp = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateDetails(paramsURL.id);
				setDataProfile(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initp();
		const initLog = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let params = {
					candidate_id: paramsURL.id
				};
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateLog(params);
				setDataLog(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initLog();
		const initResult = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let params = {
					candidate_id: paramsURL.id
				};
				setLoading(true);
				const resss: any = await _apiNotSaga.getCandidateResult(params);
				if (resss?.data?.length > 0) {
					console.log("resss", resss?.data[0]);
					if (resss?.data[0]?.probation_salary && resss?.data[0]?.after_probation_salary) {
						setIsQDCC(true);
					} else {
						setIsQDCC(false);
					}
					setLoading(false);

					formRating.setFieldsValue({
						...resss?.data[0],
						expected_working: moment(resss?.data[0]?.expected_working)
					});
				} else {
					setIsQDCC(false);
				}
				// setDataRe(ress?.data);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initResult();
		const initEvalute = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let params = {
					candidate_id: paramsURL.id
				};
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateEvalute(params);
				if (ress.data.length > 0) {
					setIsRatingId(ress?.data[0].id);
					if (ress?.data[0]?.recruitment_eval) {
						let paramsss = JSON?.parse(ress?.data[0]?.recruitment_eval);

						setDatas(paramsss?.data);
						if (isAnyCheckboxUnchecked(paramsss?.data)) {
							setIsUncheck(true);
							// return notifyError('Vui lòng chọn đánh giá cho tất cả tiêu chí');
						} else {
							setIsUncheck(false);
						}

						setDinhhuong(paramsss?.dinhhuong);
						setNhanxetchung(paramsss?.nhanxetchung);
						setDatcheck(paramsss?.datcheck);
						if (paramsss?.datcheck === "Đạt") {
							setIsDat(true);
						}
						setNoidungkhac(paramsss?.noidungkhac);
					} else {
						setIsUncheck(true);
					}
					if (ress?.data[0]?.specialize_eval) {
						let paramsss = JSON?.parse(ress?.data[0]?.specialize_eval);
						setDatas(paramsss?.data);
						setDinhhuongE(paramsss?.dinhhuong);
						setNhanxetchungE(paramsss?.nhanxetchung);
						setDatcheckE(paramsss?.datcheck);
						setNoidungkhacE(paramsss?.noidungkhac);
					}
				} else {
					setIsRatingId(undefined);
					setIsUncheck(true);
				}
				// let paramsss = JSON?.parse(ress?.data?.recruitment_eval);
				// console.log("paramsss", paramsss);
				// setDataEvalute(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initEvalute();
	}, [callback]);
	const onSubmitForm = (val: any) => {
		let params = {};
	};
	const cancelModal = () => {
		setVisible(false);
	};
	const cancelModalEmail = () => {
		setVisibleEmail(false);
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	const onSuccessModalEmail = () => {
		setCallback(!callback);
		setVisibleEmail(false);
	};
	function replacePlaceholders(emailTemplate: any, params: any) {
		for (var key in params) {
			if (params.hasOwnProperty(key)) {
				var placeholder = "[" + key + "]"; // Xây dựng placeholder từ key
				var value = params[key]; // Lấy giá trị từ params

				// Thay thế placeholder bằng giá trị tương ứng
				emailTemplate = emailTemplate?.replaceAll(placeholder, value);
				// emailTemplate = emailTemplate.replace(new RegExp(placeholder, 'g'), value);
			}
		}
		return emailTemplate;
	}
	const handleUpdateStatus = async (status: any) => {
		if (status === 6) {
			if (!formRating.getFieldValue("probation_salary") || !formRating.getFieldValue("after_probation_salary")) {
				return notifyError("Vui lòng nhập thông tin lương đề xuất");
			} else {
				if (dataProfile?.assigned_by !== 0) {
					return notifyError(
						`Vui lòng đợi ${dataProfile?.assigned_by} xét duyệt lương trước khi thực hiện hành động này`
					);
				}
				return setIsModalPheduyet(true);
			}
		}
		if (status === 8 && dataProfile.recruiment_ticket_id) {
			let paramsticket = {
				qty_actual: dataProfile?.qty_actual + 1
			};
			try {
				const ress: any = await _apiNotSaga.updateRecruimentTicket(dataProfile.recruiment_ticket_id, paramsticket);
				// setCallback(!callback);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		}
		if (status === 9 && dataProfile.recruiment_ticket_id) {
			if (dataProfile?.candidate_status_id === 8) {
				let paramsticket = {
					qty_actual: dataProfile?.qty_actual - 1
				};
				try {
					const ress: any = await _apiNotSaga.updateRecruimentTicket(dataProfile.recruiment_ticket_id, paramsticket);
					// setCallback(!callback);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			}
		}
		let params = {
			candidate_status_id: status
		};
		let params1 = {
			candidate_id: dataProfile.candidate_id,
			candidate_status_id: status,
			action_name: "Cập nhật trạng thái"
		};

		try {
			const res: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
			setCallback(!callback);
			setLoading(false);
			notifySuccess(res.message);
			const res1: any = await _apiNotSaga.createCandidateLog(params1);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const [items, setItems] = useState<any>([]);
	const popConfirm = (status: any) => {
		confirm({
			title: "Bạn có chắc muốn chuyển trạng thái hay không?",
			icon: <ExclamationCircleFilled />,
			className: "tms-modal-confirm",
			okText: "Đồng ý",
			cancelText: "Hủy",
			onOk() {
				handleUpdateStatus(status);
			},
			onCancel() {
				console.log("Cancel");
			}
		});
	};
	useEffect(() => {
		if (dataProfile?.candidate_status_id === 1) {
			setItems([
				{
					key: "8",
					label: <a onClick={() => handleUpdateStatus(2)}>Đang duyệt hồ sơ</a>
				}
			]);
		}
		if (dataProfile?.candidate_status_id === 2) {
			setItems([
				{
					key: "2",
					label: <a onClick={() => handleUpdateStatus(3)}>Duyệt-Sơ vấn</a>
				},
				{
					key: "3",
					label: <a onClick={() => handleUpdateStatus(4)}>Từ chối hồ sơ</a>
				}
			]);
		}
		if (dataProfile?.candidate_status_id === 3) {
			setItems([
				{
					key: "12",
					label: <a onClick={() => popConfirm(12)}>Mời phỏng vấn</a>
				},
				{
					key: "4",
					label: <a onClick={() => popConfirm(4)}>Không đạt</a>
				},
				{
					key: "7",
					label: <a onClick={() => popConfirm(4)}>Không liên hệ được</a>
				},
				{
					key: "5",
					label: <a onClick={() => popConfirm(5)}>Phỏng vấn</a>
				},
				{
					key: "6",
					label: <a onClick={() => popConfirm(6)}>Phê duyệt nhận việc</a>
				}
			]);
		}
		if (dataProfile?.candidate_status_id === 12) {
			setItems([
				{
					key: "5",
					label: <a onClick={() => popConfirm(5)}>Phỏng vấn</a>
				},
				{
					key: "6",
					label: <a onClick={() => popConfirm(6)}>Phê duyệt nhận việc</a>
				}
			]);
		}
		if (dataProfile?.candidate_status_id === 5 || dataProfile?.candidate_status_id === 10) {
			setItems([
				{
					key: "5",
					label: <a onClick={() => popConfirm(6)}>Phê duyệt nhận việc</a>
				},
				{
					key: "4",
					label: <a onClick={() => popConfirm(4)}>Không đạt</a>
				}
			]);
		}

		if (dataProfile?.candidate_status_id === 7) {
			setItems([
				{
					key: "8",
					label: <a onClick={() => popConfirm(8)}>Đã nhận việc</a>
				},
				{
					key: "9",
					label: <a onClick={() => popConfirm(9)}>Từ chối nhận việc</a>
				}
			]);
		}
		if (dataProfile?.candidate_status_id === 8) {
			setItems([
				{
					key: "9",
					label: <a onClick={() => popConfirm(9)}>Từ chối nhận việc</a>
				}
			]);
		}
	}, [dataProfile?.candidate_status_id]);
	const isAnyCheckboxUnchecked = (datass: any) => {
		// Duyệt qua mảng datas
		for (const item of datass) {
			for (const childItem of item.data) {
				// Duyệt qua mảng datachild của mỗi item
				for (const checkbox of childItem.datachild) {
					// Nếu activeTab của checkbox không có giá trị
					if (!checkbox.activeTab) {
						return true; // Trả về true nếu có checkbox chưa được chọn
					}
				}
			}
		}
		return false; // Trả về false nếu tất cả các checkbox đều đã được chọn
	};
	const onChange1 = ({ target: { value } }: any) => {
		console.log("radio1 checked", value);
		setBpDanhgia(value);
	};
	const handleCheckboxChange = (dataItemId: any, tieuchiIndex: any) => {
		if (bpDanhgia === "a") {
			const updatedData = datas.map((item) => ({
				...item,
				data: item.data.map((item1) => ({
					...item1,
					datachild: item1.datachild.map((item2) =>
						item2.id === dataItemId ? { ...item2, activeTab: tieuchi[tieuchiIndex] } : item2
					)
				}))
			}));
			console.log("updatedData", updatedData);
			setDatas(updatedData);
		} else {
			const updatedData = datas.map((item) => ({
				...item,
				data: item.data.map((item1) => ({
					...item1,
					datachild: item1.datachild.map((item2) =>
						item2.id === dataItemId ? { ...item2, activeTab1: tieuchi[tieuchiIndex] } : item2
					)
				}))
			}));
			console.log("updatedData", updatedData);
			setDatas(updatedData);
		}
	};
	// console.log("datas", datas);
	const onChangeTab = (key: string) => {
		console.log(key);
		setTabActive(key);
	};
	const showConfirm = () => {
		confirm({
			title: "Hồ sơ không đạt sẽ chuyển sang trạng thái không đạt",
			icon: <ExclamationCircleFilled />,
			content: "Bạn có muốn tiếp tục không?",
			className: "tms-modal-confirm",
			async onOk() {
				let USER_INFO: any = localGetAccount();
				let jsondanhgia = {
					data: datas,
					dinhhuong: bpDanhgia === "a" ? dinhhuong : dinhhuongE,
					nhanxetchung: bpDanhgia === "a" ? nhanxetchung : nhanxetchungE,
					datcheck: bpDanhgia === "a" ? datcheck : datcheckE,
					noidungkhac: bpDanhgia === "a" ? noidungkhac : noidungkhacE
				};
				let params = {
					candidate_id: paramsURL.id,
					user_recruitment_id: bpDanhgia === "a" ? JSON.parse(USER_INFO)?.user.id : undefined,
					recruitment_eval: bpDanhgia === "a" ? JSON?.stringify(jsondanhgia) : undefined,
					user_specialize_id: bpDanhgia === "b" ? JSON.parse(USER_INFO)?.user.id : undefined,
					specialize_eval: bpDanhgia === "b" ? JSON?.stringify(jsondanhgia) : undefined
				};
				let params1 = {
					candidate_id: paramsURL.id,
					candidate_status_id: 0,
					action_name: "Đánh giá ứng viên"
				};
				try {
					const res1: any = await _apiNotSaga.createCandidateLog(params1);
					handleUpdateStatus(4);
					if (isRatingId) {
						const res: any = await _apiNotSaga.updateCandidateEvalute(isRatingId, params);
						setLoading(false);
						notifySuccess(res.message);
						setCallback(!callback);
					} else {
						const res: any = await _apiNotSaga.createCandidateEvalute(params);
						setLoading(false);
						notifySuccess(res.message);
						setCallback(!callback);
					}
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
				// return handleUpdateStatus(4);
			},
			onCancel() {
				console.log("Cancel");
			}
		});
	};
	const onSave = async (val: any) => {
		if (tabActive === "2") {
			let USER_INFO: any = localGetAccount();
			let jsondanhgia = {
				data: datas,
				dinhhuong: bpDanhgia === "a" ? dinhhuong : dinhhuongE,
				nhanxetchung: bpDanhgia === "a" ? nhanxetchung : nhanxetchungE,
				datcheck: bpDanhgia === "a" ? datcheck : datcheckE,
				noidungkhac: bpDanhgia === "a" ? noidungkhac : noidungkhacE
			};
			let params = {
				candidate_id: paramsURL.id,
				user_recruitment_id: bpDanhgia === "a" ? JSON.parse(USER_INFO)?.user.id : undefined,
				recruitment_eval: bpDanhgia === "a" ? JSON?.stringify(jsondanhgia) : undefined,
				user_specialize_id: bpDanhgia === "b" ? JSON.parse(USER_INFO)?.user.id : undefined,
				specialize_eval: bpDanhgia === "b" ? JSON?.stringify(jsondanhgia) : undefined
			};
			let params1 = {
				candidate_id: paramsURL.id,
				candidate_status_id: 0,
				action_name: "Đánh giá ứng viên"
			};
			if (isAnyCheckboxUnchecked(datas)) {
				return notifyError("Vui lòng chọn đánh giá cho tất cả tiêu chí");
			}
			if (datcheck === "") {
				return notifyError("Vui lòng chọn kết luận");
			}
			if (datcheck === "Không Đạt" || datcheckE === "Không Đạt") {
				return showConfirm();
			}
			try {
				const res1: any = await _apiNotSaga.createCandidateLog(params1);
				if (isRatingId) {
					const res: any = await _apiNotSaga.updateCandidateEvalute(isRatingId, params);
					setLoading(false);
					notifySuccess("Cập nhật thành công");
					setCallback(!callback);
				} else {
					const res: any = await _apiNotSaga.createCandidateEvalute(params);
					setLoading(false);
					notifySuccess(res.message);
					setCallback(!callback);
				}
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		}
		if (tabActive === "3") {
			let USER_INFO: any = localGetAccount();
			if (fileList.length !== 0) {
				await uploadFile(fileList[0], async (response: any) => {
					if (response) {
						console.log(response);
						setUrlJobGoal(response.data[0]);
						let params = {
							...val,
							expected_working: moment(val.expected_working).format("YYYY-MM-DD"),
							level_name: dataLevel?.find((item: any) => item.id === val.level_id)?.level_name,
							candidate_id: paramsURL.id,
							user_specialize_id: JSON.parse(USER_INFO)?.user.id,
							job_goal_url: response.data[0]
						};
						let params1 = {
							candidate_id: paramsURL.id,
							candidate_status_id: 0,
							action_name: "Quyết định tuyển dụng"
						};

						try {
							const res1: any = await _apiNotSaga.createCandidateResult(params);
							const res2: any = await _apiNotSaga.createCandidateLog(params1);
							setLoading(false);
							notifySuccess("Cập nhật thành công");
							setCallback(!callback);
						} catch (error: any) {
							setLoading(false);
							notifyError(error.message);
						}
					}
				});
			} else {
				let params = {
					...val,
					expected_working: moment(val.expected_working).format("YYYY-MM-DD"),
					level_name: dataLevel?.find((item: any) => item.id === val.level_id)?.level_name,
					candidate_id: paramsURL.id,
					user_specialize_id: JSON.parse(USER_INFO)?.user.id,
					job_goal_url: undefined
				};
				let params1 = {
					candidate_id: paramsURL.id,
					candidate_status_id: 0,
					action_name: "Quyết định tuyển dụng"
				};

				try {
					const res1: any = await _apiNotSaga.createCandidateResult(params);
					const res2: any = await _apiNotSaga.createCandidateLog(params1);
					setLoading(false);
					notifySuccess("Cập nhật thành công");
					setCallback(!callback);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			}
		}
	};
	const changeFileList = (v: any, deleteFile: any) => {
		setFileList(v);
		if (deleteFile) {
			const _deleteFiles = [...deleteFiles, deleteFile];
			setDeleteFiles(_deleteFiles);
		}
	};
	const changeFileListCV = async (v: any, deleteFile: any) => {
		await uploadFile(v[0], async (response: any) => {
			if (response) {
				console.log(response);

				let params1 = {
					candidate_id: paramsURL.id,
					candidate_status_id: 0,
					action_name: "Upload CV"
				};
				let params = {
					url_cv: response.data[0]
				};
				try {
					const res1: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
					const res2: any = await _apiNotSaga.createCandidateLog(params1);
					setLoading(false);
					notifySuccess(res1.message);
					setCallback(!callback);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			}
		});
	};
	const handleOkPheduyet = async () => {
		let params1 = {
			candidate_id: paramsURL.id,
			candidate_status_id: 0,
			action_name: `Chọn người xét duyệt lương`
		};
		let params = {
			assigned_by: assignedBy,
			candidate_status_id: 6
		};
		try {
			const res1: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
			const res2: any = await _apiNotSaga.createCandidateLog(params1);
			setLoading(false);
			notifySuccess(res1.message);
			setIsModalPheduyet(false);
			setCallback(!callback);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleChangeAssignedBy = (value: any) => {
		setAssignedBy(value);
	};
	const saveFile = () => {
		// let btn:any = document.getElementById("btn");
		setShow(true);
		setLoading(true);
		setTimeout(() => {
			let page: any = document.getElementById("page");
			html2PDF(page, {
				jsPDF: {
					format: "a4"
				},
				imageType: "image/jpeg",
				output: "./pdf/generate.pdf"
			});
		}, 4000);

		setTimeout(() => {
			setShow(false);
			setLoading(false);
		}, 5000);
	};
	// console.log("dataEmail", dataEmail);
	const capitalizeFirstWord =(str: any)=> {
		// Kiểm tra nếu chuỗi có tồn tại và không rỗng
		if (!str) return str;
	
		// Tách chuỗi thành các từ
		let words = str.trim().split(" ");
	
		// Viết hoa từ đầu tiên
		words[0] = words[0].toUpperCase();
	
		// Kết hợp lại các từ thành chuỗi
		return words.join(" ");
	}
	const handleSendEmail = async (e: any) => {
		console.log("e", e);
		let contetemail = dataEmail.find((item: any) => item.id == e.key);
		console.log("contetemail", contetemail);
		let paramsemail = {
			UNG_VIEN: dataProfile?.candidate_name,
			POSITION: dataProfile?.position_name,
			EMAIL_NTD: dataProfile?.handle_by_email,
			SDT_NTD: dataProfile?.handle_by_phone,
			TEN_NTD: dataProfile?.handle_by_name,
			CHUC_VU_NTD: dataProfile?.handle_by_role,
			LINK_CNTT: `<a target="_blank" href=${TUYENDUNG_URL}capnhatthongtin?id=${dataProfile?.candidate_id}&token=${localGetToken()}><span>Cập nhật thông tin cá nhân</span></a> `,
			THOI_GIAN_PV:
				moment(dataProfile?.interview_time).format("HH:mm") +
				" Ngày " +
				moment(dataProfile?.interview_time).format("DD/MM/YYYY"),
			DIA_CHI: dataProfile?.address_interview,
			TITLE_THREAD: capitalizeFirstWord(dataProfile?.thread)
		};
		let updatedEmail = replacePlaceholders(contetemail?.content, paramsemail);
		setTitleEmail(contetemail?.email_template_name);
		setContentemail(updatedEmail);
		setVisibleEmail(true);
	};

	const onChangePV = async (_: any, dateStr: any) => {
		console.log("onChange:", dateStr);
		if (dateStr) {
			let params = {
				interview_time: dateStr
			};
			try {
				const res1: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
				// const res2: any = await _apiNotSaga.createCandidateLog(params1);
				setLoading(false);
				notifySuccess(res1.message);
				setIsModalPheduyet(false);
				setCallback(!callback);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		}
	};
	const onSearch = (value: any) => {
		history.push(`/list-profile?search=${value}`);
	};
	const handlePress = (type: any) => {
		const currentIndex = data.findIndex((item: any) => item.candidate_id == paramsURL.id);
		console.log("currentIndex", currentIndex);
		if (currentIndex !== -1) {
			if (type === "previous") {
				const previousIndex = currentIndex - 1;
				if (previousIndex >= 0) {
					setHidePrevious(false);
					const previousItem = data[previousIndex];
					history.push(`/list-profile/${previousItem?.candidate_id}`);
					window.location.reload();
					// Xử lý khi tìm thấy phần tử đứng trước
				} else {
					setHidePrevious(true);
					// Xử lý khi không tìm thấy phần tử đứng trước
				}
			} else if (type === "next") {
				const nextIndex = currentIndex + 1;
				if (nextIndex < data.length) {
					setHideNext(false);
					const nextItem = data[nextIndex];
					console.log("nextItem", nextItem);
					history.push(`/list-profile/${nextItem?.candidate_id}`);
					window.location.reload();
					// Xử lý khi tìm thấy phần tử đứng sau
				} else {
					setHideNext(true);
					// Xử lý khi không tìm thấy phần tử đứng sau
				}
			}
		}
	};
	return (
		<div className="p-8 trip-detail">
			{visible && (
				<UserModal visible={visible} detail={dataProfile} onCancel={cancelModal} onSuccess={onSuccessModal} />
			)}
			{visibleEmail && (
				<ModalEmail
					visible={visibleEmail}
					detail={dataProfile}
					titleEmail={titleEmail}
					contentEmail={contentEmail}
					onCancel={cancelModalEmail}
					onSuccess={onSuccessModalEmail}
				/>
			)}
			<div className="flex justify-between items-center">
				<Link to={`/list-profile`} style={{ cursor: "pointer" }}>
					<div className="flex items-center">
						<ArrowLeftOutlined className="-mt-[1px]" />
						<span className="ml-1">Quay lại danh sách</span>
					</div>
				</Link>
				<div className="flex justify-end items-center">
					<Search placeholder="Tên ứng viên/Mã hồ sơ" onSearch={onSearch} style={{ width: 300 }} />
					{!hidePrevious && (
						<Button
							onClick={() => handlePress("previous")}
							style={{ backgroundColor: "black" }}
							shape="circle"
							icon={<LeftOutlined style={{ color: "white" }} />}
						/>
					)}
					{!hideNext && (
						<Button
							onClick={() => handlePress("next")}
							style={{ backgroundColor: "black" }}
							className="mx-2"
							shape="circle"
							icon={<RightOutlined style={{ color: "white" }} />}
						/>
					)}
				</div>
			</div>
			<Modal
				className="tms-modal-confirm"
				title="Chọn người xét duyệt lương"
				open={isModalPheduyet}
				onOk={handleOkPheduyet}
				onCancel={() => setIsModalPheduyet(false)}
			>
				<Row gutter={[8, 8]}>
					<Col md={24} xs={24} sm={24}>
						<FormSelect
							name="assigned_by"
							label="Người xét duyệt cuối cùng"
							dataSource={dataUser}
							placeholder="Chọn Người xét duyệt cuối cùng"
							slOption={{
								name: "full_name",
								value: "id"
							}}
							required
							showSearch
							optionFilterProp="children"
							onChange={handleChangeAssignedBy}
						/>
					</Col>
				</Row>
			</Modal>
			<Form name="myForm" layout="vertical" form={formRating} onFinish={onSubmitForm}>
				<Spin spinning={loading}>
					<Row gutter={[16, 16]} className="mt-8">
						<Col xs={24} md={8}>
							<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
								<div className="flex justify-start items-center mb-[20px]">
									<Avatar src={dataProfile?.portrait_url ? CND_URL + dataProfile?.portrait_url : undefined} size={80}>
										USER
									</Avatar>
									<div className="flex flex-col justify-start items-start mx-4">
										<div className="text-black text-[16px] font-normal  ">{dataProfile?.candidate_name}</div>
										{/* <Rate disabled defaultValue={4} /> */}
										<div className="flex">
											<Tag color={dataProfile?.status_color}>{dataProfile?.candidate_status}</Tag>
											{/* <Tag color="success">Đạt</Tag> */}
										</div>
									</div>
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Email:</span>
									<span className="font-semibold ml-1">{dataProfile?.candidate_email}</span>
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Số điện thoại:</span>
									<span className="font-semibold ml-1">{dataProfile?.candidate_phone}</span>
								</div>
								<div className="flex sm:block md:flex justify-between w-full">
									<span className="text-neutral-500 whitespace-nowrap">Địa chỉ:</span>
									<span className="font-semibold ml-1 pl-[40px] text-right">
										{dataProfile?.address}, {dataProfile?.ward_name}, {dataProfile?.district_name},{" "}
										{dataProfile?.province_name}
									</span>
								</div>

								<div className=" text-black text-[18px] font-bold mt-2  ">Vị trí ứng tuyển</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Vị trí:</span>
									<span className="font-semibold ml-1">{dataProfile?.position_name}</span>
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Ngày nộp:</span>
									<span className="font-semibold ml-1">{moment(dataProfile?.created_at).format("DD/MM/YYYY")}</span>
								</div>
								<div className="flex sm:block md:flex justify-between ">
									<span className="text-neutral-500">Nguồn:</span>
									<span className="font-semibold ml-1">{dataProfile?.utm_source}</span>
								</div>
								<div className="w-full p-4 mt-2 bg-neutral-50 rounded-lg border border-zinc-100 flex flex-col">
									<div className="text-sky-500 text-[15px] font-normal font-['Roboto'] underline leading-snug">
										{dataProfile?.thread}
									</div>
									<div className="flex sm:block md:flex justify-between ">
										<span className="text-neutral-500">SL tuyển:</span>
										<span className="font-semibold ml-1">{qty}</span>
									</div>
									<div className="flex sm:block md:flex justify-between ">
										<span className="text-neutral-500">Cty:</span>
										<span className="font-semibold ml-1">Nhất Tín Logistics</span>
									</div>
									<Progress percent={parseFloat(((dataProfile?.qty_actual / qty) * 100).toFixed(0))} size="small" />
								</div>
								{dataProfile?.candidate_status_id === 12 && (
									<>
										<p className="text-[16px] font-bold pt-2">Chọn thời gian phòng vấn</p>
										<DatePicker
											placeholder="Chọn thời gian phòng vấn"
											defaultValue={dataProfile?.interview_time ? moment(dataProfile?.interview_time) : undefined}
											showTime
											onChange={onChangePV}
										/>
										{dataProfile?.interview_time && (
											<p className="text-[14px]">
												Đã cập nhật thời gian phỏng vấn, bạn có thể gửi email mời phỏng vấn cho ứng viên
											</p>
										)}
									</>
								)}

								<div className=" text-black text-[18px] font-bold mt-2  ">Quy trình ứng tuyển</div>
								<Steps
									direction="vertical"
									current={
										dataProfile?.candidate_status_id === 1
											? 0
											: dataProfile?.candidate_status_id === 2
											? 1
											: dataProfile?.candidate_status_id === 3
											? 2
											: dataProfile?.candidate_status_id === 4
											? 1
											: dataProfile?.candidate_status_id === 5
											? 4
											: dataProfile?.candidate_status_id === 6
											? 5
											: dataProfile?.candidate_status_id === 7
											? 6
											: dataProfile?.candidate_status_id === 8
											? 7
											: dataProfile?.candidate_status_id === 9
											? 7
											: dataProfile?.candidate_status_id === 12
											? 3
											: 5
									}
									size="small"
									status={
										dataProfile?.candidate_status_id === 4 ||
										dataProfile?.candidate_status_id === 9 ||
										dataProfile?.candidate_status_id === 10
											? "error"
											: "process"
									}
									items={[
										{
											title: "Hồ sơ mới"
										},
										{
											title: "Đang xét duyệt",
											subTitle:
												dataProfile?.candidate_status_id === 4
													? "Không đạt"
													: // : dataProfile?.candidate_status_id === 7
													  // ? "Đã phê duyệt nhận việc"
													  ""
										},
										{
											title: "Sơ vấn"
										},
										{
											title: "Mời phỏng vấn"
										},
										{
											title: "Phỏng vấn"
										},
										{
											title: "Phê duyệt nhận việc",
											subTitle:
												dataProfile?.candidate_status_id === 10
													? "Từ chối duyệt nhận việc"
													: // : dataSelect?.candidate_status_id === 7
													  // ? "Đã phê duyệt nhận việc"
													  ""
										},
										{
											title: "Mời nhận việc"
										},
										// {
										// 	title: "Đã phỏng vấn",
										// 	subTitle:
										// 		dataProfile?.candidate_status_id === 6
										// 			? "Chờ phê duyệt nhận việc"
										// 			: dataProfile?.candidate_status_id === 7
										// 			? "Đã phê duyệt nhận việc"
										// 			: ""
										// },
										{
											title: "Đã nhận việc",
											subTitle:
												dataProfile?.candidate_status_id === 9
													? "Từ chối nhận việc"
													: // : dataProfile?.candidate_status_id === 7
													  // ? "Đã phê duyệt nhận việc"
													  ""
										}
									]}
								/>
							</Card>
						</Col>
						<Col xs={24} md={16}>
							<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
								<Tabs
									className="activate-tab"
									defaultActiveKey="1"
									onChange={onChangeTab}
									items={[
										{
											label: `CV`,
											key: "1",
											children: (
												<div className="h-[600px]">
													{dataProfile?.is_cv === 1 || dataProfile?.url_cv ? (
														<object
															data={`${CND_URL}${dataProfile?.url_cv}`}
															type="application/pdf"
															width="100%"
															height="100%"
														>
															<p>
																Alternative text - include a link{" "}
																<a href={`${CND_URL}${dataProfile?.url_cv}`}>to the PDF!</a>
															</p>
														</object>
													) : (
														<div className="flex justify-center items-center h-[600px]">
															Công việc này không yêu cầu CV
														</div>
													)}
												</div>
											)
										},
										{
											label: `Đánh giá`,
											key: "2",
											disabled:
												dataProfile?.candidate_status_id === 1 || dataProfile?.candidate_status_id === 2 ? true : false,
											children: (
												<div>
													<div className=" text-black text-[16px] font-bold py-2">Vị trí ứng tuyển (Chi tiết)</div>

													<div className=" text-black text-[16px] font-bold py-2">Đánh giá của bộ phận phỏng vấn</div>
													<Radio.Group onChange={onChange1} defaultValue={bpDanhgia} size="large">
														<Radio.Button value="a">BP Tuyển dụng</Radio.Button>
														<Radio.Button value="b">PB/BP Chuyên môn</Radio.Button>
													</Radio.Group>
													<div className="bg-orange-100 sticky top-0 h-[40px] border-b divide-x border-black border-opacity-5 justify-between items-center flex divide-zinc-300 w-full mt-2">
														<div className="w-3/12 ">
															<div className=" text-black text-[13px] font-medium text-center">Tiêu chí đánh giá</div>
														</div>
														<div className="w-2/12 flex justify-center items-center">
															<div className=" text-black text-[14px] font-medium">Chưa phù hợp</div>
														</div>
														<div className="w-2/12 flex justify-center items-center">
															<div className=" text-black text-[14px] font-medium ">Tạm chấp nhận</div>
														</div>
														<div className="w-2/12 flex justify-center items-center">
															<div className=" text-black text-[14px] font-medium ">Phù hợp</div>
														</div>
														<div className="w-2/12 flex justify-center items-center">
															<div className=" text-black text-[14px] font-medium ">Rất Phù hợp</div>
														</div>
													</div>
													<div className="overflow-y-scroll w-full h-[400px]">
														{datas.map((item, index) => (
															<div className="w-full flex flex-col ">
																<div className="bg-neutral-400 w-full h-[40px] flex justify-start items-center">
																	<div className=" text-white text-[14px] font-medium  p-2">{item.title}</div>
																</div>
																{item.data.map((item1, index1) => (
																	<div className="w-full flex flex-col divide-y divide-zinc-300">
																		{item1.title !== "" && (
																			<div className="bg-neutral-200 w-full h-[40px] flex justify-start items-center p-2">
																				<div className=" text-black text-[13px] font-medium  ">{item1.title}</div>
																			</div>
																		)}
																		{item1.datachild.map((item2, index2) => (
																			<div className="w-full flex flex-col">
																				<div className="w-full flex justify-between items-center">
																					<div className="w-3/12 flex justify-center items-center p-2">
																						<div className=" text-black text-[13px] font-medium  ">{item2.title}</div>
																					</div>
																					{tieuchi.map((item3, index3) => (
																						<div className="w-2/12 flex justify-center items-center">
																							<div className=" text-black text-[13px] font-medium  ">
																								<Checkbox
																									checked={
																										(bpDanhgia === "a" ? item2.activeTab : item2.activeTab1) ===
																										tieuchi[index3]
																									}
																									onChange={() => handleCheckboxChange(item2.id, index3)}
																								/>
																							</div>
																						</div>
																					))}
																				</div>
																			</div>
																		))}
																	</div>
																))}
															</div>
														))}
													</div>
													<div className="text-black text-opacity-90 text-[16px] font-bold mt-4 ">
														Định hướng công việc của ứng viên trong thời gian tới
													</div>
													<TextArea
														rows={6}
														placeholder="Nhập nội dung

								"
														value={bpDanhgia === "a" ? dinhhuong : dinhhuongE}
														onChange={(value: any) => {
															bpDanhgia === "a" ? setDinhhuong(value.target.value) : setDinhhuongE(value.target.value);
															// setDinhhuong(value.target.value);
														}}
														// value={notiContent}
													/>
													<div className="text-black text-opacity-90 text-[16px] font-bold mt-4 ">Nhận xét chung</div>
													<TextArea
														rows={6}
														placeholder="Nhập nội dung
													
								"
														value={bpDanhgia === "a" ? nhanxetchung : nhanxetchungE}
														onChange={(value: any) => {
															bpDanhgia === "a"
																? setNhanxetchung(value.target.value)
																: setNhanxetchungE(value.target.value);
															// setNhanxetchung(value.target.value);
														}}
														// value={notiContent}
													/>
													<div className="text-black text-opacity-90 text-[16px] font-bold mt-4 ">Kết luận</div>
													<div className="flex justify-start items-center">
														<Checkbox
															onChange={() => (bpDanhgia === "a" ? setDatcheck("Đạt") : setDatcheckE("Đạt"))}
															checked={bpDanhgia === "a" ? datcheck === "Đạt" : datcheckE === "Đạt" ? true : false}
														>
															Đạt
														</Checkbox>
														<Checkbox
															onChange={() =>
																bpDanhgia === "a" ? setDatcheck("Không Đạt") : setDatcheckE("Không Đạt")
															}
															checked={
																bpDanhgia === "a" ? datcheck === "Không Đạt" : datcheckE === "Không Đạt" ? true : false
															}
														>
															Không Đạt
														</Checkbox>
														<Checkbox
															onChange={() => (bpDanhgia === "a" ? setDatcheck("Khác") : setDatcheckE("Khác"))}
															checked={bpDanhgia === "a" ? datcheck === "Khác" : datcheckE === "Khác" ? true : false}
														>
															Khác
														</Checkbox>
													</div>
													<TextArea
														rows={6}
														className="mt-2"
														placeholder="Nhập nội dung khác

								"
														value={bpDanhgia === "a" ? noidungkhac : noidungkhacE}
														onChange={(value: any) => {
															bpDanhgia === "a"
																? setNoidungkhac(value.target.value)
																: setNoidungkhacE(value.target.value);
															// setNoidungkhac(value.target.value);
														}}
														// value={notiContent}
													/>
												</div>
											)
										},
										{
											label: `Quyết định cuối cùng`,
											key: "3",
											disabled:
												dataProfile?.candidate_status_id === 1 ||
												dataProfile?.candidate_status_id === 2 ||
												(dataProfile?.candidate_status_id === 5 && !isDat) ||
												dataProfile?.candidate_status_id === 4
													? true
													: false,
											children: (
												<div>
													{dataProfile?.reject_reason && (
														<p className="font-bold text-[20px] text-[#ee1245eb]">
															Đã bị từ chối trước đó với lý do: {dataProfile?.reject_reason}
														</p>
													)}
													<div className=" text-black text-[16px] font-bold ">Vị trí ứng tuyển</div>
													<Row gutter={[8, 8]}>
														<Col md={5} xs={24} sm={24}>
															<FormSelect
																name="level_id"
																label="Cấp bậc"
																dataSource={dataLevel}
																placeholder="Chọn cấp bậc"
																slOption={{
																	name: "level_name",
																	value: "id"
																}}
																showSearch
																disabled={
																	dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																		? true
																		: false
																}
																optionFilterProp="children"
																// onChange={handleChangeGroup}
															/>
														</Col>
														<Col md={8} xs={24} sm={24}>
															<Form.Item
																name="expected_working"
																label="Thời gian làm việc dự kiến"
																rules={[{ required: false }]}
															>
																<DatePicker
																	disabled={
																		dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																			? true
																			: false
																	}
																	placeholder="Chọn ngày"
																	format={"DD/MM/YYYY"}
																/>
															</Form.Item>
														</Col>
													</Row>
													<div className=" text-black text-[16px] font-bold my-2">Mức lương (VNĐ)</div>
													<Row gutter={[8, 8]}>
														<Col md={5} xs={24} sm={24}>
															<FormInputNumber
																// id="form-km_number"
																disabled={
																	dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																		? true
																		: false
																}
																name="probation_salary"
																label="Lương thử việc"
																placeholder="0"
																size="large"
																classItem="mb-0"
																formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																// required
															/>
															{/* <FormInput
															id="form-carGroup_name"
															name="carGroup_name"
															label="Lương thử việc"
															type="text"
															size="large"
															placeholder="Lương thử việc"
															classItem="mb-0"
														/> */}
														</Col>
														<Col md={5} xs={24} sm={24}>
															<FormInputNumber
																// id="form-km_number"
																name="after_probation_salary"
																label="Lương sau thử việc"
																disabled={
																	dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																		? true
																		: false
																}
																placeholder="0"
																size="large"
																classItem="mb-0"
																formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																// required
															/>
														</Col>
														<Col md={8} xs={24} sm={24}>
															<FormInputNumber
																// id="form-km_number"
																name="other_allowances"
																label="Phụ cấp khác (Nếu có)"
																placeholder="0"
																size="large"
																disabled={
																	dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																		? true
																		: false
																}
																classItem="mb-0"
																formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
																parser={(value: any) => value!.replace(/\$\s?|(\.*)/g, "")}
																// required
															/>
														</Col>
														{/* <Col md={5} xs={24} sm={24}>
														<FormInput
															id="form-carGroup_name"
															name="carGroup_name"
															label="Mức đóng bảo hiểm"
															type="text"
															size="large"
															placeholder="0"
															classItem="mb-0"
														/>
													</Col> */}
													</Row>
													{dataProfile?.assigned_by === 0 ||
														(dataProfile?.candidate_status_id === 7 && (
															<>
																<div className=" text-black text-[16px] font-bold my-2">Mục tiêu công việc</div>
																<Row gutter={[8, 8]}>
																	<Col md={24} xs={24} sm={24}>
																		<UploadFile
																			id="files"
																			defaultFileList={fileList}
																			disabled={
																				dataProfile?.assigned_by !== 0 || dataProfile?.candidate_status_id === 7
																					? true
																					: false
																			}
																			setChangeFileList={changeFileList}
																			multiple={true}
																			className="uploadFile"
																			accept={["application/pdf", "image/png", "image/jpg", "image/jpeg"]}
																		/>
																	</Col>
																</Row>
															</>
														))}
													{dataProfile?.assigned_by !== 0 && (
														<p className="text-[14px] text-[#af3b3b] font-bold">
															Vui lòng đợi {dataProfile?.assigned_by_name} xét duyệt lương
														</p>
													)}
													{/* <div className=" text-black text-[16px] font-bold my-2">Quyết định từ Ban Giám Đốc</div>
													<Row gutter={[8, 8]}>
														<Col md={24} xs={24} sm={24}>
															<Form.Item label="" name="user_manager_result">
																<Radio.Group>
																	<Radio value={1}> Đồng ý </Radio>
																	<Radio value={0}> Từ chối </Radio>
																</Radio.Group>
															</Form.Item>
														</Col>
														<Col md={24} xs={24} sm={24}>
															<Form.Item label="Lý do" name="user_manager_note">
																<TextArea rows={6} placeholder="Lý do" />
															</Form.Item>
														</Col>
													</Row> */}
												</div>
											)
										},
										{
											label: `Lịch sử`,
											key: "4",
											children: (
												<>
													<TableStyled
														rowKey="id"
														dataSource={dataLog}
														bordered
														isRowLight={true}
														columns={columnsDataDetail() as any}
													/>
												</>
											)
										}
									]}
								/>
							</Card>
						</Col>
					</Row>
					{dataProfile?.candidate_status_id !== 6 && dataProfile?.candidate_status_id !== 9 && (
						<Affix offsetBottom={2}>
							<div className="bg-white h-[60px] flex justify-end items-center w-full shadow-2xl border border-black">
								{dataProfile?.candidate_status_id !== 1 &&
									dataProfile?.candidate_status_id !== 2 &&
									dataProfile?.candidate_status_id !== 4 && (
										<Button
											size="middle"
											block={true}
											type="link"
											className="mx-[20px]"
											onClick={() => {
												saveFile();
											}}
											style={{
												backgroundColor: "#fff",
												height: "40px",
												width: "110px",
												border: "1px solid black",
												borderRadius: 5
												// marginTop: 10
											}}
										>
											<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
												Tải kết quả PV
											</span>
										</Button>
									)}
								{/* {dataProfile?.candidate_status_id !== 4 &&
								dataProfile?.candidate_status_id !== 3 &&
								dataProfile?.candidate_status_id !== 5 && ( */}
								<Button
									size="middle"
									block={true}
									type="link"
									className="mx-[20px]"
									onClick={() => {
										setVisible(true);
									}}
									style={{
										backgroundColor: "#fff",
										height: "40px",
										width: "200px",
										border: "1px solid black",
										borderRadius: 5
										// marginTop: 10
									}}
								>
									<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
										Cập nhật thông tin cá nhân
									</span>
								</Button>
								<Dropdown menu={{ items: items1, onClick: handleSendEmail }} placement="top" arrow>
									<Button
										size="middle"
										block={true}
										type="link"
										className="mx-[20px]"
										onClick={() => {
											// searchVehilce();
										}}
										style={{
											backgroundColor: "#fff",
											height: "40px",
											width: "140px",
											border: "1px solid black",
											borderRadius: 5
											// marginTop: 10
										}}
									>
										<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
											Gửi Email
										</span>
									</Button>
								</Dropdown>
								{/* // )} */}
								{dataProfile?.candidate_status_id === 4 ? null : (
									<Dropdown menu={{ items }} placement="top" arrow>
										<Button
											size="middle"
											block={true}
											type="link"
											className="mx-[20px]"
											onClick={() => {
												// searchVehilce();
											}}
											style={{
												backgroundColor: "#fff",
												height: "40px",
												width: "140px",
												border: "1px solid black",
												borderRadius: 5
												// marginTop: 10
											}}
										>
											<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
												Chuyển trạng thái
											</span>
										</Button>
									</Dropdown>
								)}
								{(dataProfile?.candidate_status_id === 1 || dataProfile?.candidate_status_id === 2) && (
									<Button
										size="middle"
										block={true}
										type="link"
										className="mx-[20px]"
										onClick={() => {
											// searchVehilce();
										}}
										style={{
											backgroundColor: "#fff",
											height: "40px",
											width: "140px",
											// border: "1px solid black",
											borderRadius: 5
											// marginTop: 10
										}}
									>
										<UploadFile
											id="files_cv"
											hide
											name="Upload CV"
											defaultFileList={fileListCV}
											setChangeFileList={changeFileListCV}
											multiple={true}
											className="uploadFile"
											accept={["application/pdf", "image/png", "image/jpg", "image/jpeg"]}
										/>
										{/* <span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>
										Upload CV
									</span> */}
									</Button>
								)}
								<Popconfirm
									title="Bạn có muốn lưu hay không?"
									style={{ borderRadius: "10px", zIndex: 10000 }}
									okButtonProps={{
										style: {
											background: "black",
											color: "#fdd800"
										}
									}}
									cancelButtonProps={{
										style: {
											background: "#dfe2e4",
											color: "black"
										}
									}}
									onConfirm={() => {
										onSave(formRating.getFieldsValue());
									}}
									okText="Đồng ý"
									cancelText="Hủy"
								>
									<Button
										size="middle"
										block={true}
										type="link"
										className="mx-[20px]"
										// onClick={() => {
										// 	onSave(formRating.getFieldsValue());
										// }}
										style={{
											backgroundColor: "black",
											height: "40px",
											width: "100px",
											border: "1px solid black",
											borderRadius: 5
											// marginTop: 10
										}}
									>
										<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "white" }}>Lưu</span>
									</Button>
								</Popconfirm>
							</div>
						</Affix>
					)}
				</Spin>
			</Form>
			{show && (
				<div id="page" className="w-full bg-white p-[20px] text-[16px] text-black">
					<div className="flex justify-between items-center p-2 border border-black w-full h-[120px] ">
						<div className="w-1/7">
							<img src="/logom.svg" className="w-[94px] h-[35px]" alt="logo" />
						</div>
						<div className="w-4/7">
							<p className="text-[20px] font-bold text-center">BẢN ĐÁNH GIÁ KẾT QUẢ PHỎNG VẤN</p>
						</div>
						<div className="2/7">
							<div className="flex flex-col">
								<p>Mã: NTL.HR.DI.01.FM05</p>
								<p>PB: </p>
								<p>NBH: </p>
							</div>
						</div>
					</div>
					<div className="flex justify-between items-center border border-black w-full py-2 divide-x divide-black mt-2">
						<div className="w-1/2 flex-col flex p-2">
							<p className="font-bold text-left text-[18px]">Họ tên ứng viên: {dataProfile?.candidate_name}</p>
							<div className="flex justify-between items-center w-full">
								<p className=" text-[18px] w-1/2">
									Ngày sinh: {dataProfile?.dob ? moment(dataProfile?.dob).format("DD/MM/YYYY") : ""}
								</p>
								<p className=" text-[18px] w-1/2">
									Giới tính: {dataProfile?.gender ? (dataProfile?.gender === 1 ? "Nam" : "Nữ") : ""}
								</p>
							</div>
						</div>
						<div className="w-1/2 flex-col flex p-2">
							<p className="font-bold text-left text-[18px]">Vị trí ứng tuyển: {dataProfile?.position_name}</p>
							<div className="flex justify-between items-center w-full">
								<p className=" text-[18px] w-1/2">Phòng ban:</p>
								<p className="text-[18px] w-1/2">Cấp bậc:</p>
							</div>
						</div>
					</div>
					<p className="font-bold text-[20px] my-2">I. ĐÁNH GIÁ CỦA BỘ PHẬN PHỎNG VẤN</p>
					<div className="bg-orange-100 sticky top-0  border-b divide-x border-black border-opacity-5 divide-black justify-between items-center flex w-full mt-2">
						<div className="w-1/2 ">
							<div className=" text-black font-bold text-[20px] text-center">Tiêu chí đánh giá</div>
						</div>
						<div className="w-1/2 flex justify-between divide-x divide-black">
							<div className="flex flex-col w-1/2 p-2 divide-y divide-black">
								<p className="text-center font-bold h-[60px] text-[20px]">
									BP TUYỂN DỤNG <br /> (đánh giá)
								</p>
								<div className="w-full flex justify-between items-center p-2 divide-x h-[100px] divide-black">
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Chưa phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Tạm chấp nhận</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Rất Phù hợp</div>
									</div>
								</div>
							</div>
							<div className="flex flex-col w-1/2 p-2 divide-y divide-black">
								<p className="text-center font-bold h-[60px] text-[20px]">
									BP CHUYÊN MÔN <br /> (đánh giá)
								</p>
								<div className="w-full flex justify-between items-center p-2 divide-x h-[100px] divide-black">
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Chưa phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Tạm chấp nhận</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[16px] font-medium text-center">Rất Phù hợp</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full ">
						{datas.map((item, index) => (
							<div className="w-full flex flex-col ">
								<div className="bg-neutral-400 w-full h-[40px] flex justify-start items-center">
									<div className=" text-white text-[17px] font-medium  p-2">{item.title}</div>
								</div>
								{item.data.map((item1, index1) => (
									<div className="w-full flex flex-col divide-y divide-zinc-300">
										{item1.title !== "" && (
											<div className="bg-neutral-200 w-full h-[40px] flex justify-start items-center p-2">
												<p className=" text-black text-[16px] font-medium  ">{item1.title}</p>
											</div>
										)}
										{item1.datachild.map((item2, index2) => (
											<div key={index2} className="w-full flex flex-col p-2">
												<div className="w-full flex justify-between items-center">
													<div className="w-1/2 flex justify-start items-center p-2">
														<div className=" text-black text-[16px] font-medium text-left">{item2.title}</div>
													</div>
													<div className="w-1/2 p-2">
														<div className="flex justify-between items-center w-full">
															{tieuchi.map((item3, index3) => (
																<div className="w-1/8 flex justify-center items-center">
																	<div className=" text-black text-[13px] font-medium text-center ">
																		<Checkbox
																			checked={item2.activeTab === tieuchi[index3]}
																			// onChange={() => handleCheckboxChange(item2.id, index3)}
																		/>
																	</div>
																</div>
															))}
															{tieuchi.map((item3, index3) => (
																<div className="w-1/8 flex justify-center items-center">
																	<div className=" text-black text-[13px] font-medium  ">
																		<Checkbox
																			checked={item2.activeTab1 === tieuchi[index3]}
																			// onChange={() => handleCheckboxChange(item2.id, index3)}
																		/>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								))}
							</div>
						))}
						<div className="w-full flex flex-col p-2">
							<div className="w-full flex justify-between items-center  divide-x divide-zinc-300">
								<div className="w-1/2 flex justify-start items-center p-2">
									<div className=" text-black text-[16px] font-medium text-left">
										Định hướng công việc trong thời gian tới
									</div>
								</div>
								<div className="w-1/2 flex justify-between items-center divide-x divide-black">
									<div className="w-1/2 p-2">
										<p className="text-center">{dinhhuong}</p>
									</div>
									<div className="w-1/2 p-2">
										<p className="text-center">{dinhhuongE}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p className="font-bold text-[20px] my-2">II. PHẦN NHẬN XÉT</p>
					<table className="table-fixed w-full border-collapse border border-slate-500 border-spacing-2 p-2">
						<thead>
							<tr>
								<th className="border border-slate-600 p-2">Nhận xét chung</th>
								<th className="border border-slate-600 p-2">
									{" "}
									<p className="font-bold text-left w-full p-2">BP TUYỂN DỤNG</p>
								</th>
								<th className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">PB/BP CHUYÊN MÔN</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="text-[16px]">
								<td className="p-2"></td>
								<td className="p-2">{nhanxetchung}</td>
								<td className="p-2">{nhanxetchungE}</td>
							</tr>
							<tr className="">
								<td className="p-2">Kết luận</td>
								<td className="p-2">
									<div className="h-[70px] flex flex-col text-[16px]">
										<div className="flex justify-start items-center">
											<Checkbox className="text-[16px]" checked={datcheck === "Đạt" ? true : false}>
												Đạt
											</Checkbox>
											<Checkbox className="text-[16px]" checked={datcheck === "Không Đạt" ? true : false}>
												Không Đạt
											</Checkbox>
											<Checkbox className="text-[16px]" checked={datcheck === "Khác" ? true : false}>
												Khác
											</Checkbox>
										</div>
										<p className="">Ký, họ và tên:</p>
										<p className="">Ngày:___/___/___</p>
									</div>
								</td>
								<td className="p-2">
									<div className="h-[70px] flex flex-col">
										<div className="flex justify-start items-center">
											<Checkbox className="text-[16px]" checked={datcheckE === "Đạt" ? true : false}>
												Đạt
											</Checkbox>
											<Checkbox className="text-[16px]" checked={datcheckE === "Không Đạt" ? true : false}>
												Không Đạt
											</Checkbox>
											<Checkbox className="text-[16px]" checked={datcheckE === "Khác" ? true : false}>
												Khác
											</Checkbox>
										</div>
										<p className="">Ký, họ và tên:</p>
										<p className="">Ngày:___/___/___</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					{/* <div className="flex justify-between items-center border border-black w-full py-2 divide-x divide-black mt-2">
						<div className="w-2/12 flex-col flex p-2">
							<p className="font-bold text-left h-[150px] w-full">Nhận xét chung</p>
							<p className="font-bold text-left h-[70px]  w-full">Kết luận</p>
						</div>
						<div className="w-5/12 flex-col flex p-2 ">
							<div className="h-[150px] flex flex-col justify-start">
								<p className="text-center font-bold">BP TUYỂN DỤNG</p>
								<p className="text-center py-2">{nhanxetchung}</p>
							</div>
							<div className="h-[70px] flex flex-col">
								<div className="flex justify-start items-center">
									<Checkbox checked={datcheck === "Đạt" ? true : false}>Đạt</Checkbox>
									<Checkbox checked={datcheck === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
									<Checkbox checked={datcheck === "Khác" ? true : false}>Khác</Checkbox>
								</div>
								<p className="">Ký, họ và tên:</p>
								<p className="">Ngày:___/___/___</p>
							</div>
						</div>
						<div className="w-5/12 flex-col flex p-2">
							<div className="h-[150px] flex flex-col justify-start">
								<p className="text-center font-bold">PB/BP CHUYÊN MÔN</p>
								<p className="text-center py-2">{nhanxetchungE}</p>
							</div>
							<div className="h-[70px] flex flex-col">
								<div className="flex justify-start items-center">
									<Checkbox checked={datcheckE === "Đạt" ? true : false}>Đạt</Checkbox>
									<Checkbox checked={datcheckE === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
									<Checkbox checked={datcheckE === "Khác" ? true : false}>Khác</Checkbox>
								</div>
								<p className="">Ký, họ và tên:</p>
								<p className="">Ngày:___/___/___</p>
							</div>
						</div>
					</div> */}
					<p className="font-bold text-[20px] my-2">II. QUYẾT ĐỊNH CUỐI CÙNG</p>
					<table className="table-fixed w-full border-collapse border border-slate-500 border-spacing-2 p-2 text-[16px]">
						<thead>
							<tr>
								<th className="border border-slate-600 p-2">Mức lương thỏa thuận</th>
								<th className="border border-slate-600 p-2">
									{" "}
									<p className="font-bold text-left w-full p-2">
										Trưởng bộ phận <span>(Đề xuất)</span>{" "}
									</p>
								</th>
								<th className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">
										GĐ QTNNL <span>(Xét duyệt)</span>
									</p>
								</th>
								<th rowSpan={1} className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">
										Tổng Giám đốc/GĐ Điều hành (*)
										<span>(Phê chuẩn)</span>
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="p-2">Chức danh</td>
								<td className="p-2">{dataProfile?.position_name}</td>
								<td className="p-2"></td>
								<td rowSpan={8}></td>
							</tr>
							<tr className="">
								<td className="p-2">Cấp bậc</td>
								<td className="p-2">{formRating.getFieldValue("level_name")}</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Lương thử việc</td>
								<td className="p-2">{formRating.getFieldValue("probation_salary")}</td>
								<td className="p-2"></td>
							</tr>

							<tr className="">
								<td className="p-2">Lương sau thử việc</td>
								<td className="p-2">{formRating.getFieldValue("after_probation_salary")}</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Phụ cấp khác (nếu có)</td>
								<td className="p-2">{formRating.getFieldValue("other_allowances")}</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Mức đóng bảo hiểm</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">(Ký, Họ tên)</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td colSpan={3} className="p-2">
									Thời gian vào làm việc dự kiến: Ngày……tháng……năm………
								</td>
							</tr>
						</tbody>
					</table>
					<p>(*) Nếu đã có ủy quyền từ TGĐ, GĐ Chi nhánh sẽ duyệt mức thu nhập của NV chi nhánh trực thuộc</p>
				</div>
			)}
		</div>
	);
};

export default ListProfileDetails;
