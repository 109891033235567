/*eslint-disable*/
import { Checkbox, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInAccount } from "../../../services/actions/account.actions";
import { AppState } from "../../../types";

import { useHistory } from "react-router-dom";
import { notifyError, notifySuccess } from "src/components/notification";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import {
	STORAGE_SERVICE,
	localGetFormLogin,
	localSaveAccount,
	localSaveAuthUUID,
	localSaveFormLogin,
	localSaveToken
} from "src/utils/localStorage";
import { IFormSignIn, IPropsSignIn } from "../interfaces";
import SvgLetters from "src/assets/svg/SvgLetters";
import SvgLock from "src/assets/svg/SvgLock";
import { screenSignIn, typeScreenManagerSignIn } from "src/constants";
import { useAuth } from "src/services/authorRouter";
import colors from "src/utils/colors";
import { getOrgs } from "src/services/actions/org.action";
import routerNames from "src/utils/data/routerName";
import { APP_ID } from "src/services/api/config";
import { User } from "lucide-react";
const SignIn = (props: IPropsSignIn) => {
	const [signInForm] = Form.useForm();

	const auth = useAuth();
	const dispatch = useDispatch();
	const isMount = useIsMount();
	let history = useHistory();
	const { stateSignIn, phone, email } = useSelector((state: AppState) => state.accountReducer);
	const { stateGetOrgs } = useSelector((state: AppState) => state.orgReducer);
	const [savePass, setSavePass] = useState(false);
	/****************************START**************************/
	/*                         Life Cycle                      */

	useEffect(() => {
		let _formLogin = JSON?.parse(localStorage.getItem(STORAGE_SERVICE.ACCOUNT_LOGIN) || "{}");
		if (!phone && !email && _formLogin) {
			// let _dataUser = JSON.parse(_formLogin);
			if (_formLogin.isRemember) {
				signInForm.setFieldsValue({
					username: _formLogin.username,
					password: _formLogin.password,
					remember: _formLogin.isRemember
				});
			}
		} else {
			signInForm.setFieldsValue({
				username: "",
				password: "",
				remember: false
			});
		}
		// signInForm.setFieldsValue({
		// 	username: "",
		// 	password: ""
		// });
	}, []);

	useEffect(() => {
		if (isMount) return;
		const { success, message, error, data } = stateSignIn;
		if (success) {
			localSaveAccount(JSON.stringify(data));
			// localSaveAuthUUID(data.uuid || "");
			localSaveToken(data.token || "");
			localSaveFormLogin(
				JSON.stringify({
					username: phone || email || signInForm.getFieldValue("username"),
					password: signInForm.getFieldValue("password"),
					isRemember: savePass
				})
			);

			auth.signIn(data.token || "", JSON.stringify(data), () => {
				console.log("Save authorization!");
				notifySuccess("Đăng nhập thành công");
				history.push({
					pathname: "/"
				});
			});
		} else if (success === false || error) {
			notifyError(getMessageV1(message));
		}
	}, [stateSignIn.isLoading]);

	/**************************** END **************************/

	/****************************START**************************/
	/*                          Function                       */

	const btnSignIn = (values: IFormSignIn) => {
		let params;
		setSavePass(values.remember);
		if (values.username.search("@") !== -1) {
			params = {
				// email: values.username.trim(),
				password: values.password.trim(),
				username: values.username.trim(),
				// company_id: 1,
				// app_id: APP_ID,
				// user_type: 'I'
			};
		} else {
			params = {
				// phone: values.username.trim(),
				password: values.password.trim(),
				username: values.username.trim(),
				// company_id: 1,
				// app_id: APP_ID,
				// user_type: 'I'
			};
		}
		dispatch(signInAccount(params));
		// notifySuccess("Đăng nhập thành công");
		// history.push({
		// 	pathname: routerNames.DASHBOARD
		// });
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	const changeDirector = (values: number) => {
		if (stateSignIn.isLoading) return;
		props.changeType(values);
	};

	/**************************** END **************************/

	/****************************START**************************/
	/*                         Component                       */

	/**************************** END **************************/

	/****************************START**************************/
	/*                        Return Page                      */

	return (
		<div className="flex flex-col justify-center items-center w-full" style={{ width: screenSignIn }}>
			<img src="/logo.svg" />
			{/* <div className="text-center font-bold text-3xl">Đăng nhập</div> */}
			<Form
				className="mt-8 w-full"
				layout="vertical"
				form={signInForm}
				id="formLogin"
				onFinish={btnSignIn}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					style={{ marginTop: "8px" }}
					name="username"
					label='Mã nhân viên'
					rules={[
						{
							required: true,
							message: "Vui lòng nhập mã nhân viên"
						}
					]}
				>
					<Input
						prefix={<User color={colors.neutral_color_1_2} />}
						className="defaultInput bg-[#F5F5F5]"
						id="form-username"
						type="phoneAndEmail"
						placeholder=" Nhập mã nhân viên"
						disabled={stateSignIn.isLoading || undefined}
					/>
				</Form.Item>

				<Form.Item
					name="password"
					label='Mật khẩu'
					rules={[
						{
							required: true,
							message: "Vui lòng nhập mật khẩu"
						}
					]}
				>
					<Input.Password
						prefix={<SvgLock fill={colors.neutral_color_1_2} />}
						id="form-password"
						placeholder=" Mật khẩu"
						disabled={stateSignIn.isLoading || undefined}
						className="defaultInput"
						type="password"
					/>
				</Form.Item>
				<Form.Item name="remember" valuePropName="checked" >
					<Checkbox>Lưu mật khẩu cho lần đăng nhập sau</Checkbox>
				</Form.Item>
				{/* <div
					className="w-full mt-2 mb-3"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between"
					}}
				>
					<div
						style={{ color: colors.accent_color_5_2, cursor: "pointer" }}
						onClick={(e) => changeDirector(typeScreenManagerSignIn.FORGET_PASSWORD)}
					>
						Quên mật khẩu
					</div>
					<div
						style={{ color: colors.accent_color_5_2, cursor: "pointer" }}
						onClick={(e) => changeDirector(typeScreenManagerSignIn.REGISTER)}
					>
						Đăng ký
					</div>
				</div> */}

				<button
					form="formLogin"
					type="submit"
					style={{
						background: colors.primary_color_1_1,
						width: "100%",
						height: "48px",
						borderRadius: "5px",
						color: colors.neutral_color_1_9,
						fontWeight: "bold"
					}}
				>
					Đăng nhập
				</button>
			</Form>
		</div>
	);

	/**************************** END **************************/
};

export default SignIn;
