import * as React from "react";

const SvgLock = (props) => (
	<svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M20.52 9.625h-1.437V7.708A6.716 6.716 0 0 0 12.375 1a6.716 6.716 0 0 0-6.708 6.708v1.917H4.229a.479.479 0 0 0-.479.48v11.978C3.75 23.14 4.61 24 5.667 24h13.416C20.14 24 21 23.14 21 22.083V10.104a.479.479 0 0 0-.48-.479Zm-6.71 10.01a.48.48 0 0 1-.477.532h-1.916a.48.48 0 0 1-.477-.532l.303-2.719a1.895 1.895 0 0 1-.785-1.541c0-1.057.86-1.917 1.917-1.917s1.917.86 1.917 1.917c0 .62-.294 1.184-.784 1.541l.302 2.719Zm2.398-10.01H8.542V7.708a3.838 3.838 0 0 1 3.833-3.833 3.838 3.838 0 0 1 3.833 3.833v1.917Z"
			fill={props ? props.fill : "#001529"}
		/>
	</svg>
);

export default SvgLock;
